import moment from "moment";

import queryString from "query-string";

import * as Smav2 from "../services/Smav2";

const API_URL = process.env.REACT_APP_API_URL;
const FUNC_NAME = "getDataWebconnect?";

export function getData(
  systemInfo,
  date,
  timeOffset,
  useFullScraper = true /* used only by this maker */
) {
  const { sources } = systemInfo;
  const dataPromise = Smav2.getData(systemInfo, date, timeOffset);
  if (!sources.spScraper || !useFullScraper) {
    return dataPromise;
  }
  // by this point the pull request is on the same day, and there are sunny portal credentials
  const { email, pass, plant_id, orders } = sources.spScraper;
  const params = {
    email,
    pass,
    plant_id,
    date: date.format("M-D-YYYY"),
    time_offset: timeOffset,
  };
  const wcFetchUrl = API_URL + FUNC_NAME + queryString.stringify(params);

  return Promise.allSettled([
    dataPromise,
    fetch(wcFetchUrl, { mode: "cors" }).then((res) => res.json()),
  ]).then(([muleData, wcData]) => {
    if (muleData.status === "rejected" && wcData.status === "rejected") {
      return Promise.reject({
        reason1: muleData.reason,
        reason2: wcData.reason,
      });
    }
    const finalData =
      muleData.status === "fulfilled" ? { ...muleData.value } : {};
    if (wcData.status === "fulfilled") {
      finalData.invPower = Smav2.normalizeInvPower(wcData.value, orders, date);
    } else {
      console.warn(
        `${systemInfo.id}: puppeteer scraping failed, reported reason: '${wcData.reason}'`
      );
    }
    return Promise.resolve({ ...finalData });
  });
}

// sma style collation
export function collateHourlyData(data, mins = 60, keepOffset = false) {
  if (!Number.isInteger(mins) || mins <= 0 || 60 % mins !== 0) {
    mins = 60;
  }
  const midRes = {};
  Object.keys(data).forEach((timeStamp) => {
    const currentMoment = moment(timeStamp);
    if (currentMoment.minutes() % mins !== 0) {
      currentMoment.add(mins, "minutes");
    }
    // remove remainder from last mins interval, set to start of minute
    const currentHour = currentMoment
      .subtract(currentMoment.minutes() % mins, "minutes")
      .startOf("minute")
      .toISOString(keepOffset);
    const value = Number.parseFloat(data[timeStamp]);
    if (!Number.isNaN(value)) {
      if (!(currentHour in midRes)) {
        midRes[currentHour] = [];
      }
      midRes[currentHour].push(value);
    }
  });
  return midRes;
}

// sma style time checking
export function isActiveCb(val) {
  const numVal = parseFloat(val);
  return !Number.isNaN(numVal) && numVal !== 0;
}

export function getLatestStartTime(data) {
  const keys = Object.keys(data);
  if (keys.length === 0) {
    return null;
  }
  keys.sort();
  let res = isActiveCb(data[keys[0]]) ? keys[0] : null;
  for (let i = keys.length - 1; i > 0; --i) {
    if (!isActiveCb(data[keys[i - 1]]) && isActiveCb(data[keys[i]])) {
      res = keys[i - 1];
      break;
    }
  }
  return res;
}

export function getLatestStopTime(data) {
  const dataEntries = Object.entries(data).sort((log1, log2) =>
    log1[0] < log2[0] ? -1 : 1
  );
  for (let i = dataEntries.length - 1; i >= 0; --i) {
    if (isActiveCb(dataEntries[i][1])) {
      break;
    }
    dataEntries.pop();
  }
  const length = dataEntries.length;
  if (length === 0) {
    return null;
  }
  return dataEntries[length - 1][1] ? dataEntries[length - 1][0] : null;
}
