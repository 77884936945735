import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import getSenderIds from "../../services/senderIds";

const HIGHLIGHT_BACKGROUND_COLOR = "#d3d3d3";

const HoverableRow = styled.tr`
  &:hover {
    color: blue;
    background: ${HIGHLIGHT_BACKGROUND_COLOR};
    cursor: default;
  }
`;

class IssueItem extends Component {
  static propTypes = {
    idx: PropTypes.number,
    updateIssue: PropTypes.func,
    id: PropTypes.string,
    shouldSend: PropTypes.bool,
    messageString: PropTypes.string,
    label: PropTypes.string,
    phonesString: PropTypes.string,
    lng: PropTypes.string,
    msg: PropTypes.string,
    severity: PropTypes.number,
    params: PropTypes.any,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const lables = getSenderIds();
    if (!lables.includes(this.props.label)) {
      console.warn("wrong label name:", this.props.label);
      const { idx, updateIssue } = this.props;
      updateIssue(idx, "label", lables[0]);
    }
  }

  onChangeSendStatus = () => {
    const { idx, shouldSend, updateIssue } = this.props;
    updateIssue(idx, "shouldSend", !shouldSend);
  };

  onChangeLabel = (ev) => {
    const { idx, updateIssue } = this.props;
    updateIssue(idx, "label", ev.target.value);
  };

  onChangePhones = (ev) => {
    const { idx, updateIssue } = this.props;
    updateIssue(idx, "phonesString", ev.target.value);
  };

  onCopyMessage = () => {
    navigator.clipboard.writeText(this.props.messageString);
  };

  onChangeStatus = (ev) => {
    const { idx, updateIssue, issueData } = this.props;
    const newStatus = issueData[ev.target.value];
    const { updateKeys, updateValues } = [
      ["status", newStatus.status],
      ...Object.entries(newStatus.data),
    ].reduce(
      ({ updateKeys, updateValues }, [key, val]) => {
        updateKeys.push(key);
        updateValues.push(val);
        return { updateKeys, updateValues };
      },
      { updateKeys: [], updateValues: [] }
    );
    updateIssue(idx, updateKeys, updateValues);
  };

  render() {
    const {
      id,
      shouldSend,
      messageString,
      label,
      phonesString,
      sent,
      severity,
      thresholdSeverity,
      issueData,
    } = this.props;
    const currentIssueIdx = issueData.findIndex(
      ({ status }) => status === this.props.status
    );
    return (
      <HoverableRow>
        {/* should send */}
        <td>
          <input
            type="checkbox"
            disabled={severity <= thresholdSeverity}
            checked={shouldSend}
            onChange={this.onChangeSendStatus}
          />
        </td>
        {/* sys id */}
        <td>{id}</td>
        {/* translated message */}
        <td>{messageString}</td>
        {/* copy button */}
        <td>
          <button onClick={this.onCopyMessage}>copy</button>
        </td>
        {/* label */}
        <td>
          <select value={label} onChange={this.onChangeLabel}>
            {getSenderIds().map((sender, idx) => (
              <option key={idx}>{sender}</option>
            ))}
          </select>
        </td>
        {/* status */}
        <td>
          <select value={currentIssueIdx} onChange={this.onChangeStatus}>
            {issueData.map((data, idx) => (
              <option key={idx} value={idx}>
                {data.data.severity} - {data.status}
              </option>
            ))}
          </select>
        </td>
        {/* phones */}
        <td>
          <input
            type="tel"
            size="40"
            value={phonesString}
            onChange={this.onChangePhones}
            disabled={!shouldSend}
          />
        </td>
        {/* sent indicator */}
        <td>{sent ? "sent" : " "}</td>
      </HoverableRow>
    );
  }
}

export default IssueItem;
