import React from "react";
import PasswordForgetForm from "../components/PasswordForgetForm";

const PasswordForgetPage = () => (
  <div>
    <h1>PasswordForget</h1>
    <PasswordForgetForm />
  </div>
);

export default PasswordForgetPage;
