import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import * as SunCalc from "suncalc";

const Dawn = (props) => {
  const dawnTime = SunCalc.getTimes(props.date, props.lat, props.lon).dawn;
  return <span>{moment(dawnTime).format("HH:mm")}</span>;
};

Dawn.propTypes = {
  date: PropTypes.object,
  lat: PropTypes.number,
  lon: PropTypes.number,
};

export default Dawn;
