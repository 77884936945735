import { storage } from "./firebase";

export const fetchJsonData = (path) => {
  const pathReference = storage.ref(path);
  return pathReference
    .getDownloadURL()
    .then(function (url) {
      // `url` is the download URL for 'images/stars.jpg'
      // This can be downloaded directly
      return fetch(url);
    })
    .then((raw) => raw.json())
    .catch(function (error) {
      console.error(error.message);
      throw error;
    });
};
