import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import SignInPage from "./pages/SignIn";
import PasswordForgetPage from "./pages/PasswordForget";
import HomePage from "./pages/Home";
import withAuthentication from "./components/Session/withAuthentication";
import * as routes from "./constants/routes";

const App = () => (
  <Router>
    <Switch>
      <Route exact path={routes.SIGN_IN} component={SignInPage} />
      <Route
        exact
        path={routes.PASSWORD_FORGET}
        component={PasswordForgetPage}
      />
      <Route path={routes.HOME} component={HomePage} />
    </Switch>
  </Router>
);

export default withAuthentication(App);
