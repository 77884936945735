const SHEET_ACCESS_KEY = "aabbcc";

export function sendEmail(recepients, subject, message, sender) {
  const url = `${process.env.REACT_APP_SHEET_UPDATER_URL}?access_key=${SHEET_ACCESS_KEY}&mail=true`;

  const requestBody = {
    noReply: true,
    subject: "" + subject,
    body: "" + message,
    name: "" + sender,
    bcc: recepients.toString(),
  };

  return fetch(url, {
    method: "post",
    mode: "cors",
    body: JSON.stringify(requestBody),
  })
    .then((res) => res.json())
    .catch((e) => console.error(e));
}
