import React from "react";
import { Route, NavLink, withRouter, Redirect, Switch } from "react-router-dom";

import withAuthorization from "../components/Session/withAuthorization";
import styled from "styled-components";
import SysmapOrgs from "../containers/SysmapOrgs";
import SysmapUsers from "../containers/SysmapUsers";
import SysmapSites from "../containers/SysmapSites";
import SysmapSystems from "../containers/SysmapSystems";

const activeStyle = {
  color: "white",
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const LinksList = styled.ul`
  list-style: none;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  background: lightblue;
`;
const LinksListItem = styled.li`
  margin-right: 20px;
`;

const Content = styled.div`
  padding: 10px;
  flex: 1;
  height: 100%;
`;

const ROUTES = [
  { name: "Organizations", route: "orgs", RenderComponent: SysmapOrgs },
  { name: "Users", route: "users", RenderComponent: SysmapUsers },
  { name: "Sites", route: "sites", RenderComponent: SysmapSites },
  { name: "Systems", route: "systems", RenderComponent: SysmapSystems },
];

class SysmapApi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { match } = this.props;

    return (
      <Wrapper>
        <LinksList>
          {ROUTES.map(({ name, route }, idx) => {
            return (
              <LinksListItem key={idx}>
                <NavLink to={`${match.url}/${route}`} activeStyle={activeStyle}>
                  {name}
                </NavLink>
              </LinksListItem>
            );
          })}
        </LinksList>

        <Content>
          <Switch>
            {ROUTES.map(({ route, RenderComponent }, idx) => {
              return (
                <Route
                  key={idx}
                  path={`${match.path}/${route}`}
                  render={() => <RenderComponent {...this.state} />}
                />
              );
            })}

            <Redirect
              from={`${match.path}`}
              to={`${match.url}/${ROUTES[0].route}`}
              exact={true}
              strict={true}
            />
          </Switch>
        </Content>
      </Wrapper>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withRouter(SysmapApi));
