import * as React from "react";
import PropTypes from "prop-types";
import * as helpers from "./helpers";
import PushUi from "../PushToSheetUi";
import Select from "./Select";
import styled from "styled-components";
import { sendData } from "../../services/sendData";
import {
  pushSysstats,
  addFieldIfNotFalse,
} from "../../services/backendHelpers";
import moment from "moment";

import LocalStorageKeys from "../../localStorageKeys";

const INPUT_FIELDS = [
  "comm",
  "blackout",
  "mcm",
  "fct",
  "ovld",
  "riso",
  "shadow",
  "webbox",
];

const ResultsContainer = styled.div``;

const ActionsPanel = styled.div`
  display: flex;
  align-items: center;
`;

const TableContainer = styled.div`
  overflow: auto;
`;

// helper function to init an object with default values
function getZeroVals(data = {}, value = "0") {
  // const initVals = Object.assign(...INPUT_FIELDS.map(d => ({[d]: '0'})));
  const initVals = Object.assign(
    ...INPUT_FIELDS.map((d) => ({ [d]: value })), // eh: changed '0' to a given value won't change old functionality
    data
  );
  return initVals;
}

class StatResultsContent extends React.Component {
  headers;

  static propTypes = {
    data: PropTypes.object,
    daily: PropTypes.shape({
      comm: PropTypes.number,
      fct: PropTypes.any,
      webbox: PropTypes.string,
    }),
    canPush: PropTypes.bool,
    doPushToSheet: PropTypes.func,
    isPushOngoing: PropTypes.bool,
    pushResult: PropTypes.object,
    dataSys: PropTypes.object,
    sysDocId: PropTypes.string,
    sysId: PropTypes.string,
    dataDate: PropTypes.object,
    results: PropTypes.object,
  };

  constructor(props) {
    super(props);

    // sets all daily fields to blank if comm 3 or 4
    const initialDailyValue =
      +props.daily.comm === 3 || +props.daily.comm === 4 ? "" : "0";
    const initVals = getZeroVals(props.daily, initialDailyValue);
    // todo: combine manual with data
    this.state = {
      isCopied: false,
      vals: initVals,
      isPushOngoingToDB: false,
    };
    this.headers = helpers.getHeaders(12);
    this.handlePushClick = this.handlePushClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (this.props.data !== nextProps.data) {
  //     // sets all daily fields to blank if comm 3 or 4
  //     const initialDailyValue = (+nextProps.daily.comm === 3) || (+nextProps.daily.comm === 4) ? '' : '0';
  //     const initVals = getZeroVals(nextProps.daily, initialDailyValue);
  //     this.setState({
  //       isCopied: false,
  //       vals: initVals,
  //     })
  //   }
  // }

  // changed from `UNSAFE_componentWillReceiveProps`
  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      // sets all daily fields to blank if comm 3 or 4
      const initialDailyValue =
        +this.props.daily.comm === 3 || +this.props.daily.comm === 4 ? "" : "0";
      const initVals = getZeroVals(this.props.daily, initialDailyValue);
      this.setState({
        isCopied: false,
        vals: initVals,
      });
    }
  }

  copyEl = (id) => {
    const contentEl = document.getElementById(id);
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(contentEl);
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("copy");
  };

  // copy text from the hidden form element
  copyFormEl = () => {
    const id = "__copyme";
    const formEl = document.getElementById(id);
    formEl.select();
    document.execCommand("copy");
    this.setState({
      isCopied: true,
    });
  };

  setZero = () => {
    this.setState((state) => {
      const initVals = getZeroVals();
      initVals.webbox = state.vals.webbox;
      return {
        vals: initVals,
      };
    });
  };

  setBlank = () => {
    const initVals = getZeroVals({}, "");
    this.setState({
      vals: initVals,
    });
  };

  handlePushClick(ev) {
    const { sysId, sysDocId, dataDate } = this.props;
    const uniHeaders = INPUT_FIELDS.concat(this.headers);
    const uniData = {
      ...this.state.vals,
      ...this.props.data,
    };
    const data = helpers.getDataByHeaders(uniData, uniHeaders);
    const pushQueryData = {
      data,
      sysId,
      sysDocId: sysDocId,
      date: dataDate,
      name: sysId,
      status: "Sending",
    };
    this.props.doPushToSheet(pushQueryData);
  }

  handlePushToDB = () => {
    this.setState({ isPushOngoingToDB: true });
    const date = this.props.dataDate;
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const createLog = moment().format();
    const sysId = this.props.dataSys.id;

    const createPushData = (
      data,
      insights = null,
      allowEmptyInsight = false
    ) => {
      let extra = {};
      if (
        insights &&
        typeof insights === "object" &&
        Object.keys(insights).length
      ) {
        extra.insights = Object.fromEntries(
          Object.entries(insights).filter(([key, val]) => {
            const isNumber = Number.isFinite(Number.parseFloat(val));
            // if falsey values are not allowed, all empty values will be filtered
            return allowEmptyInsight
              ? [key, val]
              : (val || isNumber) && [key, val];
          })
        );
      }
      return {
        dataDate: formattedDate,
        logCreation: createLog,
        data: data,
        adminVersion: process.env.REACT_APP_VERSION,
        createdBy:
          localStorage.getItem(LocalStorageKeys.userEmail) || "unknown",
        statusMessage: "data model 3-Systems",
        statusCode: 0,
        systemId: this.props.dataSys.id,
        ...extra,
      };
    };

    const daily = { ...this.state.vals };
    const stats = { ...this.props.data };

    let token = localStorage.getItem(LocalStorageKeys.userToken);
    let url = "/api/logs?s3=true";
    let urlRaw = "/api/raw-logs?s3=true";
    // fix to Kw if needed
    const systemPowerFactor = this.props.dataSys.systemPowerFactor;
    const daily_sys_power =
      stats.dailySysPower &&
      (!systemPowerFactor || +systemPowerFactor === 1
        ? stats.dailySysPower
        : stats.dailySysPower.map((hourPower) => {
            return +hourPower * +systemPowerFactor;
          }));

    const insights = this.props.dataSys.frozen
      ? {}
      : {
          denoutput: stats.sysDayEnergy,
          daily_sys_power,
        };
    // take webbox from state (a mistake since it should be in props) as "ef-logger"
    const webboxField = daily.webbox;
    if (webboxField && typeof webboxField === "string") {
      insights["ef_logger"] = webboxField;
    }

    const statsData = {
      sys_id: sysId,
      date: formattedDate,
    };

    addFieldIfNotFalse(statsData, "comm", "" + daily.comm);
    addFieldIfNotFalse(statsData, "blck", "" + daily.blackout);
    addFieldIfNotFalse(statsData, "mcm", "" + daily.mcm);
    addFieldIfNotFalse(statsData, "fct", "" + daily.fct);
    addFieldIfNotFalse(statsData, "sys_energy", "" + stats.sysDayEnergy);
    addFieldIfNotFalse(statsData, "sys_acp_max", "" + stats.sysMaxPower);
    addFieldIfNotFalse(
      statsData,
      "sys_acp_maxshift",
      "" + stats.sysMorningPower
    );

    pushSysstats(statsData, token).catch((err) => console.err(err));

    sendData(
      createPushData(
        { stats: { ...this.props.data }, daily: { ...this.state.vals } },
        insights
      ),
      url,
      token
    );
    sendData(createPushData(this.props.results), urlRaw, token).then((res) => {
      this.setState({ isPushOngoingToDB: false });
    });
  };

  handleChange(name, value) {
    const newVals = {
      ...this.state.vals,
      [name]: value,
    };
    this.setState({
      vals: newVals,
    });
  }

  getDataAsTsv() {
    const uniHeaders = INPUT_FIELDS.concat(this.headers);
    const uniData = {
      ...this.state.vals,
      ...this.props.data,
    };
    return helpers.getDataAsTsv(uniData, uniHeaders);
  }

  render() {
    if (this.props.data === null) {
      return <div>Nothing</div>;
    }
    return (
      <ResultsContainer>
        <TableContainer>
          <table id="__results_table">
            <thead>
              <tr>
                {INPUT_FIELDS.map((item) => (
                  <th key={item}>{item === "webbox" ? "logger" : item}</th>
                ))}
                {this.headers.map((header, idx) => (
                  <th key={idx}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {INPUT_FIELDS.map((item, idx) => {
                  if (idx === INPUT_FIELDS.length - 1) {
                    return (
                      <td key={item}>
                        <input
                          type="text"
                          name={item}
                          value={this.state.vals[item]}
                          onChange={(ev) =>
                            this.handleChange(item, ev.target.value)
                          }
                        />
                      </td>
                    );
                  }
                  return (
                    <Select
                      key={item}
                      name={item}
                      value={
                        item in this.state.vals
                          ? this.state.vals[item].toString()
                          : ""
                      }
                      handleChange={this.handleChange}
                    />
                  );
                })}
                {this.headers.map((header, idx) => (
                  <td key={idx}>
                    {header in this.props.data ? this.props.data[header] : ""}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </TableContainer>
        <ActionsPanel>
          <div>
            <button onClick={this.copyFormEl} title="Copy to clipboard">
              Copy
            </button>
            {this.state.isCopied && (
              <span role="img" aria-label="thumb-up">
                👍
              </span>
            )}
          </div>
          <div>
            <button onClick={this.setBlank} title="Set daily fields to blank">
              Blank
            </button>
          </div>
          <div>
            <button onClick={this.setZero} title="Set daily fields to zero">
              Zero
            </button>
          </div>
          {this.props.canPush && (
            <PushUi
              isManualTab={false}
              handlePushClick={this.handlePushClick}
              pushResult={this.props.pushResult}
              handlePushToDB={this.handlePushToDB}
              isPushOngoingToDB={this.state.isPushOngoingToDB}
              typeDB={true}
            />
          )}
        </ActionsPanel>
        <textarea
          id="__copyme"
          readOnly={true}
          value={this.getDataAsTsv()}
          style={{ position: "fixed", left: "100%" }}
        />
      </ResultsContainer>
    );
  }
}

export default StatResultsContent;
