export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const PASSWORD_FORGET = "/pw-forget";
export const HOME = "/";
export const DASHBOARD = "dashboard";
export const ACCOUNT = "account";
export const OPERATION = "operation";
export const USERS = "users";
export const ORGS = "orgs";
export const SYSTEMS = "systems";
export const MESSAGES = "messages";
export const MIGRATIONS = "migrations";
export const INSIGHTS = "insights";
export const SYSMAP = "sysmap-api";
