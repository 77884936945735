import moment from "moment";

const SHEET_ACCESS_KEY = "aabbcc";
const ACCESS_KEY_GET = "aabbcc123";
const MLOG_SHEET_ID = "1gwBVjEiFkDHg6YFcr3plziYj-WKZZteBuRzd127Kc1o";
const MAX_URL_LENGTH = 1800;

/**
 * Push data to google spreadsheet
 *
 * @param {string} rawDate data date
 * @param {Array} data data to set
 * @param {string} docId document ID to push to
 * @param {string} sheetName sheet name in document
 * @param {string} columnIdx index of column to start adding from
 */
export function pushData(rawDate, data, docId, sheetName, columnIdx) {
  const date = moment(rawDate).format("DD/MM/YYYY");
  const url = `${process.env.REACT_APP_SHEET_UPDATER_URL}?access_key=${SHEET_ACCESS_KEY}&doc_id=${docId}&system_id=${sheetName}&date=${date}&col_idx=${columnIdx}`;

  return fetch(url, {
    method: "post",
    body: JSON.stringify(data),
  }).then((response) => response.json());
}

function createSingleUrl(sysIdString, type) {
  return (
    `${process.env.REACT_APP_SHEET_UPDATER_URL}?system_ids=${sysIdString}&access_key=${ACCESS_KEY_GET}` +
    `&doc_id=${MLOG_SHEET_ID}&type=${type}`
  );
}

function createUrls(sysIds, type) {
  const allowedSysLength = MAX_URL_LENGTH - createSingleUrl("", type).length;

  return sysIds.reduce(
    ({ urls, sysString }, sys, idx, idsArr) => {
      if (sysString.length + sys.length > allowedSysLength) {
        urls.push(createSingleUrl(sysString, type));
        sysString = `${sys}`;
      } else {
        sysString += `${sysString.length ? "," : ""}${sys}`;
      }
      // flush if last element
      if (idx === idsArr.length - 1) {
        urls.push(createSingleUrl(sysString, type));
      }
      return { urls, sysString };
    },
    { urls: [], sysString: "" }
  ).urls;
}

/**
 * Load data from mlog
 * TODO: DONE ensure URL is no longer than 2k characters
 * TODO: add single retry for a failed fetch
 * @param {Array<string>} sysIds array of system id's to fetch
 * @param {"daily" | "source" | "monthly" | "annual"} type type of sheet to fetch
 */
export function loadFromSheetsApi(sysIds, type) {
  const urls = createUrls(sysIds, type);

  return Promise.all(urls.map((url) => fetch(url)))
    .then((responses) => Promise.all(responses.map((res) => res.json())))
    .then((results) => results.map((result) => result.payload))
    .then((payloads) =>
      payloads.reduce((finalResult, payload) => {
        return { ...finalResult, ...payload.data };
      }, {})
    )
    .catch((err) => {
      console.error(err);
      return err;
    });
}

// loads all messages from mlog sheet for given systems and year
export function loadFromSheetsMLog(sysIds, year) {
  return loadFromSheetsApi(sysIds, `mlog&year=${year}`);
}
