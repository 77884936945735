import React from "react";
import PropTypes from "prop-types";
import StyledInput from "./StyledInput";

const InvRow = ({ rowIdx, name, data, colCount, onChange }) => (
  <tr>
    <td>{name}</td>
    {[...Array(colCount).fill(0)].map((i, idx) => {
      return (
        <td key={idx}>
          <StyledInput
            value={data[idx]}
            onChange={(ev) => onChange(rowIdx, idx, ev)}
          />
        </td>
      );
    })}
  </tr>
);

InvRow.propTypes = {
  rowIdx: PropTypes.number,
  data: PropTypes.array,
  onChange: PropTypes.func,
};

export default InvRow;
