// [
//   {'2018-11-21T01:00:00+03:00': '233.920', '2018-11-21T02:00:00+03:00': '234.820', ...},
//   {'2018-11-21T01:00:00+03:00': '233.540', '2018-11-21T02:00:00+03:00': '234.220', ...},
//   {'2018-11-21T01:00:00+03:00': '233.240', '2018-11-21T02:00:00+03:00': '235.220', ...}
// ]

// [
//   {'2018-11-21T01:00:00+03:00': ['233.920', '233.540', '233.240'], '2018-11-21T02:00:00+03:00': ['234.820', '234.220', '235.220'], ...},
// ]

/**
 * Transform array of objects to array of objects
 * Allow reordering of the columns and collecting the values into arrays
 * examples for order value
 * - ['2', '0', '1'] - will reorder the columns (0 -> 2, 1 -> 0, 2 -> 1)
 * - ['2.0', '2.1', '2.2'] - will collect all values into arrays in column 2.
 *   The other columns will remain empty, the order of the values in the array will remain as received.
 *
 * @param {Array of objects} srcArr
 * @param {Array of strings} order
 * @param {number} resLength
 */
export function columnsToObjects(srcArr, order, resLength = order.length) {
  const res = Array.from({ length: resLength }, () => ({}));
  // console.log(res);

  srcArr.forEach((colData, idx) => {
    const target = idx < order.length ? order[idx].toString() : "";

    if (target.length > 0) {
      const indices = target.split(".");
      // console.log('col', idx, 'target', target, 'indices', indices);
      Object.keys(colData).forEach((key) => {
        const targetObj = res[indices[0]];
        if (indices.length === 1) {
          targetObj[key] = colData[key];
        } else {
          if (!targetObj[key]) {
            targetObj[key] = [];
          }
          targetObj[key][indices[1]] = colData[key];
        }
        // console.log(res);
      });
    }
  });

  return res;
}

/**
 * Reorder an array, returnning result in a new array
 * reorderArrayFrom(['a', 'b', 'c'], [2, 0, 1]) => ['c', 'a', 'b']
 * @param {arry} arr array to re-order
 * @param {array} order array of [target_idx -> source_idx]
 */
export function reorderArrayFrom(arr, order) {
  const res = new Array(arr.length);
  const uniqeOrder = Array.from(new Set(order));
  if (uniqeOrder.length !== arr.length) {
    const msg =
      "order array has duplicates or doesn't match input array length. " +
      `Expected ${arr.length} but got ${uniqeOrder.length} entries`;
    throw new Error(msg);
  }

  order.forEach((el, idx) => {
    res[idx] = arr[el];
  });

  return res;
}

/**
 * Reorder an array, returnning result in a new array
 * reorderArray(['a', 'b', 'c'], [2, 0, 1]) => ['b', 'c', 'a']
 * @param {array} arr array to re-order
 * @param {array} order array of [source_idx -> target_idx]
 */
export function reorderArrayTo(
  srcArr,
  order,
  resLength = srcArr.length,
  filler = 0
) {
  const res = new Array(resLength).fill(filler);
  const uniqeOrder = Array.from(new Set(order));
  if (uniqeOrder.length !== order.length) {
    const msg = "order array has duplicates: " + order.join(", ");
    throw new Error(msg);
  }

  order.forEach((el, idx) => {
    if (el < res.length && srcArr[idx] !== undefined) {
      res[el] = srcArr[idx];
    }
  });

  return res;
}
