import React from "react";
import { withRouter } from "react-router-dom";

import PasswordForgetLink from "../components/PasswordForgetLink";
import SignInForm from "../components/SigninForm";

const SignInPage = ({ history }) => (
  <div>
    <h1>SignIn</h1>
    <SignInForm history={history} />
    <PasswordForgetLink />
  </div>
);

export default withRouter(SignInPage);
