import React from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TimePickerWrapper = styled.div`
  display: inline-block;
`;

const TIME_FORMAT = "HH:mm";

function TimePicker(props) {
  return (
    <TimePickerWrapper>
      <DatePicker
        {...props}
        showTimeSelect
        showTimeSelectOnly
        dateFormat={TIME_FORMAT}
        timeFormat={TIME_FORMAT}
      />
    </TimePickerWrapper>
  );
}

export default TimePicker;
