import * as React from "react";
import styled from "styled-components";

const TableHeaderCell = styled.th`
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
`;

const HIGHLIGHT_BACKGROUND_COLOR = "#d3d3d3";
const HoverableRow = styled.tr`
  &:hover {
    color: blue;
    background: ${HIGHLIGHT_BACKGROUND_COLOR};
    cursor: default;
  }
`;

const TableDataCell = styled.td`
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
`;

const DbItemsTable = ({ items, headers }) => {
  return (
    <table>
      <thead>
        <tr>
          {headers.map(({ show }, idx) => {
            return <TableHeaderCell key={idx}>{show}</TableHeaderCell>;
          })}
        </tr>
      </thead>
      <tbody>
        {Object.entries(items).map(([entryKey, item]) => {
          const values = headers.map(({ key, mutator }) => {
            const val = mutator ? mutator(item[key]) : item[key];
            return val;
          });
          return (
            <HoverableRow key={entryKey}>
              {values.map((val, idx) => (
                <TableDataCell key={idx}>{val}</TableDataCell>
              ))}
            </HoverableRow>
          );
        })}
      </tbody>
    </table>
  );
};

export default DbItemsTable;
