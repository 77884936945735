import React, { Component } from "react";
import StatsDashboardComponent from "../components/StatsDashboard";
// import { fetchJsonData } from "../firebase/storage";

import Services from "../services";

const SEVERITY = {
  NOTICE: 0,
  NORMAL: 1,
  WARN: 2,
  ERROR: 3,
};

/**
 * Get an array with keys corresponding to empty data results
 * @param {object} data map of data results
 */
function getEmptyKeys(data) {
  const emptyKeys = Object.keys(data).filter((key) => {
    return !Array.isArray(data[key]) || data[key].length === 0;
  });
  return emptyKeys;
}

function getSummaryMessage(data) {
  const emptyKeys = getEmptyKeys(data);
  const allKeysCount = Object.keys(data).length;
  let text, severity;
  if (emptyKeys.length === 0) {
    // all results non-empty
    text = `Done with ${allKeysCount} non-empty results`;
    severity = SEVERITY.NORMAL;
  } else if (emptyKeys.length === allKeysCount) {
    // all results empty
    text = `All ${allKeysCount} results empty: ` + emptyKeys.join(", ");
    severity = SEVERITY.ERROR;
  } else {
    // some results empty
    text =
      `Done with ${emptyKeys.length}/${allKeysCount} empty results: ` +
      emptyKeys.join(", ");
    severity = SEVERITY.WARN;
  }
  return { text, severity };
}

// function calculateValuesForSubSystems() {

// }

const initialState = {
  // systemsDataIsLoading: true,
  // systemsDataLoadError: null,
  systemsData: null,
  isFetching: false,
  message: "",
  severity: 0,
  results: null,
  dataDate: null,
  dataSysId: null,
  regionalCoordinates: null,
  inverterMakeServices: null,
  isDataReady: false,
};

class StatsDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    if (!this.props.systemsDataIsLoading) {
      this.processData();
    }
    // Promise.all([
    //   fetchJsonData("admin/regions.json"),
    //   fetchJsonData("admin/systems.json")
    // ])
    // .then(([regionsData, systemsData]) => {
    //   // filter out reference systems
    //   const systemsArr = Object.values(systemsData).filter(sysData => !sysData.is_reference);
    //   const systemsDataObj = systemsArr.reduce((acc, sys) => {
    //     acc[sys.id] = sys;
    //     return acc;
    //   }, {});
    //   const regionLocations = {}
    //   regionsData.forEach(region => regionLocations[region.id] = region.location)
    //   this.setState({
    //     systemsDataIsLoading: false,
    //     systemsData: systemsDataObj,
    //     regionalCoordinates: regionLocations
    //   });
    // })
    // .catch(error => {
    //   this.setState({
    //     systemsDataIsLoading: false,
    //     systemsDataLoadError: error
    //   });
    // });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.systemsDataIsLoading !== this.props.systemsDataIsLoading) {
      this.processData();
    }
  }

  processData = () => {
    this.setState((state, props) => {
      const {
        regionsData,
        systemsData,
        systemsDataIsLoading,
        systemsDataLoadError,
      } = props;
      if (
        systemsDataIsLoading ||
        systemsDataLoadError ||
        !systemsData ||
        !regionsData
      ) {
        return;
      }
      const systemsArr = Object.values(systemsData).filter(
        (sysData) => !sysData.is_reference && sysData.inverter_make !== "mock"
      );
      const systemsDataObj = systemsArr.reduce((acc, sys) => {
        acc[sys.id] = sys;
        return acc;
      }, {});
      const regionLocations = {};
      regionsData.forEach(
        (region) => (regionLocations[region.id] = region.location)
      );
      return {
        systemsData: systemsDataObj,
        regionalCoordinates: regionLocations,
        isDataReady: true,
      };
    });
  };

  doFetch = (sysId, date, utcOffset) => {
    this.setState({
      isFetching: true,
      message: `Fetching data for sys ${sysId} date ${date.format()}`,
      severity: 0,
    });
    console.log(`Fetch for sys ${sysId} date ${date.format()}`);
    const systemInfo = this.state.systemsData[sysId];
    Services[systemInfo.inverter_make]
      .getData(systemInfo, date, utcOffset)
      // Mock.getData(systemInfo, date, 180)
      .then((data) => {
        console.log("Final data", data);
        const message = getSummaryMessage(data);
        this.setState({
          results: data,
          dataDate: date,
          dataSysId: sysId,
          isFetching: false,
          message: message.text,
          severity: message.severity,
          // function used to calculate hourly means with getStatistics
          inverterMakeServices: Services[systemInfo.inverter_make],
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          data: null,
          isFetching: false,
          message: "Error: " + error.message,
          severity: SEVERITY.ERROR,
        });
      });
  };

  render() {
    if (this.props.systemsDataLoadError) {
      return (
        <div>
          Error Loading Systems Data:
          <br />
          {this.props.systemsDataLoadError.message}
        </div>
      );
    }
    if (this.props.systemsDataIsLoading || !this.state.isDataReady) {
      return <div>Loading Systems Data...</div>;
    }

    return (
      <StatsDashboardComponent
        isFetching={this.state.isFetching}
        message={this.state.message}
        severity={this.state.severity}
        requestData={this.doFetch}
        systems={this.state.systemsData}
        results={this.state.results}
        dataDate={this.state.dataDate}
        dataSysId={this.state.dataSysId}
        regionalCoordinates={this.state.regionalCoordinates}
        inverterMakeServices={this.state.inverterMakeServices}
      />
    );
  }
}

export default StatsDashboard;
