import moment from "moment";
import _ from "lodash";

const MAXSHIFT_HOUR = 15;
const MORNING_POWER_HOUR = 10; // hour to evaluate sysMorningPower for
const INV_MAX_POWER_PREFIX = "invMaxDailyPower";
const INV_LATEST_START_PREFIX = "inv_lstart_time";
const INV_LATEST_STOP_PREFIX = "inv_end_time";
const INV_FIRST_START_PREFIX = "inv_fstart_time";
const INV_MAXSHIFT_ACP_PREFIX = "inv_maxshift_acp";

function nullifyingAverage(...args) {
  const filtered = args.filter((e) => Number.isFinite(e) && e > 0);
  return filtered.length
    ? filtered.reduce((acc, e) => acc + e, 0) / filtered.length
    : 0;
}

/**
 * Calculate statistics from raw data
 * @param {array} sysPower array with 1 item: map of {time -> reading kW}
 * @param {array} sysEnergy array with 1 item: map of {time -> reading kWh}
 * @param {array} invPower item per inverter
 * @param {array} invDcVoltage item per inverter
 * @param {array} invAcVoltageMin item per inverter
 * @param {array} invAcVoltageMax item per inverter
 */
export function getStatistics(
  sysPower,
  sysEnergy,
  invPower,
  invDcVoltage,
  invAcVoltageMin,
  invAcVoltageMax,
  invEnergy,
  chanDcCurrent,
  chanDcPowerInput,
  inverterMakeServices,
  systemData,
  sunTimes
) {
  const result = {};
  const hourlyCollation = inverterMakeServices.collateHourlyData;
  if (!isEmpty(invPower)) {
    invPower.forEach((invData, idx) => {
      // collation of inverter power data
      const vals = _.values(toHourlyMeans(invData, hourlyCollation));
      const rawValues = filterNonEmpty(Object.values(invData));
      if (rawValues.length > 0) {
        result[getIndexedKey("invRawMaxDailyPower", idx)] = Math.max(
          ...rawValues
        );
      }
      if (vals.length > 0) {
        result[getIndexedKey(INV_MAX_POWER_PREFIX, idx)] = Math.max(...vals);
        // TODO: format date
        const invLatestStartTimeStr =
          inverterMakeServices.getLatestStartTime(invData);
        if (!isEmpty(invLatestStartTimeStr)) {
          const invLatestStartTime = moment
            .parseZone(invLatestStartTimeStr)
            .format("HH:mm");
          result[getIndexedKey("invLatestStartTime", idx)] =
            timeToDecimal(invLatestStartTime);
          result[getIndexedKey(INV_LATEST_START_PREFIX, idx)] =
            invLatestStartTime;
        }

        const invLatestStopTimeStr =
          inverterMakeServices.getLatestStopTime(invData);
        if (!isEmpty(invLatestStopTimeStr)) {
          const invLatestStopTime = moment
            .parseZone(invLatestStopTimeStr)
            .format("HH:mm");
          result[getIndexedKey("invLatestStopTime", idx)] =
            timeToDecimal(invLatestStopTime);
          result[getIndexedKey(INV_LATEST_STOP_PREFIX, idx)] =
            invLatestStopTime;
        }

        const invEarliestStartTimeStr = getFirstStartTime(invData);
        if (!isEmpty(invEarliestStartTimeStr)) {
          const invEarliestStartTime = moment
            .parseZone(invEarliestStartTimeStr)
            .format("HH:mm");
          result[getIndexedKey(INV_FIRST_START_PREFIX, idx)] =
            invEarliestStartTime;
        }

        const invMaxshiftAcp = calcMaxshiftAcp(
          invData,
          inverterMakeServices,
          moment(invLatestStopTimeStr),
          systemData
        );
        if (Number.isFinite(invMaxshiftAcp)) {
          result[getIndexedKey(INV_MAXSHIFT_ACP_PREFIX, idx)] = invMaxshiftAcp;
        }
      }
    });
  }
  if (!isEmpty(invDcVoltage)) {
    invDcVoltage.forEach((invData, idx) => {
      const values = filterNonEmpty(Object.values(invData)).map((item) =>
        Number.parseFloat(item)
      );
      if (values.length > 0) {
        result[getIndexedKey("invMedianDcVoltage", idx)] = median(values);
      }
    });
  }

  // sysDayEnergy calculation
  if (!isEmpty(sysEnergy)) {
    result.sysDayEnergy = calculateSysDailyEnergy(sysEnergy);
  } else if (!isEmpty(invEnergy)) {
    const sysEnergy = convertInvEnergyToSysEnergy(invEnergy, hourlyCollation);
    result.sysDayEnergy = calculateSysDailyEnergy(sysEnergy);
  } else if (!isEmpty(invPower)) {
    const factor = Number.isFinite(systemData.inverterPowerFactor)
      ? systemData.inverterPowerFactor
      : 1;
    const sysEnergy = calculateSysEnergyFromInvPower(invPower, factor);
    result.sysDayEnergy = calculateSysDailyEnergy(sysEnergy);
  }

  const chanDcVoltageOperationHours = isEmpty(invDcVoltage)
    ? null
    : invDcVoltage.reduce((acc, singleChanDcVolt) => {
        const nonEmptyValues = Object.entries(singleChanDcVolt).reduce(
          (nonEmptyAcc, [ts, val]) => {
            const numericVal = Number.parseFloat(val);
            if (
              Number.isFinite(numericVal) &&
              moment(ts).isBetween(
                sunTimes.dawn,
                sunTimes.sunset,
                undefined,
                "[]"
              )
            ) {
              nonEmptyAcc[ts] = numericVal;
            }
            return nonEmptyAcc;
          },
          {}
        );

        acc.push(nonEmptyValues);
        return acc;
      }, []);

  if (
    systemData.inverterMapping &&
    (!isEmpty(chanDcPowerInput) || !isEmpty(chanDcVoltageOperationHours))
  ) {
    result.channelstats = systemData.inverterMapping
      .reduce((acc, invData) => {
        // prepare key data for db scheme
        const invChans = invData.channels.map((chanData) => {
          if (invData.disabled || chanData.disabled) {
            return null;
          }
          return {
            sys_id: systemData.id,
            inv_id: invData.id,
            channel_id: "" + chanData.id,
          };
        });
        acc.push(...invChans);
        return acc;
      }, [])
      .map((chanData, idx) => {
        if (chanData === null) {
          return null;
        }
        let emptyValues = true;
        const singleChanDcInput = chanDcPowerInput && chanDcPowerInput[idx];
        const singleChanDcVoltage =
          chanDcVoltageOperationHours && chanDcVoltageOperationHours[idx];

        if (!isEmpty(singleChanDcInput)) {
          const chanHourlyPowerNonEmpty = isEmpty(singleChanDcInput)
            ? null
            : toHourlyMeans(singleChanDcInput, hourlyCollation, true);
          const chanPowerNonEmpty = _.values(chanHourlyPowerNonEmpty);

          if (chanPowerNonEmpty.length > 0) {
            chanData.channel_dcp_max = Math.max(
              ...chanPowerNonEmpty.map((e) => Number.parseFloat(e))
            );
            chanData.channel_dcp_maxshift = getValueByHour(
              chanHourlyPowerNonEmpty,
              MAXSHIFT_HOUR,
              null
            );
            emptyValues = false;
          }
        }

        if (!isEmpty(singleChanDcVoltage)) {
          const medianValue = median(Object.values(singleChanDcVoltage));
          if (Number.isFinite(medianValue)) {
            chanData.channel_dcv = medianValue;
            emptyValues = false;
          }
        }

        return emptyValues ? null : chanData;
      })
      .filter((e) => e);
  }

  if (!isEmpty(sysPower)) {
    // calculate *hourly* means
    // const sysPowerNonEmpty = filterNonEmpty(Object.values(sysPower[0]));
    const sysPowerHourlyNonEmpty = toHourlyMeans(
      sysPower[0],
      hourlyCollation,
      true
    );
    const sysPowerNonEmpty = _.values(sysPowerHourlyNonEmpty);
    if (sysPowerNonEmpty.length > 0) {
      result.sysMaxPower = Math.max(...sysPowerNonEmpty);
      // add 10:00 power value
      // TODO: DONE fix time offset
      result.sysMorningPower = nullifyingAverage(
        +getValueByHour(sysPowerHourlyNonEmpty, MORNING_POWER_HOUR),
        +getValueByHour(sysPowerHourlyNonEmpty, MAXSHIFT_HOUR)
      );
      result.dailySysPower = createDailySysPowerArray(sysPowerHourlyNonEmpty);
    }
    // sysPower is empty, use invPower instead
  } else if (!isEmpty(invPower)) {
    const factor = Number.isFinite(systemData.inverterPowerFactor)
      ? systemData.inverterPowerFactor
      : 1;
    const sysPower = convertInvPowerToSysPower(
      invPower,
      hourlyCollation,
      factor
    );
    const sysPowerHourlyNonEmpty = toHourlyMeans(sysPower, null);

    const sysPowerNonEmptyValues = filterNonEmpty(
      _.values(sysPowerHourlyNonEmpty)
    ); // array of non empty values
    if (sysPowerNonEmptyValues.length > 0) {
      const sysPowerNumberValues = sysPowerNonEmptyValues.map(
        (sysData) => +Number.parseFloat(sysData).toFixed(3)
      ); // array of number type values
      result.sysMaxPower = Math.max(...sysPowerNumberValues); // sysMaxPower
      result.sysMorningPower = nullifyingAverage(
        +getValueByHour(sysPowerHourlyNonEmpty, MORNING_POWER_HOUR),
        +getValueByHour(sysPowerHourlyNonEmpty, MAXSHIFT_HOUR)
      );
      result.dailySysPower = createDailySysPowerArray(sysPowerHourlyNonEmpty);
    }
  }

  if (!isEmpty(invAcVoltageMin)) {
    const invAcVoltageMins = [];

    invAcVoltageMin.forEach((oneInvAcVoltageMins) => {
      const { min } = calcCleanMinMax(oneInvAcVoltageMins);
      if (Number.isFinite(min)) {
        invAcVoltageMins.push(min);
      }
    });
    // to avoid a situation where Infinity is returned
    result.sysAcVoltageMin =
      invAcVoltageMins.length > 0 ? Math.min(...invAcVoltageMins) : null;
  }
  if (!isEmpty(invAcVoltageMax)) {
    const invAcVoltageMaxs = [];

    invAcVoltageMax.forEach((oneInvAcVoltageMaxs) => {
      const { max } = calcCleanMinMax(oneInvAcVoltageMaxs);
      if (Number.isFinite(max)) {
        invAcVoltageMaxs.push(max);
      }
    });
    // to avoid a situation where -Infinity is returned
    result.sysAcVoltageMax =
      invAcVoltageMaxs.length > 0 ? Math.max(...invAcVoltageMaxs) : null;
  }

  if (systemData.inverterMapping) {
    result.invStatsMapping = getInvStats(systemData, result, invEnergy);
  }

  return result;
}

function getInvStats(systemData, sysStats, invEnergy) {
  const result = [];
  const inverterMapping = systemData.inverterMapping;

  // calculated to get the next index as a channel out of stats
  let calcedIdx = 0;
  for (let i = 0; i < inverterMapping.length; ++i) {
    const invData = inverterMapping[i];
    // check if invEnergy is defined and not empty
    let inv_output;
    if (Array.isArray(invEnergy) && invEnergy.length) {
      inv_output = Object.values(invEnergy[calcedIdx]).reduce((sum, val) => {
        const numVal = Number.parseFloat(val);
        return Number.isFinite(numVal) ? sum + numVal : sum;
      }, 0);
    }
    const inv_max_acp =
      sysStats[getIndexedKey(INV_MAX_POWER_PREFIX, calcedIdx)];

    const inv_lstart_time =
      sysStats[getIndexedKey(INV_LATEST_START_PREFIX, calcedIdx)];

    const inv_end_time =
      sysStats[getIndexedKey(INV_LATEST_STOP_PREFIX, calcedIdx)];

    const inv_fstart_time =
      sysStats[getIndexedKey(INV_FIRST_START_PREFIX, calcedIdx)];

    const inv_maxshift_acp =
      sysStats[getIndexedKey(INV_MAXSHIFT_ACP_PREFIX, calcedIdx)];

    const invValues = {
      inv_max_acp,
      inv_fstart_time,
      inv_lstart_time,
      inv_end_time,
      inv_output,
      inv_maxshift_acp,
    };

    result.push({
      inv_id: invData.id,
      // filter empty values
      ...Object.entries(invValues).reduce((acc, [k, v]) => {
        if (v !== undefined) {
          acc[k] = v;
        }
        return acc;
      }, {}),
    });
    calcedIdx += invData.channels.length;
  }
  return result;
}

export function timeToDecimal(time) {
  const parts = time.split(":");
  return parseInt(parts[0], 10) + parseInt(parts[1], 10) / 60;
}

/**
 * filter an object by date
 * @param {object} data keys are iso dates
 * @param {string} date iso date string
 */
export function filterForDate(data, date) {
  return Object.assign(
    ...Object.keys(data)
      .filter((key) => moment(date).isSame(key, "day"))
      .map((key) => ({ [key]: data[key] }))
  );
}

/**
 *
 * @param {string} prefix prefix to prepend
 * @param {integer} index the index to pad
 * @param {string} pad string of zeroes in total length of padded index
 */
export function getIndexedKey(prefix, index, pad = "00") {
  const indexPadded = (pad + index).slice(-pad.length);
  return prefix + indexPadded;
}

/**
 * calculate system daily energy
 * @param {object} sysEnergy sysEnergy object
 */

export function calculateSysDailyEnergy(sysEnergy) {
  const sysEnergyObj = Array.isArray(sysEnergy) ? sysEnergy[0] : sysEnergy;
  const sysEnergyNonEmptyValues = filterNonEmpty(_.values(sysEnergyObj));
  if (sysEnergyNonEmptyValues.length > 0) {
    const sysDayEnergy = sysEnergyNonEmptyValues.reduce((sum, val) => {
      const numVal = Number.parseFloat(val);
      return +sum + numVal;
    }, 0);
    return +Number.parseFloat(sysDayEnergy).toFixed(3);
  } else {
    return "";
  }
}

/**
 * calculate sysData object by hours using array of invertors objects by hours
 * @param {array} invData array that contains invertor objects with hourly inverter data
 */
export function calculateSysData(invData) {
  const sysData = _.reduce(
    [...invData],
    (acc, cur) =>
      _.mergeWith(acc, cur, (objValRaw, srcValRaw) => {
        const objVal = Number.parseFloat(objValRaw);
        const prevValue = Number.isFinite(objVal)
          ? Number.parseFloat(objVal)
          : "";

        const srcVal = Number.parseFloat(srcValRaw);
        const currValue = Number.isFinite(srcVal)
          ? Number.parseFloat(srcVal)
          : "";
        return prevValue !== "" || currValue !== ""
          ? Number.parseFloat(prevValue + currValue)
          : null;
      }),
    {}
  );

  const [firstMmnt, secondMmnt] = Object.keys(sysData)
    .sort()
    .map((ts) => moment(ts))
    .slice(0, 2);
  const factor = secondMmnt.diff(firstMmnt, "minutes") / 60;

  return Object.fromEntries(
    Object.entries(sysData).map(([ts, datum]) => [ts, datum * factor])
  );

  // if the object has 15-minutes-intervals structure
  // if (_.keys(sysData).length > 24) {
  //   let dateKeys = _.keys(sysData);
  //   let intervalsValues = _.values(sysData);
  //   let formattedSysData = {};
  //   const dateKeysLength = dateKeys.length;
  //   const currentHour = moment(dateKeys[dateKeysLength - 1]).clone(); // get 00:00:00 time (_.keys places '00:00:00' key at the end of array)
  //   currentHour.add(1, "hours"); // start iteration from 01:00:00
  //   for (let i = 1; i <= dateKeysLength; i++) {
  //     // check if hour was iterated through all quaters (15 min intervals)
  //     if (i % 4 === 0) {
  //       const formattedHour = currentHour.format("YYYY-MM-DDTHH:mm:ssZ");
  //       formattedSysData[formattedHour] = [
  //         ...formattedSysData[formattedHour],
  //         intervalsValues[i - 1],
  //       ];
  //       currentHour.add(1, "hours"); // move to next hour
  //     } else {
  //       const formattedHour = currentHour.format("YYYY-MM-DDTHH:mm:ssZ");
  //       formattedSysData[formattedHour] = formattedSysData[formattedHour]
  //         ? [...formattedSysData[formattedHour], intervalsValues[i - 1]]
  //         : [intervalsValues[i - 1]];
  //     }
  //   }
  //   for (let i in formattedSysData) {
  //     const nonEmptyIntervalValues = filterNonEmpty(formattedSysData[i]);
  //     if (nonEmptyIntervalValues.length > 0) {
  //       const hourIntervalsSum = _.reduce(
  //         nonEmptyIntervalValues,
  //         (acc, curr) => {
  //           const floatCurr = Number.parseFloat(curr);
  //           const floatAcc = Number.parseFloat(acc);
  //           return floatAcc + floatCurr;
  //         },
  //         0
  //       );
  //       formattedSysData[i] = (hourIntervalsSum / 4).toFixed(3); // get average data for 1 hour
  //     } else {
  //       formattedSysData[i] = ""; // if all hour data is empty
  //     }
  //   }
  //   return formattedSysData;
  // } else {
  //   return sysData;
  // }
}

function calculateSysEnergyFromInvPower(
  invPower,
  factor = 1,
  secondDelta = 200
) {
  const { powerSum } = invPower.reduce(
    (acc, invObj) => {
      for (const [ts, val] of Object.entries(invObj)) {
        const parsedVal = Number.parseFloat(val);
        if (Number.isFinite(parsedVal)) {
          // create moment object and duration as supplied
          const timestampMoment = moment(ts);
          const duration = moment.duration(secondDelta, "seconds");
          // find existing moment bucket if exists, otherwise create it
          let momentBucket = acc.accMoments.find((bucket) => {
            return timestampMoment.isBetween(
              moment(bucket.mmnt).subtract(duration),
              moment(bucket.mmnt).add(duration),
              undefined,
              "()"
            );
          });
          if (!momentBucket) {
            momentBucket = {
              mmnt: timestampMoment,
              key: ts,
            };
            acc.accMoments.push(momentBucket);
          }

          const { key } = momentBucket;

          if (!Number.isFinite(acc.powerSum[key])) {
            acc.powerSum[key] = 0;
          }
          acc.powerSum[key] += parsedVal;
        }
      }
      return acc;
    },
    { powerSum: {}, accMoments: [] }
  );
  const timestamps = Object.keys(powerSum).sort();

  const result = [{}];
  if (timestamps.length > 1) {
    for (let i = 0; i < timestamps.length - 1; ++i) {
      const curr = timestamps[i];
      const next = timestamps[i + 1];
      const duration = moment.duration(moment(next).diff(curr)).as("hours");
      result[0][curr] =
        factor * 0.5 * duration * (powerSum[curr] + powerSum[next]);
    }
  }

  return result;
}

/**
 * calculate mean of array
 * @param {array of values} data
 */
export function mean(data) {
  if (data.length === 0) return NaN;
  const sum = data.reduce((a, b) => a + b, 0);
  return sum / data.length;
}

/**
 * Calculate the median for an array of numbers
 * @param {array} values
 */
export function median(rawValues) {
  const values = rawValues
    .filter((val) => val !== "")
    .map((val) => Number.parseFloat(val))
    .sort((a, b) => a - b);
  const half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];
  else return (values[half - 1] + values[half]) / 2.0;
}

/**
 * Check if input is an empty array, empty object or null
 * furthermore, makes sure that in case of a given array that it contains at least one
 * non empty value, up to specified depth,
 * in case depth is reached, false returned
 * @param {any} val
 * @param {Number} depth
 */
export function isEmpty(val, depth = 2) {
  depth = Number(depth);
  if (!Number.isInteger(depth) || depth <= 0) {
    return false;
  }
  return (
    val === null ||
    val === undefined ||
    (typeof val === "object" && Object.values(val).length === 0) ||
    (Array.isArray(val) &&
      (val.length === 0 || val.every((e) => isEmpty(e, depth - 1))))
  );
}

/**
 *
 * @param {array} arr
 */
export function filterNonEmpty(arr) {
  return arr.filter((item) => item !== "" && item !== null);
}

/**
 * check whether the parameter represents time with activity
 * @param {string | number} val
 */
export function isActive(val) {
  const numVal = Number.parseFloat(val);
  return !Number.isNaN(numVal) && numVal !== 0;
}

/**
 * Retrieve last non-empty entry
 * @param {object} data
 */
export function getLatestDataTime(
  dataArr,
  filterFunc = (val) => !Number.isNaN(Number.parseFloat(val))
) {
  const times = dataArr.map((data) => {
    const keys = Object.keys(data).filter((date) => filterFunc(data[date]));
    keys.sort();
    return keys.length > 0 ? keys[keys.length - 1] : null;
  });
  const nonEmptyTimes = times.filter((time) => !!time);
  nonEmptyTimes.sort();
  return nonEmptyTimes.length > 0
    ? nonEmptyTimes[nonEmptyTimes.length - 1]
    : null;
}

// for later use, when dst calculation will be added
function calcMaxshiftTime() {
  return 16;
}

function calcMaxshiftAcp(
  data,
  inverterMakeServices,
  invLatestStopTime,
  systemData
) {
  const maxshiftTime = calcMaxshiftTime();
  const maxshiftMoment = moment(invLatestStopTime).hour(maxshiftTime);

  if (invLatestStopTime.isSameOrBefore(maxshiftMoment)) {
    return null;
  }
  const hourlyMeans = toHourlyMeans(
    data,
    inverterMakeServices.collateHourlyData,
    true
  );

  return Number.parseFloat(getValueByHour(hourlyMeans, maxshiftTime));
}

/**
 * Retrieve first non-empty entry
 * @param {object} data
 */
export function getEarliestDataTime(dataArr) {
  const times = dataArr.map((data) => {
    // non empty dates
    const keys = Object.keys(data).filter((date) => {
      const datum = data[date];
      return !!datum || Number.isFinite(Number.parseFloat(datum));
    });
    // sort
    keys.sort();
    // return first non empty
    return keys.length > 0 ? keys[0] : null;
  });
  // non empty dates
  const nonEmptyTimes = times.filter((time) => !!time);
  // sort
  nonEmptyTimes.sort();
  // return first non empty
  return nonEmptyTimes.length > 0 ? nonEmptyTimes[0] : null;
}

function getFirstStartTime(data) {
  const timestamps = Object.keys(data).sort();
  for (const ts of timestamps) {
    const datum = Number.parseFloat(data[ts]);
    // return first timestamp for which value is positive
    if (datum > 0 && Number.isFinite(datum)) {
      return ts;
    }
  }
  return null;
}

/**
 *
 * @param {object} data time -> value
 * @param {function} isActiveCb function to determine if a value is considered active
 */
export function getLatestStartTime(data, isActiveCb = isActive) {
  const keys = Object.keys(data);
  if (keys.length === 0) {
    return null;
  }
  keys.sort();
  let res = isActiveCb(data[keys[0]]) ? keys[0] : null;
  for (let i = keys.length - 1; i > 0; i--) {
    if (!isActiveCb(data[keys[i - 1]]) && isActiveCb(data[keys[i]])) {
      res = keys[i - 1];
      break;
    }
  }
  // if (res === null && keys.length > 0) {
  //   res = keys[keys.length - 1];
  // }
  return res;
}

/**
 *
 * @param {object} data time -> value
 * @param {function} isActiveCb function to determine if a value is considered active
 *
 */
export function getLatestStopTime(data, isActiveCb = isActive) {
  const dataEntries = Object.entries(data).sort((log1, log2) =>
    log1[0] < log2[0] ? -1 : 1
  );
  for (let i = dataEntries.length - 1; i >= 0; --i) {
    if (isActiveCb(dataEntries[i][1])) {
      break;
    }
    dataEntries.pop();
  }
  const length = dataEntries.length;
  if (length === 0) {
    return null;
  }
  return dataEntries[length - 1][1] ? dataEntries[length - 1][0] : null;
  // for (let i = keys.length - 1; i > 1; i--) {
  //   if (!isActiveCb(data[keys[i]]) && isActiveCb(data[keys[i - 1]])) {
  //     res = keys[i - 1];
  //     break;
  //   }
  // }
  // if (res === null && keys.length > 0) {
  //   res = keys[0];
  // }
  // return res;
}

export function calcCleanMinMax(data) {
  // remove empty values
  const numVals = Object.values(data).filter(
    (val) => val !== "" && val !== null
  );
  if (!numVals.length) {
    return {
      min: null,
      max: null,
    };
  }
  // remove extremes (first and last values)
  const cleanVals = numVals.length > 2 ? numVals.slice(1, -1) : numVals;
  // calculate min and max
  const min = Math.min(...cleanVals);
  const max = Math.max(...cleanVals);
  return {
    min,
    max,
  };
}

// naive collation
function defaultHourlyCollation(data, mins = 60, keepOffset = false) {
  if (!Number.isInteger(mins) || mins <= 0 || 60 % mins !== 0) {
    mins = 60;
  }
  const midRes = {};
  Object.keys(data).forEach((timeStamp) => {
    const currentMoment = moment(timeStamp);
    const currentHour = currentMoment
      .subtract(currentMoment.minutes() % mins, "minutes")
      .startOf("minute")
      .toISOString(keepOffset);
    const value = Number.parseFloat(data[timeStamp]);
    if (!Number.isNaN(value)) {
      if (!(currentHour in midRes)) {
        midRes[currentHour] = [];
      }
      midRes[currentHour].push(value);
    }
  });
  return midRes;
}

/**
 *
 * @param {object of timestamp to measure} data
 */
export function toHourlyMeans(
  data,
  hourCollation = null,
  collationKeepOffset = false
) {
  // step 1: collect in hourly arrays
  let midRes = {};
  if (hourCollation === null) {
    midRes = Object.fromEntries(
      Object.entries(data).map(([time, value]) => [time, [value]])
    );
  } else {
    midRes = hourCollation(data, 60 /* minutes */, collationKeepOffset);
  }

  const finRes = {};
  Object.keys(midRes).forEach((timeStamp) => {
    finRes[timeStamp] = mean(midRes[timeStamp]);
  });

  return finRes;
}

// arbitrary minute interval means, by default quarter hour, works like hourly means
export function toMinuteIntervalMeans(
  data,
  hourCollation = null,
  collationKeepOffset = false,
  minutesInterval = 15
) {
  // step 1: collect in hourly arrays
  let midRes = {};
  if (hourCollation === null) {
    midRes = Object.fromEntries(
      Object.entries(data).map(([time, value]) => [time, [value]])
    );
  } else {
    midRes = hourCollation(
      data,
      minutesInterval /* minutes */,
      collationKeepOffset
    );
  }

  const finRes = {};
  Object.keys(midRes).forEach((timeStamp) => {
    finRes[timeStamp] = mean(midRes[timeStamp]);
  });

  return finRes;
}

// gets a timestamp string with a utc offset, returns the offset in full hour
function getUTCOffset(timestamp) {
  const token = timestamp.includes("+") ? "+" : "-";
  return parseInt(timestamp.split(token)[1], 10);
}

/**
 *
 * @param {object of timestamp to measure} data
 */
export function getValueByHour(data, hour) {
  const hourStr = `T${hour.toString().padStart(2, "0")}:`;
  const key = Object.keys(data).find((aKey) => aKey.includes(hourStr));
  if (key && data[key]) {
    return data[key];
  }
  return 0;
}

export function convertInvEnergyToSysEnergy(invEnergy, collationFunc) {
  const collatedEnergy = invEnergy.map((invData) => {
    return Object.fromEntries(
      Object.entries(
        (collationFunc ? collationFunc : defaultHourlyCollation)(
          invData,
          60 /* minutes */,
          true
        )
      ).map(([time, values]) => {
        return [time, values.reduce((sum, val) => sum + val, 0)];
      })
    );
  });
  const sysEnergy = {};
  collatedEnergy.forEach((inv, idx, arr) => {
    Object.keys(inv).forEach((time) => {
      if (!sysEnergy.hasOwnProperty(time)) {
        sysEnergy[time] = arr.reduce((sum, curr) => {
          const toAdd = isFinite(Number(curr[time])) ? Number(curr[time]) : 0;
          return sum + toAdd;
        }, 0);
      }
    });
  });
  return sysEnergy;
}

export function convertInvPowerToSysPower(invPower, collationFunc, factor) {
  const unifiedPower = {};
  invPower.forEach((inv) => {
    Object.keys(inv).forEach((time) => {
      const value = Number.parseFloat(inv[time]);
      if (!Number.isFinite(value)) {
        return;
      }
      if (!unifiedPower.hasOwnProperty(time)) {
        unifiedPower[time] = 0;
      }
      unifiedPower[time] += value;
    });
  });
  return Object.fromEntries(
    Object.entries(collationFunc(unifiedPower, 60 /* minutes */, true)).map(
      ([time, values]) => {
        const length = values.length ? values.length : 1;
        return [
          time,
          ((sum) => factor * (sum / length))(
            values.reduce((sum, val) => sum + val, 0)
          ),
        ];
      }
    )
  );
}

// create an array for insight table
function createDailySysPowerArray(hourlyPower) {
  const HOURS_IN_DAY = 24;
  const result = Array(HOURS_IN_DAY).fill(null);
  Object.entries(hourlyPower).forEach(([ts, value]) => {
    const hour = Number.parseInt(moment(ts).hour(), 10);
    result[hour] = +Number.parseFloat(value).toFixed(3);
  });
  // if all values are null, return null;
  return result.some((e) => e !== null) ? result : null;
}

function isNumericString(str) {
  return typeof str === "string" && str !== "" && isFinite(str);
}

export function convertInvPowerToSysPowerCommCalc(invPower) {
  if (!Array.isArray(invPower) || !invPower.length) {
    return [];
  }
  const sysPower = invPower.reduce((unifiedPower, inv) => {
    Object.entries(inv).forEach(([ts, value]) => {
      if (!unifiedPower.hasOwnProperty(ts)) {
        unifiedPower[ts] = value;
      } else {
        const unifiedVal = unifiedPower[ts];
        // both numbers, naive addition
        if (typeof unifiedVal === "number" && typeof value === "number") {
          unifiedPower[ts] = unifiedVal + value;
          // both numeric strings, add and convert back
        } else if (isNumericString(unifiedVal) && isNumericString(value)) {
          unifiedPower[ts] = `${+unifiedVal + +value}`;
          // at least one of them is not a parsable number, keep the other one
        } else {
          unifiedPower[ts] = value || unifiedVal;
        }
      }
    });
    return unifiedPower;
  }, {});
  return [sysPower];
}
