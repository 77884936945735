import React, { Component } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { sendData } from "../services/sendData";
import localStorageKeys from "../localStorageKeys";

const Section = styled.div`
  padding: 10px 0;
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
`;

const ButtonInRow = styled.button`
  margin-right: 1em;
`;

const HIGHLIGHT_BACKGROUND_COLOR = "#d3d3d3";
const HoverableRow = styled.tr`
  &:hover {
    color: blue;
    background: ${HIGHLIGHT_BACKGROUND_COLOR};
    cursor: default;
  }
`;

const TableHeaderCell = styled.th`
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
`;

const TableDataCell = styled.td`
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
`;

const URLS = {
  getUsers: "/api/sysmap/users",
  getOrgs: "/api/sysmap/orgs",
  manipulateUser: "/api/sysmap/user",
};

class SysmapUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      users: [],
      orgs: [],
      editedUser: null,
    };
  }

  loadData = () => {
    if (!this._isMounted) {
      return;
    }

    this.setState(
      {
        isLoading: true,
        isError: false,
        users: [],
        orgs: [],
        editedUser: null,
      },
      async () => {
        try {
          const token = localStorage.getItem(localStorageKeys.userToken);
          const usersRes = await sendData(null, URLS.getUsers, token, "GET");
          const orgsRes = await sendData(null, URLS.getOrgs, token, "GET");

          if (!usersRes.ok || !orgsRes.ok) {
            throw new Error("bad responses");
          }

          const users = usersRes.data.map(({ user_id, email, org_id }) => {
            return {
              userId: user_id,
              orgId: org_id || "",
              email,
            };
          });

          const orgs = [
            { orgId: "", orgName: "no org" },
            ...orgsRes.data.map(({ org_id, org_name }) => {
              return {
                orgId: org_id,
                orgName: org_name,
              };
            }),
          ];

          if (!this._isMounted) {
            return;
          }
          this.setState({
            users,
            orgs,
            isLoading: false,
            isError: false,
          });
        } catch (error) {
          console.error(error);
          this.setState({ isLoading: false, isError: true });
        }
      }
    );
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleAddUser = () => {
    this.setState({
      editedUser: {
        isNew: true,
        userId: uuidv4(),
        orgId: "",
        email: "",
      },
    });
  };

  handleEditProp = (propName) => (ev) => {
    const newVal = ev.target.value;
    this.setState((state) => {
      const editedUser = { ...state.editedUser };
      editedUser[propName] = newVal;
      return {
        editedUser,
      };
    });
  };

  handleEditUser = (userId, email, orgId) => () => {
    this.setState({
      editedUser: {
        isNew: false,
        userId,
        orgId,
        email,
      },
    });
  };

  handleSaveChanges = () => {
    this.setState({ isLoading: true }, async () => {
      try {
        const { userId, orgId, email, isNew } = this.state.editedUser;
        const org_id = orgId || null;

        const token = localStorage.getItem(localStorageKeys.userToken);
        const editUserRes = await (isNew
          ? sendData(
              { values: { user_id: userId, email, org_id, hashed_pass: "" } },
              URLS.manipulateUser,
              token,
              "POST"
            )
          : sendData(
              {
                where: { user_id: userId },
                values: { org_id, email },
              },
              URLS.manipulateUser,
              token,
              "PUT"
            ));

        if (!editUserRes.ok) {
          window.alert("Error, try again later");
          return this.setState({ isLoading: false });
        }
      } catch (error) {
        console.error(error);
      }

      this.loadData();
    });
  };

  handleCancelEdit = () => {
    this.setState({ editedUser: null });
  };

  handleDeleteUser = () => {
    const userRes = window.confirm(
      "you are about to delete a user, are you sure?"
    );

    if (!userRes) {
      return;
    }

    this.setState({ isLoading: true }, async () => {
      try {
        const token = localStorage.getItem(localStorageKeys.userToken);
        await sendData(
          { where: { user_id: this.state.editedUser.userId } },
          URLS.manipulateUser,
          token,
          "DELETE"
        );
        this.loadData();
      } catch (error) {
        console.error(error);
      }
    });
  };

  render() {
    const { isLoading, isError, users, orgs, editedUser } = this.state;

    if (isError) {
      return <Section>Error loading data</Section>;
    }
    if (isLoading) {
      return <Section>Loading data...</Section>;
    }

    if (editedUser) {
      const { isNew, userId, orgId, email } = editedUser;
      return (
        <Section>
          <h3>
            {isNew ? "add" : "edit"} user - '{email}'
          </h3>
          <Section>uuid: {userId}</Section>
          <Section>
            email:{" "}
            <input
              type="text"
              value={email}
              onChange={this.handleEditProp("email")}
            />
          </Section>
          <Section>
            org:{" "}
            <select value={orgId} onChange={this.handleEditProp("orgId")}>
              {orgs.map((org, idx) => {
                return (
                  <option key={idx} value={org.orgId}>
                    {org.orgName} ({org.orgId || "none"})
                  </option>
                );
              })}
            </select>
          </Section>
          <Section>
            <button disabled={isNew} onClick={this.handleDeleteUser}>
              delete
            </button>
          </Section>
          <Section>
            <ButtonInRow onClick={this.handleSaveChanges}>save</ButtonInRow>
            <ButtonInRow onClick={this.handleCancelEdit}>cancel</ButtonInRow>
          </Section>
        </Section>
      );
    }

    return (
      <Section>
        <h3>Sysmap users:</h3>
        <Section>
          <ButtonInRow onClick={this.handleAddUser}>new user</ButtonInRow>
          <ButtonInRow onClick={this.loadData}>refresh data</ButtonInRow>
        </Section>
        <table>
          <thead>
            <tr>
              <TableHeaderCell>uuid</TableHeaderCell>
              <TableHeaderCell>email</TableHeaderCell>
              <TableHeaderCell>org</TableHeaderCell>
              <TableHeaderCell>edit</TableHeaderCell>
            </tr>
          </thead>
          <tbody>
            {users.map(({ userId, orgId, email }, idx) => {
              const org = orgs.find((o) => o.orgId === orgId);
              return (
                <HoverableRow key={idx}>
                  <TableDataCell>{userId}</TableDataCell>
                  <TableDataCell>{email}</TableDataCell>
                  <TableDataCell>
                    {org.orgName} ({orgId || "none"})
                  </TableDataCell>
                  <TableDataCell>
                    <button onClick={this.handleEditUser(userId, email, orgId)}>
                      edit
                    </button>
                  </TableDataCell>
                </HoverableRow>
              );
            })}
          </tbody>
        </table>
      </Section>
    );
  }
}

export default SysmapUsers;
