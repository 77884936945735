import React from "react";

import ManualMessagesForm from "../components/MessagesManual";

class MessagesManual extends React.Component {
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { noRefSystems, testSystems, frozenSystems, sysMatch } = this.props;
    const systems = {
      "": { id: "", name: "" },
      ...(testSystems || {}),
      ...(noRefSystems || {}),
      ...(frozenSystems || {}),
    };
    if (Object.keys(systems).length <= 1) {
      return <div>Loading systems...</div>;
    }
    return <ManualMessagesForm systems={systems} sysMatch={sysMatch} />;
  }
}

export default MessagesManual;
