import React from "react";
import SystemsList from "../components/DBItemsTable";

class SystemsNames extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  boolMutator = (val) => {
    return !!val ? "V" : "X";
  };

  render() {
    const { isLoading, loadError, systemsData } = this.props;

    return (
      <div>
        <h1>Systems</h1>
        {loadError ? (
          "Error loading"
        ) : isLoading || !systemsData ? (
          "Loading..."
        ) : (
          <SystemsList
            items={systemsData}
            headers={[
              { key: "id", show: "ID" },
              { key: "name", show: "Name" },
              { key: "frozen", show: "Frozen", mutator: this.boolMutator },
              {
                key: "is_reference",
                show: "is_reference (old)",
                mutator: this.boolMutator,
              },
              {
                key: "reference",
                show: "reference (new)",
                mutator: this.boolMutator,
              },
            ]}
          />
        )}
      </div>
    );
  }
}

export default SystemsNames;
