import React from "react";
import PropTypes from "prop-types";
import StyledInput from "./StyledInput";

// const Select = (props) => (
//   <select
//     name={props.name}
//     onChange={(ev) => props.handleChange(props.name, ev.target.value)}
//     value={props.value}
//   >
//     <option key="empty" value=""> </option>
//     <option key="0" value="0">0</option>
//     <option key="1" value="1">1</option>
//     <option key="2" value="2">2</option>
//     <option key="3" value="3">3</option>
//   </select>
// )

const DailyFuncs = ({ funcNames, values, onChange }) => (
  <table>
    <thead>
      <tr>
        {funcNames.map((name) => {
          let text = name;
          if (name === "webbox") {
            text = "logger";
          }
          return <th key={name}>{text}</th>;
        })}
      </tr>
    </thead>
    <tbody>
      <tr>
        {funcNames.map((name, idx) => (
          <td key={name}>
            <StyledInput
              type="text"
              name={name}
              value={values[idx]}
              onChange={(ev) => onChange(idx, ev)}
            />
          </td>
        ))}
      </tr>
    </tbody>
  </table>
);

DailyFuncs.propTypes = {
  funcNames: PropTypes.array,
  values: PropTypes.array,
  onChange: PropTypes.func,
};

export default DailyFuncs;
