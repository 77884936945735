import React from "react";

function getList(title, listObj, buttonFunc) {
  return (
    <div style={{ marginTop: "1em" }}>
      <strong>{title}</strong>
      <ul>
        {Object.values(listObj).map((job) => {
          return (
            <li key={job.id}>
              {job.id} - '{job.status}' at{" "}
              {job.lastChangeTimestamp.format("DD/MM, HH:mm")}
              <button
                onClick={(ev) => {
                  ev.preventDefault();
                  buttonFunc(job.id);
                }}
                style={{ marginLeft: "0.5em" }}
              >
                retry
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function DoneList(props) {
  const { doneJobs, canceledJobs, retryJob } = props;
  return (
    <div style={{ marginTop: "1em" }}>
      {getList("done jobs", doneJobs, retryJob)}
      {getList("failed/canceled jobs", canceledJobs, retryJob)}
    </div>
  );
}

export default DoneList;
