import React, { Component } from "react";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import styled from "styled-components";
import SystemsSelect from "../SystemsDropDown";
import DatePickerCombo from "../DatePickerCombo";

const Label = styled.span`
  min-width: 130px;
  display: inline-block;
`;
const Row = styled.div``;

class SelectionForm extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    systems: PropTypes.object,
    doFetch: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const now = moment();

    this.state = {
      selectedSystem: "",
      selectedDate: now,
    };
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.props.doFetch(
      this.state.selectedSystem,
      this.state.selectedDate,
      this.state.selectedDate.utcOffset()
    );
  };

  onSystemSelect = (sysId) => {
    this.setState({
      selectedSystem: sysId,
    });
  };

  onDateSelect = (date) => {
    this.setState({
      selectedDate: date,
    });
  };
  onDateMove = (delta) => {};
  movePrevDay = () => {
    this.setState({
      selectedDate: this.state.selectedDate.add(-1, "days"),
    });
  };
  moveNextDay = () => {
    this.setState({
      selectedDate: this.state.selectedDate.add(1, "days"),
    });
  };

  render() {
    const currentSystem = this.props.systems[this.state.selectedSystem];
    return (
      <div>
        <Row>
          <Label>Select system:</Label>
          <SystemsSelect
            systems={Object.values(this.props.systems)}
            onSystemSelect={this.onSystemSelect}
            selectedSystem={this.state.selectedSystem}
          />
        </Row>
        <Row>
          <Label>Pick a date:</Label>
          <DatePickerCombo
            selected={this.state.selectedDate}
            onChange={this.onDateSelect}
          />
        </Row>
        <Row>
          <button
            onClick={this.handleSubmit}
            disabled={this.props.isFetching || this.state.selectedSystem === ""}
          >
            {this.props.isFetching ? "Loading..." : "Run"}
          </button>
          &nbsp;&nbsp;&nbsp;
          <span>
            {currentSystem &&
              `${currentSystem.id}: ${currentSystem.inverter_make}`}
          </span>
        </Row>
      </div>
    );
  }
}

export default SelectionForm;
