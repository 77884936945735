import React from "react";
import styled from "styled-components";
import { Route, NavLink, withRouter, Redirect, Switch } from "react-router-dom";
import withAuthorization from "../components/Session/withAuthorization";
import SystemsNames from "../containers/SystemsNames";
import AddSystem from "../containers/AddSystem";
import AddSystemSpecialized from "../containers/AddSystemSpecialized";

const activeStyle = {
  color: "white",
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const LinksList = styled.ul`
  list-style: none;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  background: lightblue;
`;
const LinksListItem = styled.li`
  margin-right: 20px;
`;

const Content = styled.div`
  padding: 10px;
  flex: 1;
  height: 100%;
`;

class SystemsPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match, systemsData, isLoading, loadError } = this.props;
    return (
      <Wrapper>
        <LinksList>
          <LinksListItem key="sys-names">
            <NavLink to={`${match.url}/sys-names`} activeStyle={activeStyle}>
              Systems
            </NavLink>
          </LinksListItem>
          <LinksListItem key="add-sys">
            <NavLink to={`${match.url}/add-sys`} activeStyle={activeStyle}>
              Add system
            </NavLink>
          </LinksListItem>
          <LinksListItem key="add-sys-specialized">
            <NavLink
              to={`${match.url}/add-sys-specialized`}
              activeStyle={activeStyle}
            >
              Add specialized
            </NavLink>
          </LinksListItem>
        </LinksList>
        <Content>
          <Switch>
            <Route
              path={`${match.path}/sys-names`}
              render={() => (
                <SystemsNames {...{ isLoading, loadError, systemsData }} />
              )}
            />
            <Route
              path={`${match.path}/add-sys`}
              render={() => <AddSystem />}
            />
            <Route
              path={`${match.path}/add-sys-specialized`}
              render={() => <AddSystemSpecialized />}
            />
            <Redirect
              from={`${match.path}`}
              to={`${match.url}/sys-names`}
              exact={true}
              strict={true}
            />
          </Switch>
        </Content>
      </Wrapper>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withRouter(SystemsPage));
