import * as mockSysData from "../mockData/system";
import * as mockInvData from "../mockData/inverters";
import moment from "moment";

export function getData(sysInfo, date, timeOffset) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const data = {
        sysPower: [mockSysData.sysPower],
        sysEnergy: [mockSysData.sysEnergy],
        invPower: mockInvData.invPower,
        invDcVoltage: mockInvData.invDcVoltage,
        invAcVoltageMin: mockInvData.invAcVoltageMin,
        invAcVoltageMax: mockInvData.invAcVoltageMax,
        messageCount: 0,
      };
      resolve(data);
    }, 500);
  });
}

// naive collation
export function collateHourlyData(data, mins = 60, keepOffset = false) {
  if (!Number.isInteger(mins) || mins <= 0 || 60 % mins !== 0) {
    mins = 60;
  }
  const midRes = {};
  Object.keys(data).forEach((timeStamp) => {
    const currentMoment = moment(timeStamp);
    const currentHour = currentMoment
      .subtract(currentMoment.minutes() % mins, "minutes")
      .startOf("minute")
      .toISOString(keepOffset);
    const value = Number.parseFloat(data[timeStamp]);
    if (!Number.isNaN(value)) {
      if (!(currentHour in midRes)) {
        midRes[currentHour] = [];
      }
      midRes[currentHour].push(value);
    }
  });
  return midRes;
}

// sma style time checking
export function isActiveCb(val) {
  const numVal = parseFloat(val);
  return !Number.isNaN(numVal) && numVal !== 0;
}

export function getLatestStartTime(data) {
  const keys = Object.keys(data);
  if (keys.length === 0) {
    return null;
  }
  keys.sort();
  let res = isActiveCb(data[keys[0]]) ? keys[0] : null;
  for (let i = keys.length - 1; i > 0; --i) {
    if (!isActiveCb(data[keys[i - 1]]) && isActiveCb(data[keys[i]])) {
      res = keys[i - 1];
      break;
    }
  }
  return res;
}

export function getLatestStopTime(data) {
  const dataEntries = Object.entries(data).sort((log1, log2) =>
    log1[0] < log2[0] ? -1 : 1
  );
  for (let i = dataEntries.length - 1; i >= 0; --i) {
    if (isActiveCb(dataEntries[i][1])) {
      break;
    }
    dataEntries.pop();
  }
  const length = dataEntries.length;
  if (length === 0) {
    return null;
  }
  return dataEntries[length - 1][1] ? dataEntries[length - 1][0] : null;
}
