import React, { Component } from "react";

import AutoDashboardComponent from "../components/AutoDashboard";

function initRegionsSystems(regions, systemsData) {
  const systemsArr = Object.values(systemsData).filter(
    (sysData) => sysData.is_reference
  );
  const byRegion = {};
  systemsArr.forEach((sys) => {
    if (!byRegion[sys.region]) {
      byRegion[sys.region] = [];
    }
    byRegion[sys.region].push(sys);
  });
  const newRegions = Object.fromEntries(
    regions.map((region) => {
      return [
        region.id,
        {
          ...region,
          region: region.id,
          refs: byRegion[region.id] ? byRegion[region.id] : [],
        },
      ];
    })
  );
  return newRegions;
}

const INITIAL_STATE = {
  systems: null,
  regions: null,
  orgs: null,
  isDataReady: false,
};

class AutoDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    if (!this.props.systemsDataIsLoading) {
      this.processData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.systemsDataIsLoading !== this.props.systemsDataIsLoading) {
      this.processData();
    }
  }

  processData = () => {
    this.setState((state, props) => {
      const {
        regionsData,
        systemsData,
        systemsDataIsLoading,
        systemsDataLoadError,
        schedule,
        orgs,
      } = props;
      if (
        systemsDataIsLoading ||
        systemsDataLoadError ||
        !systemsData ||
        !regionsData ||
        !orgs
      ) {
        return;
      }
      const regions = initRegionsSystems(regionsData, systemsData);
      const systems = {
        ...Object.fromEntries(
          Object.entries(systemsData).filter(
            ([id, sys]) => !sys.is_reference && sys.inverter_make !== "mock"
          )
        ),
        ...regions,
      };
      return {
        systems,
        regions,
        schedule,
        orgs,
        isDataReady: true,
      };
    });
  };

  render() {
    if (this.props.systemsDataLoadError) {
      return (
        <div>
          Error Loading Systems Data:
          <br />
          {this.props.systemsDataLoadError.message}
        </div>
      );
    }
    if (this.props.systemsDataIsLoading || !this.state.isDataReady) {
      return <div>Loading Systems Data...</div>;
    }

    return (
      <AutoDashboardComponent
        systems={this.state.systems}
        regions={this.state.regions}
        schedule={this.state.schedule}
        orgs={this.state.orgs}
      />
    );
  }
}

export default AutoDashboard;
