import React, { Component } from "react";
import moment from "moment";

import LocalStorageKeys from "../localStorageKeys";
import DatePickerCombo from "../components/DatePickerCombo";
import { sendData } from "../services/sendData";
import { loadFromSheetsApi } from "../services/Spreadsheet";
import monthlyInsightModel from "../models/MonthlyInsightModel";

const operation = {
  idle: "idle",
  fetching: "fetching",
  pushing: "pushing",
  done: "done",
  error: "error",
};

class MigrateMonthly extends Component {
  constructor(props) {
    super(props);
    const now = moment();
    this.state = {
      currentOperation: operation.idle,
      selectedDate: moment([now.year(), now.month()]),
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  onDateSelect = (newDate) => {
    this.setState((state) => {
      // component is mounted and currently idle
      if (!this._isMounted || state.currentOperation !== operation.idle) {
        return;
      }
      return {
        // take only the needed parts
        selectedDate: moment([newDate.year(), newDate.month()]),
      };
    });
  };

  // async function that pushes to the internal db
  pushMonthlyData = async (monthlyData) => {
    console.log("starting push with", monthlyData);
    const urlCreate = "/api/insights/monthly";
    const urlUpdate = "/api/insight/monthly";
    const methodCreate = "post";
    const methodUpdate = "put";
    const existingRowStatusCode = 403;
    try {
      const token = localStorage.getItem(LocalStorageKeys.userToken);
      const results = await Promise.all(
        monthlyData.map((payload) =>
          sendData(payload, urlCreate, token, methodCreate)
        )
      );
      const updateIndices = [];
      const updatedRowResults = await Promise.all(
        results.reduce((promises, res, idx) => {
          if (res.status === existingRowStatusCode) {
            updateIndices.push([idx, promises.length]);
            const url = `${urlUpdate}/${res.data.id}`;
            promises.push(sendData(monthlyData[idx], url, token, methodUpdate));
          }
          return promises;
        }, [])
      );
      // insert into the results the new updated results
      updateIndices.forEach(
        ([originIdx, newIdx]) =>
          (results[originIdx] = updatedRowResults[newIdx])
      );
      const problemResults = results.filter((res) => !res.ok);
      console.log("update done with results", results);
      if (problemResults.length > 0) {
        console.error("error with results", problemResults);
        throw new Error("problem pushing to db");
      }
      this.setState(() => {
        if (!this._isMounted) {
          return;
        }
        return {
          currentOperation: operation.done,
        };
      });
    } catch (err) {
      console.error(err);
      this.setState(() => {
        if (!this._isMounted) {
          return;
        }
        return {
          currentOperation: operation.error,
        };
      });
    }
  };

  // async function that fetches data from gsheets-monthly, calls back the push function
  fetchMonthlyData = async (sysIds) => {
    try {
      console.log("monthly migration started, fetching data");
      const { selectedDate } = this.state;
      const monthlyData = monthlyInsightModel(
        await loadFromSheetsApi(sysIds, "monthly"),
        selectedDate
      );
      this.setState(
        () => {
          if (!this._isMounted) {
            return;
          }
          return {
            currentOperation: operation.pushing,
          };
        },
        async () => this.pushMonthlyData(monthlyData)
      );
    } catch (err) {
      console.error(err);
      this.setState(() => {
        if (!this._isMounted) {
          return;
        }
        return {
          currentOperation: operation.error,
        };
      });
    }
  };

  handleMonthlyMigration = (sysIds) => {
    // confirm user choice
    if (
      !window.confirm("You are about to start MONTHLY migration, are you sure?")
    ) {
      console.log("monthly migration aborted");
      return;
    }
    // lock state to fetching
    this.setState(
      (state) => {
        if (!this._isMounted || state.currentOperation !== operation.idle) {
          return;
        }
        return {
          currentOperation: operation.fetching,
        };
        // callback to start fetching
      },
      async () => this.fetchMonthlyData(sysIds)
    );
  };

  render() {
    const { isLoading, loadError, systemsData } = this.props;
    const systemsIds = Object.keys(systemsData || {}).filter((sysId) => {
      const sys = systemsData[sysId];
      return !sys.frozen && !sys.reference && !sys.is_reference;
    });
    const disabled = this.state.currentOperation !== operation.idle;

    if (loadError) {
      return <div>Error loading systems, please open console with `F12`</div>;
    }

    if (isLoading) {
      return <div>Loading systems...</div>;
    }

    return (
      <>
        <div>
          <h4>Monthly migration</h4>
          <DatePickerCombo
            selected={this.state.selectedDate}
            onChange={this.onDateSelect}
            disable={disabled}
            format="MM/YYYY"
            showMonthYearPicker={true}
            intervalIncrements="months"
          />
        </div>
        <div>
          <div>Status: {this.state.currentOperation}</div>
          <button
            onClick={() => this.handleMonthlyMigration(systemsIds)}
            disabled={disabled}
          >
            start migration
          </button>
        </div>
      </>
    );
  }
}

export default MigrateMonthly;
