import { sendData } from "./sendData";
import LocalStorageKeys from "../localStorageKeys";

// pushing sysstats to backend db
export async function pushSysstats(statsData, useToken = null) {
  const urlStats = "/api/stats/sys";
  const token = useToken
    ? useToken
    : localStorage.getItem(LocalStorageKeys.userToken);
  return sendData(statsData, urlStats, token).then(({ ok, data }) => {
    if (!ok) {
      statsData.id = data.id;
      return sendData(statsData, urlStats, token, "PUT");
    }
  });
}

// adds value 'v' to 'obj' under key 'k' if 'v' is not falsey
export function addFieldIfNotFalse(obj, k, v) {
  if (v) {
    obj[k] = v;
  }

  return obj;
}
