import * as React from "react";
import PropTypes from "prop-types";

const Select = (props) => {
  return (
    <td key={props.name}>
      <select
        name={props.name}
        onChange={(ev) => props.handleChange(props.name, ev.target.value)}
        value={props.value}
      >
        <option key="empty" value="">
          {" "}
        </option>
        {Array(5)
          .fill(0)
          .map((e, i) => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        <option key={-1} value={-1}>
          -1
        </option>
      </select>
    </td>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

export default Select;
