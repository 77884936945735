import React from "react";
import styled from "styled-components";
import { Route, NavLink, withRouter, Redirect, Switch } from "react-router-dom";

import MessagesManual from "../containers/MessagesManual";
import MessagesAuto from "../containers/MessagesAuto";
import MessagesAdmin from "../containers/MessagesAdmin";
import MessagesBackend from "../containers/MessagesBackend";
import withAuthorization from "../components/Session/withAuthorization";

const activeStyle = {
  color: "white",
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const LinksList = styled.ul`
  list-style: none;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  background: lightblue;
`;
const LinksListItem = styled.li`
  margin-right: 20px;
`;

const Content = styled.div`
  padding: 10px;
  flex: 1;
  height: 100%;
`;

class MessagesPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  matchOrgsToUsers = (orgsData, usersData) => {
    if (!orgsData || !usersData) {
      return null;
    }
    return Object.entries(usersData).reduce(
      (sysMatch, [phone, user]) => {
        // filter out users that are not meant to get messages
        if (!["viewer", "admin", "demo"].includes(user.role)) {
          const orgId = user.organization;

          orgsData[orgId].systemIds.forEach((sysId) => {
            if (!sysMatch[sysId]) {
              sysMatch[sysId] = { phones: [], label: user.label[0] };
            }
            if (!sysMatch[sysId].phones.includes(phone)) {
              sysMatch[sysId].phones.push(phone);
            }
            if (sysMatch[sysId].label !== user.label[0]) {
              sysMatch[sysId].label = "";
            }
          });
        }
        return sysMatch;
      },
      { "": { label: "", phones: [] } }
    );
  };

  render() {
    const { match, systemsData, loadError, orgsData, usersData } = this.props;

    // remove references, filter out frozen systems, divide into test, non-test and frozen systems
    const [testSystems, noRefSystems, frozenSystems] = !systemsData
      ? [null, null, null]
      : Object.keys(systemsData).reduce(
          ([test, noRef, frozenSys], id) => {
            const sysData = systemsData[id];
            if (!sysData.is_reference) {
              if (sysData.frozen) {
                frozenSys[id] = sysData;
              } else if (sysData.region !== "il.test") {
                noRef[id] = sysData;
              } else {
                test[id] = sysData;
              }
            }
            return [test, noRef, frozenSys];
          },
          [{}, {}, {}]
        );

    const sysMatch = this.matchOrgsToUsers(orgsData, usersData);

    return (
      <Wrapper>
        <LinksList>
          <LinksListItem key="manual">
            <NavLink to={`${match.url}/manual`} activeStyle={activeStyle}>
              Manual
            </NavLink>
          </LinksListItem>
          <LinksListItem>
            <NavLink to={`${match.url}/auto`} activeStyle={activeStyle}>
              Mlog
            </NavLink>
          </LinksListItem>
          <LinksListItem>
            <NavLink to={`${match.url}/admin`} activeStyle={activeStyle}>
              Administrative
            </NavLink>
          </LinksListItem>
          <LinksListItem>
            <NavLink to={`${match.url}/new-auto`} activeStyle={activeStyle}>
              DBLog
            </NavLink>
          </LinksListItem>
        </LinksList>
        <Content>
          <Switch>
            <Route
              path={`${match.path}/manual`}
              render={() => (
                <MessagesManual
                  noRefSystems={noRefSystems}
                  testSystems={testSystems}
                  frozenSystems={frozenSystems}
                  sysMatch={sysMatch}
                />
              )}
            />
            <Route
              path={`${match.path}/auto`}
              render={() => (
                <MessagesAuto
                  noRefSystems={noRefSystems}
                  testSystems={testSystems}
                  orgsData={orgsData}
                  usersData={usersData}
                  loadError={loadError}
                />
              )}
            />
            <Route
              path={`${match.path}/new-auto`}
              render={() => (
                <MessagesBackend
                  noRefSystems={noRefSystems}
                  testSystems={testSystems}
                  orgsData={orgsData}
                  usersData={usersData}
                  loadError={loadError}
                />
              )}
            />
            <Route
              path={`${match.path}/admin`}
              render={() => (
                <MessagesAdmin
                  noRefSystems={noRefSystems}
                  testSystems={testSystems}
                  frozenSystems={frozenSystems}
                  usersData={usersData}
                  orgsData={orgsData}
                  sysMatch={sysMatch}
                />
              )}
            />
            <Redirect
              from={`${match.path}`}
              to={`${match.url}/manual`}
              exact={true}
              strict={true}
            />
          </Switch>
        </Content>
      </Wrapper>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withRouter(MessagesPage));
