import React from "react";
import PropTypes from "prop-types";
import COLORS from "./Colors";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const dateFormatter = (time) => moment(time).format("HH:mm");

class OneChart extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    data: PropTypes.array,
    sets: PropTypes.array,
    units: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    if (this.props.data.length === 0) {
      return <div>[No Data to plot]</div>;
    }

    // for extra large label names the chart may disappear, take care of that
    const labelsCombinedLength = this.props.data
      .map(({ name }) => name)
      .join("").length;

    return (
      <LineChart
        width={650}
        height={labelsCombinedLength > 400 ? 600 : 400}
        margin={{ top: 5, right: 30, left: 30, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="time"
          type="number"
          allowDuplicatedCategory={false}
          domain={["dataMin", "dataMax"]}
          tickFormatter={dateFormatter}
        />
        <YAxis
          type="number"
          dataKey="value"
          domain={[(dataMin) => Math.max(0, dataMin - 2), "dataMax"]}
          tickFormatter={(val) => val.toFixed(1)}
          unit={this.props.units}
        />
        <Tooltip labelFormatter={dateFormatter} />
        <Legend />
        {this.props.data.map((s, idx) => (
          <Line
            dataKey="value"
            data={s.data}
            name={s.name}
            key={s.name}
            stroke={COLORS[idx % COLORS.length]}
            strokeWidth={2}
            dot={false}
          />
        ))}
      </LineChart>
    );
  }
}

export default OneChart;
