import React from "react";

import { auth } from "../../firebase";

import LocalStorageKeys from "../../localStorageKeys";

const SignOutButton = () => {
  const signOut = () => {
    Object.values(LocalStorageKeys).forEach((storageKey) => {
      localStorage.removeItem(storageKey);
    });
    auth.doSignOut();
  };

  return (
    <button type="button" onClick={signOut}>
      Sign Out
    </button>
  );
};

export default SignOutButton;
