import queryString from "query-string";
import fetchJsonp from "fetch-jsonp";
import moment from "moment";
import * as bPromise from "bluebird";

// API docs https://www.solaredge.com/sites/default/files/se_monitoring_api.pdf

const API_URL = "https://monitoringapi.solaredge.com";

const API_TIMEOUT = 1000 * 10;

const JSONP_OPTIONS = {
  mode: "cors",
  timeout: API_TIMEOUT,
};

export function getData(systemInfo, date, timeOffset) {
  const { siteId, apiKey, invertersToQuery } = systemInfo.sources;
  const fDate = date.format("YYYY-MM-DD");
  let params;

  // sysPower
  params = {
    startTime: `${fDate} 00:00:00`,
    endTime: `${fDate} 23:59:59`,
    api_key: apiKey,
  };
  const sysPowerUrl =
    API_URL +
    `/site/${siteId}/power.json?${queryString.stringify({ ...params })}`;

  // sysEnergy
  params = {
    startDate: fDate,
    endDate: fDate,
    api_key: apiKey,
    timeUnit: "QUARTER_OF_AN_HOUR",
  };
  const sysEnergyUrl =
    API_URL +
    `/site/${siteId}/energy.json?${queryString.stringify({ ...params })}`;

  // inverters
  params = {
    api_key: apiKey,
  };
  const equipmentUrl =
    API_URL +
    `/equipment/${siteId}/list.json?${queryString.stringify({ ...params })}`;

  // TODO: to be used later when we have alert access privileges
  // const siteDetails =
  //   `${API_URL}/site/${siteId}/details.json?${queryString.stringify({ ...params })}`;

  const urls = [sysPowerUrl, sysEnergyUrl, equipmentUrl];

  let sysPower;
  let sysEnergy;

  // if no need for system power/energy, resolve promises immediately
  const promiseArray =
    !Array.isArray(invertersToQuery) || !invertersToQuery.length
      ? urls.map((url) => fetchJsonp(url, JSONP_OPTIONS))
      : [
          Promise.resolve({ json: () => null }),
          Promise.resolve({ json: () => null }),
          Promise.resolve({ json: () => null }),
        ];

  return Promise.allSettled(promiseArray)
    .then((results) => {
      return Promise.all(
        results.map((res, idx) =>
          res.status === "fulfilled"
            ? Promise.resolve(res.value)
            : fetchJsonp(urls[idx], JSONP_OPTIONS)
        )
      );
    })
    .then((responses) => Promise.all(responses.map((res) => res.json())))
    .then((results) => {
      // sysPower = [normalizeSysPower(results[0])];
      // sysEnergy = [normalizeSysEnergy(results[1])];
      // const inverters = extractInverters(results[2]); // .slice(0, 3);
      sysPower = results[0] ? [normalizeSysPower(results[0])] : null;
      sysEnergy = results[1] ? [normalizeSysEnergy(results[1])] : null;
      const inverters = results[2]
        ? extractInverters(results[2])
        : invertersToQuery;

      const invParams = {
        startTime: `${fDate} 00:00:00`,
        endTime: `${fDate} 23:59:59`,
        api_key: apiKey,
      };

      const invUrls = inverters.map(
        (invSn) =>
          API_URL +
          `/equipment/${siteId}/${invSn}/data.json?${queryString.stringify({
            ...invParams,
          })}`
      );
      // return promiseSerial(invUrls.map(url => fetchJsonp(url, {mode: 'cors'})))
      // return promiseSerial(invUrls.map(url => delayedFetch(url, 500)))
      return bPromise.map(invUrls, (url) => fetchJsonp(url, JSONP_OPTIONS), {
        concurrency: 3,
      });
    })
    .then((responses) => {
      return Promise.all(responses.map((res) => res.json()));
    })
    .then((results) => {
      const invData = normalizeInvData(results, systemInfo.inverterPowerFactor);
      if (!sysPower || !sysEnergy) {
        console.log(
          `'${systemInfo.id}': solar-edge system building system data from inv data`
        );
        const invSysPowerEnergy = invToSysPowerEnergy(
          invData,
          systemInfo.inverterPowerFactor
        );
        sysPower = sysPower || invSysPowerEnergy.sysPower;
        sysEnergy = sysEnergy || invSysPowerEnergy.sysEnergy;
      }
      return Promise.resolve({
        sysPower,
        sysEnergy,
        ...invData,
        messageCount: null,
      });
    });
}

// function delayedFetch(url, delay = 500) {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       fetchJsonp(url, { mode: "cors", timeout: API_TIMEOUT }).then(res => resolve(res));
//     }, delay);
//   });
// }

// sort logs into buckets of interval minutes
export function logsToTimestampBuckets(datapoints, intervalMinutes) {
  const tsMoments = Object.keys(datapoints).sort();
  const firstTs = moment(tsMoments[0]);
  let currentMoment = moment(firstTs).startOf("hour");
  let nextMoment = moment(currentMoment).add(intervalMinutes, "minutes");
  // find first bucket
  while (!firstTs.isBetween(currentMoment, nextMoment, undefined, "[)")) {
    currentMoment = moment(nextMoment);
    nextMoment = moment(currentMoment).add(intervalMinutes, "minutes");
  }
  return tsMoments.reduce(
    (buckets, ts) => {
      const tsMom = moment(ts);
      // current log belongs to current bucket
      if (tsMom.isBetween(currentMoment, nextMoment, undefined, "[)")) {
        buckets[currentMoment.format()].push(datapoints[ts]);
        // advance to next bucket
      } else {
        // find next non-empty bucket
        while (!tsMom.isBetween(currentMoment, nextMoment, undefined, "[)")) {
          currentMoment = moment(nextMoment);
          nextMoment = moment(currentMoment).add(intervalMinutes, "minutes");
        }
        buckets[currentMoment.format()] = [datapoints[ts]];
      }
      return buckets;
    },
    { [currentMoment.format()]: [] }
  );
}

// creates system power and energy from inverter values
export function invToSysPowerEnergy(invData, invPowerFactor) {
  const logIntervalMinutes = 15;
  const { invPower, invEnergy } = invData;

  // first seperate into timestamped buckets
  const sysPower = invPower
    .map((singleInvPower) =>
      logsToTimestampBuckets(singleInvPower, logIntervalMinutes)
    )
    .reduce(
      ([sysPow], logsBuckets) => {
        // find mean of every timestamp in every bucket and add to the overall sum
        Object.entries(logsBuckets).forEach(([ts, logsArr]) => {
          if (!sysPow.hasOwnProperty(ts)) {
            sysPow[ts] = 0;
          }
          sysPow[ts] += calcArrayMean(logsArr) * invPowerFactor;
        });
        return [sysPow];
      },
      [{}]
    );

  // like power, instead of mean, just sum
  const sysEnergy = invEnergy
    .map((singleInvEnergy) =>
      logsToTimestampBuckets(singleInvEnergy, logIntervalMinutes)
    )
    .reduce(
      ([sysEn], logsBuckets) => {
        Object.entries(logsBuckets).forEach(([ts, logsArr]) => {
          if (!sysEn.hasOwnProperty(ts)) {
            sysEn[ts] = 0;
          }
          sysEn[ts] += logsArr.reduce((sum, log) => sum + +log, 0);
        });
        return [sysEn];
      },
      [{}]
    );

  return {
    sysPower,
    sysEnergy,
  };
}

// takes inverter power values and calculates inverter energy values
// IMPORTENT: this function is heuristic in nature, and is NOT ACCURATE ENOUGH
// TODO: fix accuracy
export function invPowerToInvEnergy(singleInvPow, invPowerFactor) {
  const secondsInHour = 3600;
  const invEnergy = {};
  const timestampsAsMoments = Object.keys(singleInvPow)
    .sort()
    .map((ts) => ({ asString: ts, asMoment: moment(ts) }));
  let prevMmnt = timestampsAsMoments[0].asMoment;
  for (const { asString, asMoment } of timestampsAsMoments) {
    const powerLog = +(singleInvPow[asString] || 0);
    const logDuration = +asMoment.diff(prevMmnt, "seconds");
    invEnergy[asString] =
      +invPowerFactor * (powerLog * (logDuration / secondsInHour));
    prevMmnt = asMoment;
  }
  return invEnergy;
}

// calculates the mean of a numeric array, in case not an array, or empty, return NaN
export function calcArrayMean(numericArr) {
  if (!Array.isArray(numericArr) || numericArr.length === 0) {
    return NaN;
  }
  return numericArr.reduce((sum, curr) => sum + +curr, 0) / numericArr.length;
}

export function normalizeSysPower(rawData) {
  // console.log('normalizeSysPower', rawData);
  const res = {};
  rawData.power.values.forEach((entry) => {
    //2017-10-20T13:00:00+00:00
    const date = moment(entry.date).format();
    res[date] = entry.value === null ? entry.value : entry.value * 0.001; // convert to kW
  });
  return res;
}

export function normalizeSysEnergy(rawData) {
  // console.log('normalizeSysEnergy', rawData);
  const res = {};
  rawData.energy.values.forEach((entry) => {
    //2017-10-20T13:00:00+00:00
    const date = moment(entry.date).format();
    res[date] = entry.value === null ? entry.value : entry.value * 0.001; // convert to kWh
  });
  return res;
}

export function extractInverters(rawData) {
  // console.log('extractInverters', rawData);
  const res = rawData.reporters.list.map((entry) => entry.serialNumber);
  return res;
}

/**
 * run promisses serially
 * source: https://decembersoft.com/posts/promises-in-serial-with-array-reduce/
 * @TODO run in batches of 3
 */
export function promiseSerial(tasks) {
  return tasks.reduce((promiseChain, currentTask) => {
    return promiseChain.then((chainResults) =>
      currentTask.then((currentResult) => [...chainResults, currentResult])
    );
  }, Promise.resolve([]));
}

export function normalizeInvData(rawData, invPowerFactor) {
  // console.log('normalizeInvData', rawData);
  const invPower = [];
  const invEnergy = [];
  const invDcVoltage = [];
  const invAcVoltageMax = [];
  const invAcVoltageMin = [];

  rawData.forEach((oneInvData) => {
    const oneInvRes = normalizeOneInvData(oneInvData, invPowerFactor);
    pushIfNonEmpty(invPower, oneInvRes.invPower);
    pushIfNonEmpty(invEnergy, oneInvRes.invEnergy);
    pushIfNonEmpty(invDcVoltage, oneInvRes.invDcVoltage);
    pushIfNonEmpty(invAcVoltageMax, oneInvRes.invAcVoltageMax);
    pushIfNonEmpty(invAcVoltageMin, oneInvRes.invAcVoltageMin);

    // invPower.push(oneInvRes.invPower);
    // invDcVoltage.push(oneInvRes.invDcVoltage);
    // invAcVoltageMax.push(oneInvRes.invAcVoltageMax);
    // invAcVoltageMin.push(oneInvRes.invAcVoltageMin);
  });

  return {
    invPower,
    invEnergy,
    invDcVoltage,
    invAcVoltageMax,
    invAcVoltageMin,
  };
}

export function pushIfNonEmpty(arr, obj) {
  if (Object.keys(obj).length > 0) {
    arr.push(obj);
  } else {
    arr.push({});
  }
}

export function getPhaseVoltages(entry) {
  const allKeys = ["L1Data", "L2Data", "L3Data"];
  const availKeys = allKeys.filter((key) => key in entry);
  const values = availKeys.map((key) => entry[key].acVoltage);
  return values;
}

export function normalizeOneInvData(rawData, invPowerFactor = 1) {
  // console.log('normalizeOneInvData', rawData);
  const invPower = {};
  const invDcVoltage = {};
  const invAcVoltageMax = {};
  const invAcVoltageMin = {};
  let invEnergy = {};

  rawData.data.telemetries.forEach((entry) => {
    const date = moment(entry.date).format();
    invPower[date] =
      entry.totalActivePower === null ? null : entry.totalActivePower; // * 0.001; // convert to kW
    invDcVoltage[date] = entry.dcVoltage;
    const voltages = getPhaseVoltages(entry);
    invAcVoltageMax[date] = Math.max(...voltages);
    invAcVoltageMin[date] = Math.min(...voltages);
  });
  // only calculate invEnergy if invPower is not empty
  if (Object.keys(invPower).length) {
    invEnergy = { ...invPowerToInvEnergy(invPower, invPowerFactor) };
  }

  return {
    invPower,
    invDcVoltage,
    invAcVoltageMax,
    invAcVoltageMin,
    invEnergy,
  };
}

// solar-edge style collation
export function collateHourlyData(data, mins = 60, keepOffset = false) {
  if (!Number.isInteger(mins) || mins <= 0 || 60 % mins !== 0) {
    mins = 60;
  }
  const midRes = {};
  Object.keys(data).forEach((timeStamp) => {
    const currentMoment = moment(timeStamp);
    currentMoment.add(mins, "minutes");
    const currentHour = currentMoment
      .subtract(currentMoment.minutes() % mins, "minutes")
      .startOf("minute")
      .toISOString(keepOffset);
    const value = Number.parseFloat(data[timeStamp]);
    if (!Number.isNaN(value)) {
      if (!(currentHour in midRes)) {
        midRes[currentHour] = [];
      }
      midRes[currentHour].push(value);
    }
  });
  return midRes;
}

// solaredge style time checking
export function isActiveCb(val) {
  const numVal = parseFloat(val);
  return !Number.isNaN(numVal) && numVal !== 0;
}

export function getLatestStartTime(data) {
  const keys = Object.keys(data);
  if (keys.length === 0) {
    return null;
  }
  keys.sort();
  let res = isActiveCb(data[keys[0]]) ? keys[0] : null;
  for (let i = keys.length - 1; i > 0; --i) {
    if (!isActiveCb(data[keys[i - 1]]) && isActiveCb(data[keys[i]])) {
      res = keys[i];
      break;
    }
  }
  return res;
}

export function getLatestStopTime(data) {
  const dataEntries = Object.entries(data).sort((log1, log2) =>
    log1[0] < log2[0] ? -1 : 1
  );
  for (let i = dataEntries.length - 1; i >= 0; --i) {
    if (isActiveCb(dataEntries[i][1])) {
      break;
    }
    dataEntries.pop();
  }
  const length = dataEntries.length;
  if (length === 0) {
    return null;
  }
  return dataEntries[length - 1][1] ? dataEntries[length - 1][0] : null;
}
