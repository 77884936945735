import moment from "moment";
import * as SunCalc from "suncalc";
import * as Stats from "./stats";

const HOURS_PER_DAY = 24;

const HOURS_DOWNTIME_COMM_DELAY = {
  beforeSunset: 3.5,
  afterSunset: 1,
};

const MINUTES_FOR_COMM_NIGHT_DATA = {
  beforeDawn: 120,
  afterSunset: 120,
};

const RISO_MINUTE_CALC_THRESHOLD = 45;
const MCM_MINUTE_CALC_THRESHOLD = 45;

const DAILY_COEFS = {
  invSizeCoefficient: 1.6,
  invDCVoltage: 1.1,
  fctDCVoltage: 1.045,
  effectiveCoef: 0.002,
  invAmountDCVSpike: 0.2, // percentage of inverters to consider blackout
  invAmountACDrop: 0.2,
  invACVPercentageDrop: 0.95,
  effectiveFctCoef: 0.01,
  fct1To4CutoffPoint: 0.03,
  equalityDeltaFct: 0.005,
};

// gets stats object and regex pattern, and searches for the pattern in the stats, returns an array with the values
const buildArrayFromStats = (stats, pattern) => {
  let maxIdx = 0;
  return Object.entries(stats)
    .reduce((result, [key, value]) => {
      const match = RegExp(pattern).exec(key);
      if (match) {
        const matchIdx = Number(match[1]);
        maxIdx = maxIdx < matchIdx ? matchIdx : maxIdx;
        result.push([matchIdx, value]);
      }
      return result;
    }, [])
    .reduce((acc, [idx, value]) => {
      acc[idx] = value;
      return acc;
    }, Array(maxIdx + 1).fill(""));
};

// gets an array of inverter sizes, returns the sum of them
function calcSysACSize(invSizes) {
  if (!Array.isArray(invSizes) || invSizes.length === 0) {
    return null;
  }
  return invSizes.reduce((sum, size) => sum + Number(size), 0);
}

/**
 * Calculate comm result for reference systems
 * Not accurate enough for real systems!
 * @param {object} sysPower
 * @return interface {value: number, reason: string}
 */
export function calcRefComm(
  sysPower,
  invPower,
  sysDayEnergy,
  lat,
  lon,
  invSizes = null,
  sysACSize = null,
  sysFactor = 1,
  invFactor = 1
) {
  try {
    let isSystem = !!sysPower; // system or inverter level
    let effectiveFactor = isSystem ? sysFactor : invFactor;
    let effectivePower = sysPower || invPower[0] || {};
    // if no data return 3
    const nonEmpty = Stats.filterNonEmpty(Object.values(effectivePower || {}));
    if (nonEmpty.length === 0) {
      return {
        value: 3,
        reason: "No non-empty data",
      };
    }

    const isoDateTime = Object.keys(effectivePower)[0];
    // get date in UTC TZ for SunCalc
    const date = moment.utc(isoDateTime.split("+")[0]);
    const sunTimes = SunCalc.getTimes(date, lat, lon);
    const now = moment();

    // get rid of times prior to dawn and after sunset
    const relevantHoursPower = Object.fromEntries(
      Object.entries(effectivePower).filter(
        ([hour, power]) =>
          +power ||
          moment(hour).isBetween(
            sunTimes.dawn,
            sunTimes.sunset,
            undefined,
            "[]"
          )
      )
    );

    const negativePower = Object.values(effectivePower).find(
      (power) => power < 0
    );

    // if sizes are defined and system level sum them, otherwise get the first
    // const effectiveInvSize = invSizes && Number(isSystem ?
    //   calcSysACSize(invSizes) :
    //   invSizes[0]
    // );

    // if the calculation is for system level, check for system size if it does not exist,
    // try summing inverters, otherwise, take first inverter size
    const effectiveInvSize = isSystem
      ? Number(sysACSize || calcSysACSize(invSizes)) || null
      : Number(invSizes[0]);

    const tooMuchPower =
      effectiveInvSize &&
      Object.values(relevantHoursPower).find(
        (pow) =>
          effectiveFactor * Number(pow) >
          DAILY_COEFS.invSizeCoefficient * effectiveInvSize
      );

    if (negativePower || tooMuchPower) {
      return {
        value: 4,
        reason: negativePower
          ? `Negative power value of ${negativePower}`
          : `inv power exceeds size of ${invSizes}, ${tooMuchPower} was found`,
      };
    }

    if (
      Number.isFinite(sysDayEnergy) &&
      (sysDayEnergy < 0 || sysDayEnergy > sysACSize * HOURS_PER_DAY)
    ) {
      return {
        value: 4,
        reason: "Negative or too high daily system energy value",
      };
    }

    // whether or not there is data in the middle of the night
    const nightDataTime = Object.keys(relevantHoursPower).find(
      (hour) =>
        !moment(hour).isBetween(
          moment(sunTimes.dawn).subtract(
            MINUTES_FOR_COMM_NIGHT_DATA.beforeDawn,
            "minutes"
          ),
          moment(sunTimes.sunset).add(
            MINUTES_FOR_COMM_NIGHT_DATA.afterSunset,
            "minutes"
          ),
          undefined,
          "[]"
        )
    );
    // check for data in the middle of the night and return comm 4 if so
    if (nightDataTime) {
      return {
        value: 4,
        reason: `Data from night at hour ${nightDataTime}`,
      };
    }

    // added so when the entire day is empty of true data, but contains empty data, would consider as comm 3
    const nonEmptyRelevantHoursLength = Object.entries(
      relevantHoursPower
    ).filter(
      ([timestamp, datum]) => !Number.isNaN(Number.parseFloat(datum))
    ).length;
    if (nonEmptyRelevantHoursLength === 0) {
      return {
        value: 3,
        reason: "Data out of working hours",
      };
    }

    const lastStopTime = Stats.getLatestDataTime([relevantHoursPower]);

    const relevantDiffTime =
      moment(sunTimes.sunset).diff(now, "hours") < 0 ? sunTimes.sunset : now;

    // if stop time > 2 hours before sunset return 2
    const stopDiff = moment(relevantDiffTime).diff(lastStopTime, "hours");
    const delayTime = now.isBefore(sunTimes.sunset)
      ? HOURS_DOWNTIME_COMM_DELAY.beforeSunset
      : now.isAfter(moment(sunTimes.sunset).add(2, "h"))
      ? HOURS_DOWNTIME_COMM_DELAY.afterSunset
      : (HOURS_DOWNTIME_COMM_DELAY.beforeSunset +
          HOURS_DOWNTIME_COMM_DELAY.afterSunset) /
        2;
    if (stopDiff > delayTime) {
      return {
        value: 2,
        reason: `Stop time ${stopDiff} hours before sunset`,
      };
    }
    // calc firstStartTime
    const firstStartTime = Stats.getEarliestDataTime([relevantHoursPower]);
    // todo: firstStartTime lastStartTime not consistent
    // TODO: move isActiveCb function to the implementation of every logger arch-type
    const lastStartTime = Stats.getLatestStartTime(
      relevantHoursPower,
      /* isActiveCb */ (val) => val !== null && val !== ""
    );
    const isContinuous =
      moment(firstStartTime).diff(lastStartTime, "minutes") >= 0;
    if (!isContinuous) {
      return {
        value: 1,
        reason: `Data not continuos, first start ${firstStartTime} last start ${lastStartTime}`,
      };
    }
    // if start time > 90 minutes after dawn
    const startDiff = moment(lastStartTime).diff(sunTimes.dawn, "minutes");
    if (startDiff >= 90) {
      return {
        value: 1,
        reason: `Start time ${startDiff} minutes after dawn`,
      };
    }
    return {
      value: 0,
      reason: "All is well",
    };
  } catch (err) {
    console.error(err);
    return {
      value: -1,
      reason: "Error",
    };
  }
}

// fct 2
function checkFct2(powerFactor, size, powerObj, dcVoltages, dcEstimates) {
  if (!powerObj || typeof powerObj !== "object") {
    return false;
  }
  const checkVoltage =
    Array.isArray(dcVoltages) &&
    Array.isArray(dcEstimates) &&
    dcVoltages.length &&
    dcVoltages.length === dcEstimates.length &&
    dcVoltages.every((e) => e) &&
    dcEstimates.every((e) => e);

  const [timestamps, powerArray, overVoltageArray] = Object.entries(
    powerObj
  ).reduce(
    ([timestamps, powerArray, overVoltageArray], [ts, value]) => {
      const normalizedValue = Number.parseFloat(value) * powerFactor;
      if (Number.isFinite(normalizedValue)) {
        let overVoltage = true;
        if (checkVoltage && dcVoltages.some((o) => o.hasOwnProperty(ts))) {
          overVoltage = dcVoltages.some((o, idx) => {
            try {
              const v = Number.parseFloat(o[ts]);
              if (
                Number.isFinite(v) &&
                v > dcEstimates[idx] * DAILY_COEFS.fctDCVoltage
              ) {
                return true;
              }
            } catch {
              console.warn(`error with fct2 calc`);
            }
            return false;
          });
        }
        overVoltageArray.push(overVoltage);
        powerArray.push(normalizedValue);
      }
      timestamps.push(moment(ts));
      return [timestamps, powerArray, overVoltageArray];
    },
    [[], [], []]
  );

  const maxValue = Math.max(...powerArray);

  if (
    !Number.isFinite(maxValue) ||
    maxValue > size * (1 - DAILY_COEFS.effectiveFctCoef) ||
    maxValue === 0
  ) {
    return false;
  }

  const equalValues = powerArray.filter((value, idx) => {
    return (
      Math.abs(maxValue - value) <= size * DAILY_COEFS.equalityDeltaFct &&
      overVoltageArray[idx]
    );
  });

  if (equalValues.length <= 1 || timestamps.length < 2) {
    return false;
  }

  timestamps.sort((m1, m2) => {
    return m1.isBefore(m2) ? -1 : 1;
  });

  let timeInterval = Infinity;
  for (let i = 1; i < timestamps.length && i < 10; ++i) {
    timeInterval = Math.min(
      timeInterval,
      timestamps[i].diff(timestamps[i - 1], "minutes")
    );
  }

  if (!Number.isFinite(timeInterval)) {
    return false;
  }

  let thresholdCount;
  // less than 5 minutes case
  if (timeInterval < 10) {
    thresholdCount = 13;
    // less than 15 minutes case
  } else if (timeInterval < 15) {
    thresholdCount = 9;
    // less than 30 minutes case
  } else if (timeInterval < 30) {
    thresholdCount = 7;
    // less than an hour case
  } else if (timeInterval < 60) {
    thresholdCount = 4;
  } else {
    thresholdCount = 3;
  }

  return equalValues.length >= thresholdCount;
}

// calculates an fct component, for inverter or system level
function calcFctComponent(
  powerFactor,
  sizes,
  maxima,
  powersArray,
  dcVoltages = null,
  dcEstimates = null
) {
  let fct = 0;
  sizes
    .reduce((acc, size, idx, currentArr) => {
      const maximum = maxima[idx];
      if (Number.isFinite(size) && Number.isFinite(maximum)) {
        let chanDcVoltages = null,
          chanDcEstimates = null;

        if (Array.isArray(dcVoltages) && Array.isArray(dcEstimates)) {
          let lastIdx = idx + 1;
          while (lastIdx < currentArr.length) {
            if (Number.isFinite(currentArr[lastIdx])) {
              break;
            }
            ++lastIdx;
          }
          chanDcEstimates = dcEstimates.slice(idx, lastIdx);
          chanDcVoltages = dcVoltages.slice(idx, lastIdx);
        }

        acc.push({
          max: maximum * powerFactor,
          size: size,
          powers: powersArray[idx],
          chanDcVoltages,
          chanDcEstimates,
        });
      }
      return acc;
    }, [])
    .forEach(({ max, size, powers, chanDcVoltages, chanDcEstimates }) => {
      const fct1LowerBound = size * (1 - DAILY_COEFS.effectiveFctCoef) < max;
      const fct1UpperBound = size * (1 + DAILY_COEFS.fct1To4CutoffPoint) > max;

      const fct4LowerBound = size * (1 + DAILY_COEFS.fct1To4CutoffPoint) <= max;
      const fct4UpperBound = size * DAILY_COEFS.invSizeCoefficient > max;

      if (fct1LowerBound && fct1UpperBound) {
        fct = Math.max(fct, 1);
        // fct 4 is being assigned as 1.5 intentionally, so max function would escalate to fct 2
      } else if (fct4LowerBound && fct4UpperBound) {
        fct = Math.max(fct, 1.5);
      }
      if (
        checkFct2(powerFactor, size, powers, chanDcVoltages, chanDcEstimates)
      ) {
        fct = Math.max(fct, 2);
      }
    });
  return fct;
}

// calculates fct
export function calcFct(stats, sys, rawData) {
  try {
    const {
      inverterSizes,
      inverterPowerFactor,
      systemPowerFactor,
      sysACSize,
      inverterDCVEstimate: dcEstimates,
    } = sys;
    let fctArray = [0];

    let powerFactor = null;
    let sizes = null;
    let maxima = null;
    let dcVoltages = null;

    // only in case inverter sizes are defined and are a non-empty array
    if (Array.isArray(inverterSizes) && inverterSizes.length) {
      maxima = [...buildArrayFromStats(stats, /^invRawMaxDailyPower(\d\d)$/)];
      // fct 3 check in case there are both inverter sizes, and power factor
      if (
        inverterPowerFactor &&
        maxima.every((e) => e || e === 0 || e === "")
      ) {
        powerFactor = Number(inverterPowerFactor);
        sizes = [...inverterSizes];

        if (Array.isArray(rawData.invDcVoltage)) {
          dcVoltages = rawData.invDcVoltage;
        }

        fctArray.push(
          calcFctComponent(
            powerFactor,
            sizes,
            maxima,
            rawData.invPower,
            dcVoltages,
            dcEstimates
          )
        );
      }
    }

    // for a case where system level is defined, should run along with the inverter level calculation
    if (
      (Number.isFinite(sysACSize) || Array.isArray(inverterSizes)) &&
      systemPowerFactor
    ) {
      powerFactor = Number(systemPowerFactor);
      sizes = [Number(sysACSize) || calcSysACSize(inverterSizes)];
      maxima = [stats.sysMaxPower];
      fctArray.push(
        calcFctComponent(powerFactor, sizes, maxima, rawData.sysPower)
      );
    }

    const result = Math.max(...fctArray);
    return result === 1.5 ? 4 : result;
  } catch (err) {
    console.error(err);
    return err;
  }
}

export function getWebboxField(messageCount) {
  const numberCast = Number(messageCount);
  if (numberCast <= 0 || !isFinite(numberCast)) {
    return "";
  }
  const single = numberCast === 1;
  return `There ${single ? "is" : "are"} ${numberCast} system message${
    single ? "" : "s"
  }`;
}

// if power exists, and expected is over the defined coefficient, riso 3
// only an invreter whose wattage is zero and defined
// check from median
function calcRisoOld(stats, data, sys) {
  try {
    let riso = 0;
    const { inverterSizes, inverterDCVEstimate } = sys;
    const { invAcVoltageMin: acVoltMin } = data;
    const invAcVolt =
      Array.isArray(acVoltMin) &&
      acVoltMin.map((inv) => {
        // return !inv || Object.keys(inv).length === 0 ? null : {...inv};
        if (!inv || Object.keys(inv).length === 0) {
          return null;
        }
        const min = Math.min(
          ...Object.values(inv).filter((val) => val !== "" && val !== null)
        );
        return Number.isFinite(min) ? min : null;
      });
    if (
      !Array.isArray(inverterSizes) ||
      !Array.isArray(inverterDCVEstimate) ||
      !invAcVolt
    ) {
      return riso;
    }

    const invDCVMedians = [
      ...buildArrayFromStats(stats, /^invMedianDcVoltage(\d\d)$/),
    ];

    buildArrayFromStats(stats, /^invMaxDailyPower(\d\d)$/)
      .reduce((acc, val, idx) => {
        if (
          val !== "" &&
          val < inverterSizes[idx] * DAILY_COEFS.effectiveCoef
        ) {
          // in case of a multi input channel inverter, should take into account the successive input channel
          const toPush = inverterSizes[idx + 1] === "" ? [idx, idx + 1] : [idx];
          acc.push(...toPush);
        }
        return acc;
      }, [])
      .forEach((toCheck) => {
        const medianTooHigh =
          invDCVMedians[toCheck] >
          DAILY_COEFS.invDCVoltage * inverterDCVEstimate[toCheck];
        const acVoltIsPositive = invAcVolt[toCheck] > 0;
        if (medianTooHigh && acVoltIsPositive) {
          riso = 3;
        }
      });

    return riso;
  } catch (err) {
    console.error(err);
    return -1;
  }
}

function calcInvRiso(invSize, dcEstimates, invPower, acVoltageMin, dcVoltages) {
  const riso2MinuteLimit = 60;
  const riso3MinuteLimit = 360;

  const size = Number.parseFloat(invSize);
  if (!Number.isFinite(size)) {
    return 0;
  }
  const timestamps = Object.keys(invPower).sort();
  const suspectTs = timestamps.map((ts) => {
    const powerValue = Number.parseFloat(invPower[ts]);
    const acValue = Number.parseFloat(acVoltageMin[ts]);
    const dcValues = dcVoltages.map((v) => Number.parseFloat(v[ts]));
    if (
      !Number.isFinite(powerValue) ||
      !Number.isFinite(acValue) ||
      dcValues.every((v) => !Number.isFinite(v))
    ) {
      // indeterminate placeholder, can be true or false
      return null;
    } else if (
      powerValue < size * DAILY_COEFS.effectiveCoef &&
      acValue > 0 &&
      dcValues.some(
        (v, idx) =>
          v > Number.parseFloat(dcEstimates[idx]) * DAILY_COEFS.invDCVoltage
      )
    ) {
      return true;
    }
    return false;
  });

  let maximalMinuteInterval = 0;
  let idx = suspectTs.findIndex((s) => s),
    lastIdx;

  while (idx < suspectTs.length && idx !== -1) {
    lastIdx = idx;
    let currentMoment = moment(timestamps[idx]);
    let nextMoment = moment(timestamps[idx + 1]);
    while (
      suspectTs[lastIdx + 1] ||
      (currentMoment.isSame(nextMoment, "hour") &&
        suspectTs[lastIdx] &&
        suspectTs[lastIdx + 1] === null)
    ) {
      ++lastIdx;
      suspectTs[lastIdx] = true;
      currentMoment = moment(timestamps[lastIdx]);
      nextMoment = moment(timestamps[lastIdx + 1]);
    }
    const firstMoment = moment(timestamps[idx]);
    const lastMoment = moment(timestamps[lastIdx]);
    maximalMinuteInterval = Math.max(
      maximalMinuteInterval,
      lastMoment.diff(firstMoment, "minutes")
    );
    idx = suspectTs.findIndex((s, i) => s && i > lastIdx);
  }

  if (0 !== maximalMinuteInterval) {
    if (riso3MinuteLimit <= maximalMinuteInterval) {
      return 3;
    } else if (riso2MinuteLimit <= maximalMinuteInterval) {
      return 2;
    } else {
      return 1;
    }
  } else {
    return 0;
  }
}

export function calcRiso(stats, data, sys, sunrise) {
  try {
    const { inverterSizes, inverterDCVEstimate: dcEstimates } = sys;
    const { invAcVoltageMin: acVoltMin, invDcVoltage, invPower } = data;

    // calculate subchannels' indices per inverter
    const invSubchannelsRanges = [];
    for (let idx = 0; idx < inverterSizes.length; ++idx) {
      let startIdx = idx;
      let endIdx = idx;
      while (
        idx + 1 < inverterSizes.length &&
        !Number.isFinite(Number.parseFloat(inverterSizes[idx + 1]))
      ) {
        ++idx;
        endIdx = idx;
      }
      invSubchannelsRanges.push({ startIdx, endIdx });
    }

    const invRisoArr = invSubchannelsRanges.map(
      ({ startIdx: s, endIdx }, invIdx) => {
        if (
          Array.isArray(stats.invStatsMapping) &&
          stats.invStatsMapping[invIdx] &&
          stats.invStatsMapping[invIdx].inv_fstart_time
        ) {
          const [hour, minute] =
            stats.invStatsMapping[invIdx].inv_fstart_time.split(":");
          const firstStartMoment = moment(sunrise)
            .startOf("day")
            .hours(hour)
            .minutes(minute);
          if (
            firstStartMoment.isSameOrBefore(
              moment(sunrise).add(RISO_MINUTE_CALC_THRESHOLD, "minutes")
            )
          ) {
            return 0;
          }
        }
        const e = endIdx + 1;
        return calcInvRiso(
          inverterSizes[s],
          dcEstimates.slice(s, e),
          invPower[s],
          acVoltMin[s],
          invDcVoltage.slice(s, e)
        );
      }
    );
    const riso = Math.max(...invRisoArr);
    return Number.isFinite(riso) ? riso : 0;
  } catch (err) {
    return calcRisoOld(stats, data, sys);
  }
}

function calcInvMcm(invSize, dcEstimates, invPower, acVoltageMin, dcVoltages) {
  const mcm2MinuteLimit = 30;
  const mcm3MinuteLimit = 90;

  const size = Number.parseFloat(invSize);
  if (!Number.isFinite(size)) {
    return 0;
  }
  const timestamps = Object.keys(invPower).sort();
  const suspectTs = timestamps.map((ts) => {
    const powerValue = Number.parseFloat(invPower[ts]);
    const acValue = Number.parseFloat(acVoltageMin[ts]);
    const dcValues = dcVoltages.map((v) => Number.parseFloat(v[ts]));
    if (
      !Number.isFinite(powerValue) ||
      !Number.isFinite(acValue) ||
      dcValues.every((v) => !Number.isFinite(v))
    ) {
      // indeterminate placeholder, can be true or false
      return null;
    } else if (
      powerValue < size * DAILY_COEFS.effectiveCoef &&
      acValue > 0 &&
      dcValues.some(
        (v, idx) =>
          v > Number.parseFloat(dcEstimates[idx]) * DAILY_COEFS.invDCVoltage
      )
    ) {
      return true;
    }
    return false;
  });

  let maximalMinuteInterval = 0;
  let idx = suspectTs.findIndex((s) => s),
    lastIdx;

  while (idx < suspectTs.length && idx !== -1) {
    lastIdx = idx;
    let currentMoment = moment(timestamps[idx]);
    let nextMoment = moment(timestamps[idx + 1]);
    while (
      suspectTs[lastIdx + 1] ||
      (currentMoment.isSame(nextMoment, "hour") &&
        suspectTs[lastIdx] &&
        suspectTs[lastIdx + 1] === null)
    ) {
      ++lastIdx;
      suspectTs[lastIdx] = true;
      currentMoment = moment(timestamps[lastIdx]);
      nextMoment = moment(timestamps[lastIdx + 1]);
    }
    const firstMoment = moment(timestamps[idx]);
    const lastMoment = moment(timestamps[lastIdx]);
    maximalMinuteInterval = Math.max(
      maximalMinuteInterval,
      lastMoment.diff(firstMoment, "minutes")
    );
    idx = suspectTs.findIndex((s, i) => s && i > lastIdx);
  }

  if (0 !== maximalMinuteInterval) {
    if (mcm3MinuteLimit <= maximalMinuteInterval) {
      return 3;
    } else if (mcm2MinuteLimit <= maximalMinuteInterval) {
      return 2;
    } else {
      return 1;
    }
  } else {
    return 0;
  }
}

export function calcMcm(stats, data, sys, sunrise) {
  try {
    const { inverterSizes, inverterDCVEstimate: dcEstimates } = sys;
    const { invAcVoltageMin: acVoltMin, invDcVoltage, invPower } = data;

    // calculate subchannels' indices per inverter
    const invSubchannelsRanges = [];
    for (let idx = 0; idx < inverterSizes.length; ++idx) {
      let startIdx = idx;
      let endIdx = idx;
      while (
        idx + 1 < inverterSizes.length &&
        !Number.isFinite(Number.parseFloat(inverterSizes[idx + 1]))
      ) {
        ++idx;
        endIdx = idx;
      }
      invSubchannelsRanges.push({ startIdx, endIdx });
    }

    const invMcmArr = invSubchannelsRanges.map(
      ({ startIdx: s, endIdx }, invIdx) => {
        if (
          Array.isArray(stats.invStatsMapping) &&
          stats.invStatsMapping[invIdx] &&
          stats.invStatsMapping[invIdx].inv_fstart_time &&
          stats.invStatsMapping[invIdx].inv_lstart_time
        ) {
          const [fhour, fminute] =
            stats.invStatsMapping[invIdx].inv_fstart_time.split(":");
          const [lhour, lminute] =
            stats.invStatsMapping[invIdx].inv_lstart_time.split(":");
          const firstStartMoment = moment(sunrise)
            .startOf("day")
            .hours(fhour)
            .minutes(fminute);
          const lastStartMoment = moment(sunrise)
            .startOf("day")
            .hours(lhour)
            .minutes(lminute);
          if (
            firstStartMoment.isBefore(
              moment(sunrise).add(MCM_MINUTE_CALC_THRESHOLD, "minutes")
            ) &&
            lastStartMoment.isAfter(
              moment(sunrise).add(MCM_MINUTE_CALC_THRESHOLD, "minutes")
            )
          ) {
            const e = endIdx + 1;
            return calcInvMcm(
              inverterSizes[s],
              dcEstimates.slice(s, e),
              invPower[s],
              acVoltMin[s],
              invDcVoltage.slice(s, e)
            );
          }
        }
        return 0;
      }
    );
    const mcm = Math.max(...invMcmArr);
    return Number.isFinite(mcm) ? mcm : 0;
  } catch (err) {
    return 0;
  }
}

// if the object is falsey, an empty object, or an array of empty objects, returns true
function isEmptyObject(obj) {
  if (Array.isArray(obj)) {
    return obj.every((entry) => !Object.keys(entry).length);
  }
  return !obj || !Object.keys(obj).length;
}

function findClosestTimestamps(ts, tsArr) {
  const momentTs = moment(ts);
  const idx = tsArr.findIndex((elem, idx, arr) =>
    momentTs.isBetween(elem, arr[idx + 1], undefined, "[]")
  );
  return [tsArr[idx], tsArr[idx + 1]];
}

export function calcBlackout(data, sys) {
  try {
    let blackout = 0;
    const { inverterDCVEstimate, location, inverterSizes, sysACSize } = sys;
    let { sysPower, invPower, invAcVoltageMin, invDcVoltage } = data;
    // sys power is either empty or defective, use invPower instead
    if (isEmptyObject(sysPower)) {
      sysPower = Stats.convertInvPowerToSysPowerCommCalc(invPower);
    }

    const systemSize =
      Number(sysACSize || calcSysACSize(inverterSizes)) || null;

    // all of these objects has to exist in order to compute blackout
    const canCalc = !(
      isEmptyObject(sysPower) ||
      isEmptyObject(invPower) ||
      !Array.isArray(inverterDCVEstimate) ||
      isEmptyObject(invAcVoltageMin) ||
      isEmptyObject(invDcVoltage) ||
      isEmptyObject(location) ||
      !Array.isArray(inverterSizes) ||
      !systemSize
    );
    if (!canCalc) {
      return blackout;
    }

    // set hour to 3AM to avoid time convertion to prior day
    let lastDataTime = moment(Object.keys(sysPower[0])[0]).hour(3);
    // relevant times of the day
    const { sunrise, sunset } = SunCalc.getTimes(
      lastDataTime,
      location.lat,
      location.lon
    );
    const effectiveZeroPower = DAILY_COEFS.effectiveCoef * systemSize;
    // arrays of timestamps where system power is not defined, or equals to zero
    const { missingTs, zeroTs } = Object.entries(sysPower[0]).reduce(
      (timestamps, [ts, val], idx, powArr) => {
        if (
          moment(ts).isBetween(
            moment(sunrise).add(90, "minutes"),
            moment(sunset).subtract(1, "hours"),
            undefined,
            "[]"
          )
        ) {
          const numVal = parseFloat(val);
          // numeric value could not be parsed, missing value
          if (Number.isNaN(numVal)) {
            timestamps.missingTs.push(ts);
          }
          // value is smaller than a predefined value, close enough to zero
          else if (
            numVal < effectiveZeroPower &&
            !(
              parseFloat(powArr[idx - 1][1]) < effectiveZeroPower ||
              parseFloat(powArr[idx + 1][1]) < effectiveZeroPower
            )
          ) {
            timestamps.zeroTs.push(ts);
          } else {
            lastDataTime = moment(ts);
          }
        }
        return timestamps;
      },
      { missingTs: [], zeroTs: [] }
    );

    const powerTimes = Object.keys(sysPower[0]).sort();
    const powerHoles = missingTs.reduce((holes, missing) => {
      const idx = powerTimes.findIndex((ts) => ts === missing);
      if (
        idx !== 0 &&
        Number.isNaN(parseFloat(sysPower[0][powerTimes[idx - 1]]))
      ) {
        return holes;
      }
      let endHole = missing;
      for (let index = idx + 1; index < powerTimes.length; ++index) {
        if (Number.isNaN(parseFloat(sysPower[0][powerTimes[index]]))) {
          endHole = powerTimes[index];
        } else {
          break;
        }
      }
      holes.push([missing, endHole]);
      return holes;
    }, []);

    // no data hole + sysPower point drop within mid day
    // if (powerHoles.length === 0 && zeroTs.length !== 0) {
    //   // check for high DC voltage spikes
    //   const highInvDCV = inverterDCVEstimate.map((expectedDCV, idx) => {
    //     const invDCV = invDcVoltage[idx];
    //     let highVoltage = false;
    //     zeroTs.forEach((ts) => {
    //       // in case system and inverter DCV timestamps does not match find the interval of the ts and get it's start
    //       const invTs = invDCV.hasOwnProperty(ts)
    //         ? ts
    //         : findClosestTimestamps(ts, Object.keys(invDCV).sort())[0];
    //       if (
    //         parseFloat(invDCV[invTs]) >
    //         parseFloat(expectedDCV) * DAILY_COEFS.invDCVoltage
    //       ) {
    //         highVoltage = true;
    //       }
    //     });
    //     return highVoltage;
    //   });
    //   const highDCVPercentage =
    //     highInvDCV.reduce((sum, val) => {
    //       return sum + val;
    //     }, 0) /
    //       highInvDCV.length >
    //     DAILY_COEFS.invAmountDCVSpike;
    //   // check for AC voltage drops
    //   const highInvACV = invAcVoltageMin.map((invACV) => {
    //     const acTimes = Object.keys(invACV).sort();
    //     let acDrop = false;
    //     zeroTs.forEach((ts) => {
    //       const invTs = invACV.hasOwnProperty(ts)
    //         ? ts
    //         : findClosestTimestamps(ts, acTimes)[0];
    //       const tsIdx = acTimes.indexOf(invTs);
    //       if (Number.isNaN(parseFloat(invACV[invTs]))) {
    //         acDrop = true;
    //         return;
    //       } else {
    //         // index is large enough to look before it
    //         if (tsIdx > 1) {
    //           const averege =
    //             (parseFloat(invACV[acTimes[tsIdx - 2]]) +
    //               parseFloat(invACV[acTimes[tsIdx - 1]])) /
    //             2;
    //           if (invACV[invTs] < averege * DAILY_COEFS.invACVPercentageDrop) {
    //             acDrop = true;
    //             return;
    //           }
    //         }
    //         // index is small enough to look after it
    //         if (tsIdx < acTimes.length - 2) {
    //           const averege =
    //             (parseFloat(invACV[acTimes[tsIdx + 2]]) +
    //               parseFloat(invACV[acTimes[tsIdx + 1]])) /
    //             2;
    //           if (invACV[invTs] < averege * DAILY_COEFS.invACVPercentageDrop) {
    //             acDrop = true;
    //             return;
    //           }
    //         }
    //       }
    //     });
    //     return acDrop;
    //   });
    //   const highACVPercentage =
    //     highInvACV.reduce((sum, val) => {
    //       return sum + val;
    //     }, 0) /
    //       highInvACV.length >
    //     DAILY_COEFS.invAmountACDrop;

    //   if (highDCVPercentage && highACVPercentage) {
    //     blackout = 1;
    //   } else if (highDCVPercentage || highACVPercentage) {
    //     blackout = 2; // blackout 2c condition
    //   }
    // }

    // power holes case blackout 2b
    if (powerHoles.length && zeroTs.length) {
      let zeroPowerDropOnHole = false;
      powerHoles.forEach(([start, end]) => {
        const startIdx = powerTimes.indexOf(start);
        const endIdx = powerTimes.indexOf(end);
        if (
          zeroTs.includes(powerTimes[startIdx - 1]) ||
          zeroTs.includes(powerTimes[endIdx + 1])
        ) {
          zeroPowerDropOnHole = true;
        }
      });
      if (zeroPowerDropOnHole) {
        const highInvDCV = inverterDCVEstimate.map((expectedDCV, idx) => {
          const invDCV = invDcVoltage[idx];
          const invDCVTimes = Object.keys(invDCV).sort();
          let highVoltage = false;
          powerHoles.forEach(([start, end]) => {
            // in case system and inverter DCV timestamps does not match find the interval of the ts and get it's start
            const invTsStart = invDCV.hasOwnProperty(start)
              ? start
              : findClosestTimestamps(start, invDCVTimes)[0];
            const invTsEnd = invDCV.hasOwnProperty(end)
              ? end
              : findClosestTimestamps(end, invDCVTimes)[1];
            if (
              parseFloat(invDCV[invTsStart]) >
                parseFloat(expectedDCV) * DAILY_COEFS.invDCVoltage ||
              parseFloat(invDCV[invTsEnd]) >
                parseFloat(expectedDCV) * DAILY_COEFS.invDCVoltage
            ) {
              highVoltage = true;
            }
          });
          return highVoltage;
        });
        const highDCVPercentage =
          highInvDCV.reduce((sum, val) => {
            return sum + val;
          }, 0) /
            highInvDCV.length >
          DAILY_COEFS.invAmountDCVSpike;
        // check for AC voltage drops
        const highInvACV = invAcVoltageMin.map((invACV) => {
          const acTimes = Object.keys(invACV).sort();
          let acDrop = false;
          powerHoles.forEach(([start, end]) => {
            const invTsStart = invACV.hasOwnProperty(start)
              ? start
              : findClosestTimestamps(start, acTimes)[0];
            const invTsEnd = invACV.hasOwnProperty(end)
              ? end
              : findClosestTimestamps(end, acTimes)[1];
            const tsIdxStart = acTimes.indexOf(invTsStart);
            const tsIdxEnd = acTimes.indexOf(invTsEnd);
            // check start of hole
            if (Number.isNaN(parseFloat(invACV[invTsStart]))) {
              acDrop = true;
              return;
            } else {
              // index is large enough to look before it
              if (tsIdxStart > 1) {
                const averege =
                  (parseFloat(invACV[acTimes[tsIdxStart - 2]]) +
                    parseFloat(invACV[acTimes[tsIdxStart - 1]])) /
                  2;
                if (
                  invACV[invTsStart] <
                  averege * DAILY_COEFS.invACVPercentageDrop
                ) {
                  acDrop = true;
                  return;
                }
              }
              // index is small enough to look after it
              if (tsIdxStart < acTimes.length - 2) {
                const averege =
                  (parseFloat(invACV[acTimes[tsIdxStart + 2]]) +
                    parseFloat(invACV[acTimes[tsIdxStart + 1]])) /
                  2;
                if (
                  invACV[invTsStart] <
                  averege * DAILY_COEFS.invACVPercentageDrop
                ) {
                  acDrop = true;
                  return;
                }
              }
            }

            // check end of hole
            if (Number.isNaN(parseFloat(invACV[invTsEnd]))) {
              acDrop = true;
              return;
            } else {
              // index is large enough to look before it
              if (tsIdxEnd > 1) {
                const averege =
                  (parseFloat(invACV[acTimes[tsIdxEnd - 2]]) +
                    parseFloat(invACV[acTimes[tsIdxEnd - 1]])) /
                  2;
                if (
                  invACV[invTsEnd] <
                  averege * DAILY_COEFS.invACVPercentageDrop
                ) {
                  acDrop = true;
                  return;
                }
              }
              // index is small enough to look after it
              if (tsIdxEnd < acTimes.length - 2) {
                const averege =
                  (parseFloat(invACV[acTimes[tsIdxEnd + 2]]) +
                    parseFloat(invACV[acTimes[tsIdxEnd + 1]])) /
                  2;
                if (
                  invACV[invTsEnd] <
                  averege * DAILY_COEFS.invACVPercentageDrop
                ) {
                  acDrop = true;
                  return;
                }
              }
            }
          });
          return acDrop;
        });
        const highACVPercentage =
          highInvACV.reduce((sum, val) => {
            return sum + val;
          }, 0) /
            highInvACV.length >
          DAILY_COEFS.invAmountACDrop;
        if (highDCVPercentage || highACVPercentage) {
          blackout = 2;
        }
      }
    }

    const lastHoleTime = !powerHoles.length
      ? null
      : moment(powerHoles[powerHoles.length - 1][1]);
    // blackout is 2 and last data is prior to 1 hour of sunset
    if (
      blackout === 2 &&
      powerHoles.length &&
      moment(powerHoles[powerHoles.length - 1][1]).isSame(sunset, "hours")
    ) {
      const now = moment();
      // only advance to blackout 3 if all data for the day have been collected
      if (
        !now.isSame(lastDataTime, "day") ||
        now.isAfter(moment(sunset).add(1, "hours"))
      ) {
        blackout = 3;
      }
    }

    return blackout;
  } catch (err) {
    console.error(err);
    return -1;
  }
}

// pseudo-daily function to find stopped inverters, only works for comm 0
export function getStoppedInv(invPower, lat, lon, collateHourlyData) {
  if (!invPower || invPower.length === 0) {
    return [false];
  }
  const dataDate = moment(Object.keys(invPower[0])[1]).hour(12);
  const utcOff = dataDate.utcOffset();
  const sunTimes = SunCalc.getTimes(dataDate, lat, lon);
  const { sunrise, sunset } = {
    sunrise: moment(sunTimes.sunrise).add(120, "minutes"),
    sunset: moment(sunTimes.sunset).subtract(60, "minutes"),
  };
  if (!moment().utcOffset(utcOff).isSame(dataDate, "day")) {
    return [false];
  }
  return invPower.map((singleInvPow) => {
    // get quarter hourly means of data, if existing interval is larger, smallest interval is returned
    const invPowMeans = Stats.toMinuteIntervalMeans(
      singleInvPow,
      collateHourlyData,
      true,
      15
    );
    //reverse sort
    const ts = Object.keys(invPowMeans || {})
      .filter((singleTstamp) => {
        const inWorkTimes = moment(singleTstamp).isBetween(
          sunrise,
          sunset,
          "minutes",
          "[]"
        );
        const isNumericValue = Number.isFinite(
          parseFloat(invPowMeans[singleTstamp])
        );
        return inWorkTimes && isNumericValue;
      })
      .sort((a, b) => {
        return a < b ? 1 : -1;
      });
    if (ts.length > 1) {
      if (
        parseFloat(invPowMeans[ts[0]]) === 0 &&
        parseFloat(invPowMeans[ts[1]]) === 0
      ) {
        return true;
      }
    }
    return false;
  });
}

// pseudo-daily function to find stopped subchannels
export function getStoppedSubchannels(
  subchannelDcCurrent,
  dataDate,
  lat,
  lon,
  collateHourlyData
) {
  if (!subchannelDcCurrent || subchannelDcCurrent.length === 0) {
    return [];
  }
  const utcOff = dataDate.utcOffset();
  const sunTimes = SunCalc.getTimes(dataDate, lat, lon);
  const { sunrise, sunset } = {
    sunrise: moment(sunTimes.sunrise).add(120, "minutes"),
    sunset: moment(sunTimes.sunset).subtract(60, "minutes"),
  };
  if (!moment().utcOffset(utcOff).isSame(dataDate, "day")) {
    return [];
  }
  return subchannelDcCurrent.map((subchannels) => {
    return subchannels.map((singleSubchannel) => {
      // get quarter hourly means of data, if existing interval is larger, smallest interval is returned
      const subchannelMeans = Stats.toMinuteIntervalMeans(
        singleSubchannel,
        collateHourlyData,
        true,
        15
      );
      //reverse sort
      const ts = Object.keys(subchannelMeans || {})
        .filter((singleTstamp) => {
          const inWorkTimes = moment(singleTstamp).isBetween(
            sunrise,
            sunset,
            "minutes",
            "[]"
          );
          const isNumericValue = Number.isFinite(
            parseFloat(subchannelMeans[singleTstamp])
          );
          return inWorkTimes && isNumericValue;
        })
        .sort((a, b) => {
          return a < b ? 1 : -1;
        });
      if (ts.length > 1) {
        if (
          parseFloat(subchannelMeans[ts[0]]) === 0 &&
          parseFloat(subchannelMeans[ts[1]]) === 0
        ) {
          return true;
        }
      }
      return false;
    });
  });
}
