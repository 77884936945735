import React from "react";
import styled from "styled-components";

const Field = styled.div`
  margin-bottom: 1em;
`;

const FieldName = styled.b`
  margin-${(props) => (props.leftMargin ? "left" : "right")}: 1em;
`;

function getCleanState() {
  return {
    id: "",
    name: "",
    make: "",
    date_installed: "",
    parent_id: "",
    power: "",
    frozen: false,
    inverters: [],
    invId: "",
    invName: "",
    invDisabled: false,
    chansPerInv: 0,
    stringsPerChan: 2,
  };
}

class AddSystemSpecialized extends React.Component {
  constructor(props) {
    super(props);

    this.state = getCleanState();
  }

  createSystemString() {
    const {
      id,
      name,
      make,
      date_installed,
      parent_id,
      power,
      frozen,
      inverters,
    } = this.state;
    const sysObject = {
      id,
      name,
      make,
      date_installed,
      power: Number.parseFloat(power) || 0,
    };
    if (parent_id) {
      sysObject.parent_id = parent_id;
    }
    if (frozen) {
      sysObject.frozen = !!frozen;
    }
    if (inverters.length) {
      sysObject.subsystems = [];
      sysObject.inverters = [];
      inverters.forEach(({ id, name: invName, disabled, channels }) => {
        const invData = { id, name: invName, disabled };
        if (channels.length) {
          invData.channels = channels.map(
            ({ id, name: chanName, disabled, subchannels }, chanIdx) => {
              sysObject.subsystems.push(`${invName}-${chanIdx + 1}`);
              const chan = { id, name: chanName, disabled };
              if (subchannels.length) {
                chan.subchannels = subchannels.map(
                  ({ id, name: subchanName, disabled }) => {
                    return { id, name: subchanName, disabled };
                  }
                );
              }
              return chan;
            }
          );
        }
        sysObject.inverters.push(invData);
      });
    }
    return `"${id}": ${JSON.stringify(sysObject, null, 2)}`;
  }

  addInverter = () => {
    this.setState((state) => {
      const inverters = [...state.inverters];
      const channels = new Array(state.chansPerInv)
        .fill(null)
        .map((e, chanIdx) => {
          const subchannels = new Array(state.stringsPerChan)
            .fill(null)
            .map((e, strIdx) => {
              const stringId = strIdx + chanIdx * state.stringsPerChan;
              return {
                id: `${stringId}`,
                name: `${state.invName}_PV${stringId + 1}`,
                disabled: false,
              };
            });
          return {
            id: `${chanIdx}`,
            name: `${state.invName}_MPPT${chanIdx + 1}`,
            disabled: false,
            subchannels,
          };
        });
      inverters.push({
        id: state.invId,
        name: state.invName,
        disabled: state.invData,
        channels,
      });
      return { inverters };
    });
  };

  render() {
    const systemString = this.createSystemString();
    return (
      <div>
        <h1>Add System (huawei)</h1>

        <Field>
          <input
            type="checkbox"
            checked={this.state.frozen}
            onChange={(e) => this.setState({ frozen: e.target.checked })}
          />
          <FieldName leftMargin>Frozen</FieldName>
        </Field>

        <Field>
          <FieldName>System id:</FieldName>
          <input
            type="text"
            value={this.state.id}
            onChange={(e) => this.setState({ id: e.target.value })}
          />
        </Field>

        <Field>
          <FieldName>System name:</FieldName>
          <input
            type="text"
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </Field>

        <Field>
          <FieldName>System make:</FieldName>
          <input
            type="text"
            value={this.state.make}
            onChange={(e) => this.setState({ make: e.target.value })}
          />
        </Field>

        <Field>
          <FieldName>Date installed:</FieldName>
          <input
            type="text"
            value={this.state.date_installed}
            onChange={(e) => this.setState({ date_installed: e.target.value })}
          />
        </Field>

        <Field>
          <FieldName>Parent id:</FieldName>
          <input
            type="text"
            value={this.state.parent_id}
            onChange={(e) => this.setState({ parent_id: e.target.value })}
          />
        </Field>

        <Field>
          <FieldName>System power:</FieldName>
          <input
            type="number"
            value={this.state.power}
            onChange={(e) => this.setState({ power: e.target.value })}
          />
        </Field>

        <Field>
          <FieldName>Inverters:</FieldName>
          <button onClick={this.addInverter}>Add inverter</button>
        </Field>
        <Field>
          <FieldName>Inv id:</FieldName>
          <input
            type="text"
            value={this.state.invId}
            onChange={(e) => this.setState({ invId: e.target.value })}
          />
        </Field>
        <Field>
          <FieldName>Inv name:</FieldName>
          <input
            type="text"
            value={this.state.invName}
            onChange={(e) => this.setState({ invName: e.target.value })}
          />
        </Field>
        <Field>
          <FieldName>Channels per inv:</FieldName>
          <input
            type="number"
            value={this.state.chansPerInv}
            onChange={(e) => this.setState({ chansPerInv: +e.target.value })}
          />
        </Field>
        <Field>
          <FieldName>Strings per channel:</FieldName>
          <input
            type="number"
            value={this.state.stringsPerChan}
            onChange={(e) => this.setState({ stringsPerChan: +e.target.value })}
          />
        </Field>

        <div>
          <Field>
            <FieldName>Result:</FieldName>
            <button onClick={() => navigator.clipboard.writeText(systemString)}>
              Copy
            </button>
          </Field>
          <textarea value={systemString} readOnly={true} rows={30} cols={50} />
        </div>
        <button onClick={() => this.setState({ ...getCleanState() })}>
          Clear
        </button>
      </div>
    );
  }
}

export default AddSystemSpecialized;
