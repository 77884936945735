import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";

import messageGenerator from "@soltell/soltell-message-generator";

import SystemsSelect from "../components/SystemsDropDown";
import DatePickerCombo from "../components/DatePickerCombo";
import { sendData } from "../services/sendData";

import LocalStorageKeys from "../localStorageKeys";
import { pushSysstats } from "../services/backendHelpers";

const TLabel = styled.th`
  text-align: start;
`;

const SubmitButton = styled.button`
  margin-top: 1em;
`;

const DATE_BACKEND_FORMAT = "YYYY-MM-DD";

const EF_LOGGER_MAX_LENGTH = 255;
const ALLOWED_INVDEG_VALUES = [
  "",
  "ok",
  ...Object.keys(messageGenerator.getIssuesData()["if-invdeg-n"]),
];

const getCleanState = () => {
  return {
    selectedSysId: "",
    selectedInvs: [],
    startDate: moment({ hour: 6 }),
    endDate: moment({ hour: 6 }),
    shadowString: "",
  };
};

class Shadow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...getCleanState(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      this.setState(getCleanState());
    }
  }

  getToken = () => {
    return localStorage.getItem(LocalStorageKeys.userToken);
  };

  onSystemSelect = (sysId) => {
    this.setState((state, props) => {
      const sysData = props.systemsData[sysId];
      const selectedInvs =
        sysData && Array.isArray(sysData.inverterMapping)
          ? sysData.inverterMapping.map((inv) => inv.id)
          : [];
      return { selectedSysId: sysId, selectedInvs };
    });
  };

  onInvSelect = (ev) => {
    ev.preventDefault();
    const selectedInvs = [...ev.target.selectedOptions].map(
      (option) => option.value
    );
    this.setState({ selectedInvs });
  };

  diffDays = (subtructed = this.state.endDate, by = this.state.startDate) => {
    return subtructed.diff(by, "days");
  };

  getDatesArray = () => {
    const { startDate, endDate } = this.state;
    return Array(this.diffDays(endDate, startDate) + 1)
      .fill(0)
      .map((e, idx) => {
        return moment(startDate).add(idx, "days").format(DATE_BACKEND_FORMAT);
      });
  };

  onStartDateSelect = (newStartDate) => {
    this.setState((state) => {
      const newState = { startDate: moment(newStartDate) };
      if (this.diffDays(state.endDate, newStartDate) < 0) {
        newState.endDate = moment(newStartDate);
      }
      return newState;
    });
  };

  onEndDateSelect = (newEndDate) => {
    this.setState((state) => ({
      endDate: moment(
        this.diffDays(newEndDate, state.startDate) < 0
          ? state.startDate
          : newEndDate
      ),
    }));
  };

  handleShadowChange = (ev) => {
    ev.preventDefault();
    const newValue = ev.target.value;
    this.setState({ shadowString: newValue });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    const sys = this.props.systemsData[this.state.selectedSysId];

    const sharedPayload = {
      sys_id: sys.id,
      inv_ids: [...this.state.selectedInvs],
      newValues: { shadow: this.state.shadowString },
    };

    const urlPut = "/api/stats/inv-create-or-update";

    const dates = this.getDatesArray();
    Promise.all(
      dates.map((date) => {
        const payload = {
          ...sharedPayload,
          date,
        };
        return sendData(payload, urlPut, this.getToken(), "PUT");
      })
    )
      .then((results) => {
        const badResults = results.filter((r) => !r.ok);
        if (badResults.length) {
          console.warn(badResults);
          throw new Error("some results are bad, all are printed above");
        }
        window.alert("done");
      })
      .catch((err) => {
        console.error(err);
        window.alert("error, check console with f12");
      });
  };

  render() {
    const { loadError, isLoading, systemsData } = this.props;
    const { selectedSysId, selectedInvs, shadowString } = this.state;

    if (loadError) {
      return (
        <div>
          Error Loading Systems Data:
          <br />
          {loadError.message}
        </div>
      );
    }
    if (isLoading) {
      return <div>Loading Systems Data...</div>;
    }

    // remove refs
    const systems = Object.entries(systemsData).reduce((acc, [id, sys]) => {
      if (!sys.reference && !sys.is_reference) {
        acc[id] = sys;
      }
      return acc;
    }, {});

    const sysData = systems[selectedSysId];
    const invList =
      sysData &&
      Array.isArray(sysData.inverterMapping) &&
      sysData.inverterMapping.length
        ? sysData.inverterMapping
        : null;

    return (
      <div>
        <div>
          <table>
            <thead>
              <tr>
                <TLabel>System:</TLabel>
                <TLabel>Start Date:</TLabel>
                <TLabel>End Date:</TLabel>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <SystemsSelect
                    systems={Object.values(systems)}
                    selectedSystem={selectedSysId}
                    onSystemSelect={this.onSystemSelect}
                  />
                </td>
                <td>
                  <DatePickerCombo
                    selected={this.state.startDate}
                    onChange={this.onStartDateSelect}
                  />
                </td>
                <td>
                  <DatePickerCombo
                    selected={this.state.endDate}
                    onChange={this.onEndDateSelect}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <TLabel>shadow:</TLabel>
                <TLabel>selected inverters:</TLabel>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    value={shadowString}
                    placeholder="shadow"
                    onChange={this.handleShadowChange}
                  />
                </td>
                <td>
                  <select
                    multiple={true}
                    disabled={!invList}
                    value={selectedInvs}
                    onChange={this.onInvSelect}
                  >
                    {invList ? (
                      invList.map((inv, idx) => (
                        <option key={idx} value={inv.id}>
                          {inv.name}
                        </option>
                      ))
                    ) : (
                      <option>no inverters</option>
                    )}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <SubmitButton
          disabled={!selectedSysId || !shadowString.length || !invList}
          onClick={this.handleSubmit}
        >
          Submit
        </SubmitButton>
      </div>
    );
  }
}

export default Shadow;
