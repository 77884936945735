import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Sunset from "../Sunset";
import Dawn from "../Dawn";
import StatResults from "../StatResults";
import styled from "styled-components";
import QueriesStatuses from "./QueriesStatuses";
import * as Spreadsheet from "../../services/Spreadsheet";

// const SYS_LAT = 32.085300;
// const SYS_LON = 34.781768;
// const SYS_TIMEZONE = '+02';

const ResultsPropsLine = styled.div`
  margin-bottom: 1em;
`;
const ResultsProp = styled.span`
  margin-right: 1em;
`;
const initialState = {
  pushResult: null,
  pushQueries: [],
  currentPushPosition: 0,
};

class Result extends React.Component {
  _isMounted = true;

  static propTypes = {
    dataSys: PropTypes.object,
    dataDate: PropTypes.object, // moment
    data: PropTypes.object,
    results: PropTypes.object,
    daily: PropTypes.shape({
      comm: PropTypes.number,
      fct: PropTypes.any,
      webbox: PropTypes.string,
    }),
    latest: PropTypes.string,
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({
      pushQueries: [],
      currentPushPosition: 0,
    });
  }

  pushPromise = (payload) => {
    const { date, sysDocId, sysId, data } = payload;
    let updatedPushQueries = [...this.state.pushQueries];
    const currentPushPosition = this.state.currentPushPosition;
    if (
      this.state.pushQueries[currentPushPosition] &&
      this.state.pushQueries[currentPushPosition].status === "Skipped"
    ) {
      if (this.state.pushQueries[currentPushPosition + 1]) {
        this.setState(
          {
            currentPushPosition: currentPushPosition + 1,
          },
          () => {
            return this.pushPromise(
              this.state.pushQueries[currentPushPosition]
            );
          }
        );
      }
    } else if (
      this.state.pushQueries[currentPushPosition] &&
      this.state.pushQueries[currentPushPosition].status !== "Pending"
    ) {
      updatedPushQueries[currentPushPosition].status = "Pending";
      this.setState({
        pushQueries: updatedPushQueries,
      });
      return Spreadsheet.pushData(date, data, sysDocId, sysId, "N")
        .then((data) => {
          console.log(data);
          if (this._isMounted) {
            let updatedPushQueries = [...this.state.pushQueries];
            if (data.success) {
              updatedPushQueries[this.state.currentPushPosition].status =
                "Success";
              this.setState(
                {
                  pushResult: data,
                  pushQueries: updatedPushQueries,
                  currentPushPosition: this.state.currentPushPosition + 1,
                },
                () => {
                  if (
                    !!this.state.pushQueries[this.state.currentPushPosition]
                  ) {
                    return this.pushPromise(
                      this.state.pushQueries[currentPushPosition]
                    );
                  }
                }
              );
            } else {
              updatedPushQueries[this.state.currentPushPosition].status =
                "Failed";
              this.setState(
                {
                  pushResult: {
                    success: false,
                    message: data.message,
                  },
                  pushQueries: updatedPushQueries,
                  currentPushPosition: this.state.currentPushPosition + 1,
                },
                () => {
                  if (
                    !!this.state.pushQueries[this.state.currentPushPosition]
                  ) {
                    return this.pushPromise(
                      this.state.pushQueries[currentPushPosition]
                    );
                  }
                }
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (this._isMounted) {
            let updatedPushQueries = [...this.state.pushQueries];
            updatedPushQueries[this.state.currentPushPosition].status =
              "Failed";
            this.setState(
              {
                pushResult: {
                  success: false,
                  message: err.message,
                },
                pushQueries: updatedPushQueries,
                currentPushPosition: this.state.currentPushPosition + 1,
              },
              () => {
                if (!!this.state.pushQueries[this.state.currentPushPosition]) {
                  return this.pushPromise(
                    this.state.pushQueries[currentPushPosition]
                  );
                }
              }
            );
          }
        });
    }
  };

  retryPush = (payload, index) => {
    let updatedPushQueries = [...this.state.pushQueries];
    updatedPushQueries.splice(index, 1);
    updatedPushQueries.push(payload);
    this.setState(
      {
        pushQueries: updatedPushQueries,
        currentPushPosition:
          updatedPushQueries.findIndex(
            (query) => query.status === "Pending"
          ) === -1
            ? updatedPushQueries.length - 1
            : updatedPushQueries.findIndex(
                (query) => query.status === "Pending"
              ),
      },
      () => {
        this.pushPromise(payload);
      }
    );
  };

  cancelPushQuery = (index) => {
    let updatedPushQueries = [...this.state.pushQueries];
    updatedPushQueries[index].status = "Skipped";
    this.setState({
      pushQueries: updatedPushQueries,
      currentPushPosition:
        updatedPushQueries.findIndex((query) => query.status === "Pending") ===
        -1
          ? updatedPushQueries.length - 1
          : updatedPushQueries.findIndex((query) => query.status === "Pending"),
    });
  };

  clearAllQueries = () => {
    this.setState({
      pushQueries: [],
      currentPushPosition: 0,
    });
  };

  doPushToSheet = (pusQueryData) => {
    this.setState(
      {
        pushResult: null,
        pushQueries: [...this.state.pushQueries, pusQueryData],
      },
      () => {
        this.pushPromise(pusQueryData);
      }
    );
    // this.setState({
    //   isPushOngoing: false,
    //   pushResult: {
    //     message: "Mock push done",
    //     success: true,
    //   },
    // })
    // return
  };

  render() {
    return (
      <div>
        <ResultsPropsLine>
          <ResultsProp>
            <strong>System:</strong> {this.props.dataSys.id}{" "}
          </ResultsProp>
          <ResultsProp>
            <strong>Logger:</strong> {this.props.dataSys.inverter_make || ""}{" "}
          </ResultsProp>
          <ResultsProp>
            <strong>Date:</strong>{" "}
            {moment(this.props.dataDate).format("D/MMM/YYYY")}{" "}
          </ResultsProp>
          <ResultsProp>
            <strong>Dawn:</strong>
            <Dawn
              date={this.props.dataDate}
              lon={this.props.location.lon}
              lat={this.props.location.lat}
            />{" "}
            <strong>Sunset:</strong>
            <Sunset
              date={this.props.dataDate}
              lon={this.props.location.lon}
              lat={this.props.location.lat}
            />{" "}
          </ResultsProp>
          <ResultsProp>
            <strong>Last data from:</strong>{" "}
            {this.props.latest
              ? moment(this.props.latest).format("HH:mm")
              : "NA"}
          </ResultsProp>
        </ResultsPropsLine>
        <QueriesStatuses
          pushQueries={this.state.pushQueries}
          retryPush={this.retryPush}
          isPushOngoing={this.state.pushQueries.some(
            (query) => query.status === "Pending" || query.status === "Sending"
          )}
          removePushQueryData={this.removePushQueryData}
          clearAllQueries={this.clearAllQueries}
          cancelPushQuery={this.cancelPushQuery}
        />
        <StatResults
          data={this.props.data}
          daily={this.props.daily}
          sysId={this.props.dataSys.id}
          sysDocId={this.props.dataSys.docId}
          dataDate={this.props.dataDate}
          dataSys={this.props.dataSys}
          doPushToSheet={this.doPushToSheet}
          results={this.props.results}
        />
      </div>
    );
  }
}

export default Result;
