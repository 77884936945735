export const sysPower = {
  "2017-10-20T00:00:00+00:00": "",
  "2017-10-20T01:00:00+00:00": "",
  "2017-10-20T02:00:00+00:00": "",
  "2017-10-20T03:00:00+00:00": "",
  "2017-10-20T04:00:00+00:00": "",
  "2017-10-20T05:00:00+00:00": "",
  "2017-10-20T06:00:00+00:00": "",
  "2017-10-20T07:00:00+00:00": "",
  "2017-10-20T08:00:00+00:00": "",
  "2017-10-20T09:00:00+00:00": "",
  "2017-10-20T10:00:00+00:00": "",
  "2017-10-20T11:00:00+00:00": "",
  "2017-10-20T12:00:00+00:00": "",
  "2017-10-20T13:00:00+00:00": "",
  "2017-10-20T14:00:00+00:00": "",
  "2017-10-20T15:00:00+00:00": "",
  "2017-10-20T16:00:00+00:00": "",
  "2017-10-20T17:00:00+00:00": "",
  "2017-10-20T18:00:00+00:00": "",
  "2017-10-20T19:00:00+00:00": "",
  "2017-10-20T20:00:00+00:00": "",
  "2017-10-20T21:00:00+00:00": "",
  "2017-10-20T22:00:00+00:00": "",
  "2017-10-20T23:00:00+00:00": "",
  "2017-10-20T24:00:00+00:00": "",
};

export const sysEnergy = {
  "2017-10-20T00:00:00+00:00": "",
  "2017-10-20T01:00:00+00:00": "",
  "2017-10-20T02:00:00+00:00": "",
  "2017-10-20T03:00:00+00:00": "",
  "2017-10-20T04:00:00+00:00": "",
  "2017-10-20T05:00:00+00:00": "",
  "2017-10-20T06:00:00+00:00": "",
  "2017-10-20T07:00:00+00:00": "",
  "2017-10-20T08:00:00+00:00": "",
  "2017-10-20T09:00:00+00:00": "",
  "2017-10-20T10:00:00+00:00": "",
  "2017-10-20T11:00:00+00:00": "",
  "2017-10-20T12:00:00+00:00": "",
  "2017-10-20T13:00:00+00:00": "",
  "2017-10-20T14:00:00+00:00": "",
  "2017-10-20T15:00:00+00:00": "",
  "2017-10-20T16:00:00+00:00": "",
  "2017-10-20T17:00:00+00:00": "",
  "2017-10-20T18:00:00+00:00": "",
  "2017-10-20T19:00:00+00:00": "",
  "2017-10-20T20:00:00+00:00": "",
  "2017-10-20T21:00:00+00:00": "",
  "2017-10-20T22:00:00+00:00": "",
  "2017-10-20T23:00:00+00:00": "",
  "2017-10-20T24:00:00+00:00": "",
};
