import moment from "moment";
import queryString from "query-string";
import * as SunCalc from "suncalc";

const API_URL = process.env.REACT_APP_API_URL;
const FUNC_NAME = "getDataNeteco?";
const RESPONSE_DATA_MAP = "inventerDataMap";

const RESPONSE_VALUE_MAP = [
  ["timestamp", /^createtime$/],
  ["invPower", /^OutputActivePower$/],
  ["invEnergy", /^DailyPowerGeneration$/],
  ["invDcVoltage", /^PV(\d+)Voltage$/],
  ["invAcVoltage", /^(A|B|C)outputVoltage1$/],
];

const DATE_FORMAT = "YYYY-MM-DD";
const FETCH_OPTIONS = {
  mode: "cors",
};

export function getData(systemInfo, date, timeOffset) {
  const { location } = systemInfo;
  const { ip_address, user, pass, group_sn, plant_name, inv_sn } =
    systemInfo.sources;
  const params = {
    ip_address,
    user,
    pass,
    group_sn,
    plant_name,
    date: moment(date).format(DATE_FORMAT),
  };
  const fetchUrl = API_URL + FUNC_NAME + queryString.stringify(params);

  return fetch(fetchUrl, FETCH_OPTIONS)
    .then((rawRes) => {
      if (!rawRes.ok) {
        throw new Error(rawRes.statusText);
      }
      return rawRes.json();
    })
    .then((res) => {
      // get inverter data array and map it
      return Object.fromEntries(
        Object.entries(res[RESPONSE_DATA_MAP]).map(([invId, invRawData]) => {
          // create data and channel object
          const res = invRawData.map((dataPoint) => {
            return RESPONSE_VALUE_MAP.reduce((acc, [fieldName, pattern]) => {
              let field = [];
              Object.keys(dataPoint).forEach((rawDataName) => {
                const match = rawDataName.match(pattern);
                if (match) {
                  field.push({
                    value: dataPoint[rawDataName],
                    channel: match[1] || null,
                  });
                  delete dataPoint[rawDataName];
                }
              });
              if (field.length) {
                // if there is more than a single result, return sorted lexicographically
                acc[fieldName] =
                  field.length === 1
                    ? field[0].value
                    : field
                        .sort((f1, f2) => {
                          return f1.channel < f2.channel ? -1 : 1;
                        })
                        .map((e) => e.value);
                if (fieldName === "timestamp") {
                  acc[fieldName] = moment(acc[fieldName]).format();
                }
              }
              return acc;
            }, {});
          });
          return [invId, res];
        })
      );
    })
    .then((data) => {
      return Object.entries(data)
        .filter(([serialNumber]) => {
          return inv_sn.includes(serialNumber);
        })
        .sort(([snA], [snB]) => {
          return inv_sn.indexOf(snA) - inv_sn.indexOf(snB);
        })
        .map(([serialNumber, datapoints]) => datapoints);
    })
    .then((data) => {
      return {
        sysPower: [],
        sysEnergy: [],
        ...normalizeInvData(data, moment(date), location.lat, location.lon),
      };
    });
}

function arrayOfEmptyObjecs(length) {
  return new Array(length).fill(null).map(() => ({}));
}

function normalizeInvData(data, date, lat, lon) {
  const { dawn, sunset } = SunCalc.getTimes(date.toDate(), lat, lon);
  return data.reduce(
    (acc, invData) => {
      const length = invData[0].invDcVoltage.length;
      const [
        invPower,
        invEnergy,
        invDcVoltage,
        invAcVoltageMin,
        invAcVoltageMax,
      ] = new Array(5).fill(null).map(() => arrayOfEmptyObjecs(length));
      invData.reduce((lastEnergyReading, datapoint) => {
        const { timestamp } = datapoint;
        const momentTime = moment(timestamp);
        invPower[0][timestamp] = datapoint.invPower;
        // energy is a sum, needs to be subtracted, returned as function accumulator
        invEnergy[0][timestamp] = datapoint.invEnergy - lastEnergyReading;
        if (
          Math.max(...datapoint.invAcVoltage) ||
          momentTime.isBetween(dawn, sunset)
        ) {
          invAcVoltageMin[0][timestamp] = Math.min(...datapoint.invAcVoltage);
          invAcVoltageMax[0][timestamp] = Math.max(...datapoint.invAcVoltage);
        }
        datapoint.invDcVoltage.forEach((value, idx) => {
          const numValue = Number(value);
          if (numValue || momentTime.isBetween(dawn, sunset)) {
            invDcVoltage[idx][timestamp] = numValue;
          }
        });
        return datapoint.invEnergy;
      }, 0);
      acc.invPower.push(...invPower);
      acc.invEnergy.push(...invEnergy);
      acc.invDcVoltage.push(...invDcVoltage);
      acc.invAcVoltageMin.push(...invAcVoltageMin);
      acc.invAcVoltageMax.push(...invAcVoltageMax);
      return acc;
    },
    {
      invPower: [],
      invEnergy: [],
      invDcVoltage: [],
      invAcVoltageMin: [],
      invAcVoltageMax: [],
    }
  );
}

// solar-edge style collation
export function collateHourlyData(data, mins = 60, keepOffset = false) {
  if (!Number.isInteger(mins) || mins <= 0 || 60 % mins !== 0) {
    mins = 60;
  }
  const midRes = {};
  Object.keys(data).forEach((timeStamp) => {
    const currentMoment = moment(timeStamp);
    currentMoment.add(mins, "minutes");
    const currentHour = currentMoment
      .subtract(currentMoment.minutes() % mins, "minutes")
      .startOf("minute")
      .toISOString(keepOffset);
    const value = Number.parseFloat(data[timeStamp]);
    if (!Number.isNaN(value)) {
      if (!(currentHour in midRes)) {
        midRes[currentHour] = [];
      }
      midRes[currentHour].push(value);
    }
  });
  return midRes;
}

// solaredge style time checking
export function isActiveCb(val) {
  const numVal = parseFloat(val);
  return !Number.isNaN(numVal) && numVal !== 0;
}

export function getLatestStartTime(data) {
  const keys = Object.keys(data);
  if (keys.length === 0) {
    return null;
  }
  keys.sort();
  let res = isActiveCb(data[keys[0]]) ? keys[0] : null;
  for (let i = keys.length - 1; i > 0; --i) {
    if (!isActiveCb(data[keys[i - 1]]) && isActiveCb(data[keys[i]])) {
      res = keys[i];
      break;
    }
  }
  return res;
}

export function getLatestStopTime(data) {
  const dataEntries = Object.entries(data).sort((log1, log2) =>
    log1[0] < log2[0] ? -1 : 1
  );
  for (let i = dataEntries.length - 1; i >= 0; --i) {
    if (isActiveCb(dataEntries[i][1])) {
      break;
    }
    dataEntries.pop();
  }
  const length = dataEntries.length;
  if (length === 0) {
    return null;
  }
  return dataEntries[length - 1][1] ? dataEntries[length - 1][0] : null;
}
