import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import DatePickerCombo from "../DatePickerCombo";

import messageGenerator from "@soltell/soltell-message-generator";

import * as sms from "../../services/Sms";
import getSenderIds from "../../services/senderIds";
import SystemsSelect from "../SystemsDropDown";

import MessageLogItem from "./MessageLogItem";

const { fixedTs } = messageGenerator;
const TRANSLATOR_VERSION = messageGenerator.version();

const MESSAGES_SELECTION = [
  {
    name: "add system",
    code: "add_sys",
    params: ["pvsys", "label", "applink"],
  },
  { name: "update system", code: "update_sys", params: ["pvsys", "label"] },
  { name: "freeze system", code: "freeze_sys", params: ["pvsys", "label"] },
  {
    name: "reactivate system",
    code: "reactivate_sys",
    params: ["pvsys", "label"],
  },
  {
    name: "end system subscription",
    code: "end_subscr_sys",
    params: ["pvsys", "label"],
  },
  { name: "install application", code: "install_app", params: ["applink"] },
  {
    name: "access permission started",
    code: "access_start_org",
    params: ["organization"],
  },
  {
    name: "access permission ended",
    code: "access_end_org",
    params: ["organization"],
  },
  {
    name: "monthly report",
    code: "monthly_report",
    params: ["previous-month", "applink"],
  },
];

const LANGS = [
  { id: "he", name: "Hebrew" },
  { id: "en", name: "English" },
  { id: "de", name: "German" },
];

const MAX_MESSAGES_BEFORE_PROMPT = 100;

const Section = styled.div`
  padding: 10px 0;
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
`;
const Label = styled.span`
  padding-bottom: 0.3em;
  min-width: 100px;
  display: inline-block;
`;

// returns a state of a clean form
const getCleanForm = () => {
  const messageParams = MESSAGES_SELECTION[0].params.reduce((acc, param) => {
    acc[param] = "";
    return acc;
  }, {});
  return {
    shouldSendSms: true,
    shouldSendEmail: false,
    messageType: sms.getMessageTypes()[0],
    recievingPhone: "",
    recievingEmail: "",
    selectedMessageIdx: 0,
    messageParams: { ...messageParams },
    selectedSysId: "",
    senderId: getSenderIds()[0],
    eventDate: moment(),
    selectedLang: LANGS[0].id,
  };
};

// constructor for object representing a message log
function MessageLog(messageId, recipient, status, body, sender, error = null) {
  this.messageId = messageId; // aws message id
  this.recipientNumber = recipient; // recipient number
  this.status = status; // log status
  this.body = body; // message body
  this.sender = sender;
  this.error = error; // error if exists
}

class AdminMessagesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messagesCounter: 0,
      sentMessages: [],
      pendingMessage: null,
      ...getCleanForm(),
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  handleMessageTypeChange = (ev) => {
    if (!this._isMounted) {
      return;
    }
    this.setState({ messageType: ev.target.value });
  };

  handleSysIdChange = (sysId) => {
    if (!this._isMounted) {
      return;
    }
    // if system has a defined name and message has that type of param, use it
    this.setState((state, props) => {
      const systemMeta = props.sysMatch[sysId];
      const label = systemMeta ? systemMeta.label : "";
      const phones = systemMeta ? systemMeta.phones : [];
      let messageParams = state.messageParams;
      if (messageParams.hasOwnProperty("pvsys")) {
        messageParams = { ...messageParams };
        messageParams.pvsys = props.systems[sysId].name || "";
      }
      if (messageParams.hasOwnProperty("label")) {
        messageParams = { ...messageParams };
        messageParams.label = label || "";
      }

      return {
        selectedSysId: sysId,
        messageParams,
        senderId: label,
        recievingPhone: phones.join(","),
      };
    });
  };

  handlePhoneChange = (ev) => {
    if (!this._isMounted) {
      return;
    }
    this.setState({ recievingPhone: ev.target.value });
  };

  handleMessageChange = (ev) => {
    if (!this._isMounted) {
      return;
    }
    const idx = ev.target.value;

    this.setState((state, props) => {
      const messageParams = MESSAGES_SELECTION[idx].params.reduce(
        (acc, param) => {
          if (state.messageParams.hasOwnProperty(param)) {
            acc[param] = state.messageParams[param];
          } else {
            acc[param] = "";
          }
          return acc;
        },
        {}
      );

      if (messageParams.hasOwnProperty("pvsys")) {
        messageParams.pvsys = props.systems[state.selectedSysId].name || "";
      }

      return { messageParams, selectedMessageIdx: idx };
    });
  };

  handleSenderIdChange = (ev) => {
    if (!this._isMounted) {
      return;
    }
    this.setState({ senderId: ev.target.value });
  };

  parseStringToList = (str) => {
    return str.split(/,|\s+/).filter((p) => p);
  };

  onSendMessage = () => {
    if (!this._isMounted) {
      return;
    }
    // confirm after set amount of sent messages to make sure no error occured
    if (this.state.messagesCounter >= MAX_MESSAGES_BEFORE_PROMPT) {
      if (
        !window.confirm(
          `sent messages exceeded ${MAX_MESSAGES_BEFORE_PROMPT}, continue?`
        )
      ) {
        return;
      } else {
        this.setState({ messagesCounter: 0 });
      }
    }
    this.setState(
      (state) => {
        const {
          messageType,
          recievingPhone,
          recievingEmail,
          senderId,
          selectedSysId,
          eventDate,
          messageParams,
          selectedMessageIdx,
          shouldSendSms,
          shouldSendEmail,
        } = state;

        // split phones by either commas or any whitespace
        const phoneList = shouldSendSms
          ? this.parseStringToList(recievingPhone)
          : [];
        const emailList = shouldSendEmail
          ? this.parseStringToList(recievingEmail)
          : [];

        return {
          pendingMessage: {
            messageType,
            phoneList,
            emailList,
            message: {
              body: this.translateMessage(state),
              params: {
                version: TRANSLATOR_VERSION,
                msg: MESSAGES_SELECTION[selectedMessageIdx].code,
                params: messageParams,
              },
            },
            senderId,
            systemId: selectedSysId,
            eventDate,
          },
          messagesCounter: state.messagesCounter + phoneList.length,
        };
      },
      () => {
        const {
          messageType,
          phoneList,
          emailList,
          message,
          senderId,
          systemId,
          eventDate,
        } = this.state.pendingMessage;
        sms
          .sendSMS(
            message,
            phoneList,
            senderId,
            messageType,
            this.state.selectedLang,
            systemId,
            eventDate,
            "closed",
            emailList
          )
          .then((res) => {
            if (!this._isMounted) {
              return;
            }
            // create logs for sent messages
            const logs = phoneList.map((recievingPhone, idx) => {
              const messageId = res[idx].MessageId;
              // no aws message id means an error, log that to console and done list
              if (!messageId) {
                console.warn(res[idx].message);
                return new MessageLog(
                  "",
                  recievingPhone,
                  "error",
                  message,
                  senderId,
                  new Error(res[idx].message)
                );
              }
              // log as done with given aws message id
              return new MessageLog(
                messageId,
                recievingPhone,
                "success",
                message,
                senderId
              );
            });
            this.setState((state) => {
              return {
                ...getCleanForm(),
                sentMessages: [...state.sentMessages, ...logs],
              };
            });
          })
          .catch((err) => {
            if (!this._isMounted) {
              return;
            }
            console.warn(err);
            this.setState(() => {
              return {
                ...getCleanForm(),
              };
            });
          })
          .finally(() => {
            if (!this._isMounted) {
              return;
            }
            this.setState({
              pendingMessage: null,
            });
          });
      }
    );
  };

  onClearForm = () => {
    if (!this._isMounted) {
      return;
    }
    this.setState({ ...getCleanForm() });
  };

  onClearSent = () => {
    if (!this._isMounted) {
      return;
    }
    this.setState({ sentMessages: [] });
  };

  onChangeEventDate = (newDate) => {
    this.setState(() => {
      if (!this._isMounted) {
        return;
      }
      return {
        eventDate: moment(newDate),
      };
    });
  };

  onChangeLang = (ev) => {
    this.setState({ selectedLang: ev.target.value });
  };

  onParamChange = (ev) => {
    const { id: param, value } = ev.target;
    this.setState((state) => {
      const messageParams = { ...state.messageParams };
      messageParams[param] = value;
      return { messageParams };
    });
  };

  renderParamsInputs = () => {
    const { selectedMessageIdx: idx, messageParams } = this.state;
    return (
      <Section>
        {MESSAGES_SELECTION[idx].params.map((paramId) => {
          return (
            <div key={paramId}>
              <Label>{paramId}</Label>
              <div>
                <input
                  id={paramId}
                  type="text"
                  value={messageParams[paramId]}
                  onChange={this.onParamChange}
                />
              </div>
            </div>
          );
        })}
      </Section>
    );
  };

  translateMessage = (state) => {
    return fixedTs[state.selectedLang](
      MESSAGES_SELECTION[state.selectedMessageIdx].code,
      state.messageParams
    );
  };

  componentDidUpdate(prevProps, prevState) {
    const fieldIds = ["organization"];

    for (const field of fieldIds) {
      if (
        // current state must contain relevant field
        this.state.messageParams.hasOwnProperty(field) &&
        // either phone string has changed
        (prevState.recievingPhone !== this.state.recievingPhone ||
          // or field was added in this update
          !prevState.messageParams.hasOwnProperty(field))
      ) {
        const orgs = this.parseStringToList(this.state.recievingPhone)
          .reduce((acc, phone) => {
            const user = this.props.usersData[phone];
            if (user && user.organization && !acc.includes(user.organization)) {
              acc.push(user.organization);
            }
            return acc;
          }, [])
          .map((orgId) => this.props.orgsData[orgId].name || "");

        // needs to contain at least 1 match
        if (orgs.length) {
          this.setState((state) => {
            const messageParams = { ...state.messageParams };
            // if orgs list has 1 match, use it, otherwise, empty the field
            messageParams[field] = orgs.length === 1 ? orgs[0] : "";
            return { messageParams };
          });
          // only update state once
          break;
        }
      }
    }
  }

  render() {
    const { pendingMessage, shouldSendSms, shouldSendEmail } = this.state;
    return (
      <div>
        <Section>
          <Label>Event date-time</Label>
          <div>
            <DatePickerCombo
              selected={this.state.eventDate}
              disable={false}
              onChange={this.onChangeEventDate}
              format={"DD/MM/YYYY, HH:mm"}
              pickerProps={{ showTimeSelect: true }}
            />
          </div>
        </Section>
        <Section>
          <Label>Send message via:</Label>
          <div>
            <input
              type="checkbox"
              checked={shouldSendSms}
              onChange={() => this.setState({ shouldSendSms: !shouldSendSms })}
            />
            <span>Sms</span>
          </div>
          <div>
            <input
              type="checkbox"
              checked={shouldSendEmail}
              onChange={() =>
                this.setState({ shouldSendEmail: !shouldSendEmail })
              }
            />
            <span>Email</span>
          </div>
        </Section>
        <Section>
          <Label>Message type</Label>
          <div>
            <select
              disabled={!shouldSendSms}
              value={this.state.messageType}
              onChange={this.handleMessageTypeChange}
            >
              {sms.getMessageTypes().map((type, idx) => (
                <option key={idx}>{type}</option>
              ))}
            </select>
          </div>
        </Section>
        <Section>
          <Label>Phone number</Label>
          <div>
            <textarea
              disabled={!shouldSendSms}
              placeholder="+1 999 999 9999"
              cols={40}
              rows={2}
              value={this.state.recievingPhone}
              onChange={this.handlePhoneChange}
            />
          </div>
        </Section>
        <Section>
          <textarea
            disabled={!shouldSendEmail}
            placeholder="mail@comes.here"
            cols={40}
            rows={1}
            value={this.state.recievingEmail}
            onChange={(ev) =>
              this.setState({ recievingEmail: ev.target.value })
            }
          />
        </Section>
        <Section>
          <Label>System ID</Label>
          <div>
            <SystemsSelect
              systems={Object.values(this.props.systems)}
              onSystemSelect={this.handleSysIdChange}
              selectedSystem={this.state.selectedSysId}
            />
          </div>
        </Section>
        <Section>
          <Label>Language</Label>
          <div>
            <select
              value={this.state.selectedLang}
              onChange={this.onChangeLang}
            >
              {LANGS.map(({ id, name }, idx) => (
                <option key={idx} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </Section>
        <Section>
          <Label>Message</Label>
          <div>
            <select
              value={this.state.selectedMessageIdx}
              onChange={this.handleMessageChange}
            >
              {MESSAGES_SELECTION.map(({ name }, idx) => (
                <option key={idx} value={idx}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </Section>
        {this.renderParamsInputs()}
        <Section>
          <Label>Sender ID</Label>
          <div>
            <select
              value={this.state.senderId}
              onChange={this.handleSenderIdChange}
            >
              {getSenderIds().map((sender, idx) => (
                <option key={idx}>{sender}</option>
              ))}
            </select>
          </div>
        </Section>
        <Section>
          <Label>Final Message</Label>
          <div>{this.translateMessage(this.state)}</div>
        </Section>
        <Section>
          <button disabled={pendingMessage} onClick={this.onSendMessage}>
            Send
          </button>
          <button onClick={this.onClearForm}>Clear</button>
        </Section>
        {pendingMessage && (
          <Section>
            <Label>Pending response</Label>
            <div style={{ color: "orange" }}>
              <i>phone:</i> '{pendingMessage.recievingPhone}', <i>status:</i>{" "}
              pending
            </div>
          </Section>
        )}
        <Section>
          <Label>Messages log</Label>
          <ul>
            {this.state.sentMessages.map((log, idx) => (
              <MessageLogItem key={idx} log={log} />
            ))}
          </ul>
          <button onClick={this.onClearSent}>Clear sent</button>
        </Section>
      </div>
    );
  }
}

export default AdminMessagesForm;
