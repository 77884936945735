import * as React from "react";

const DbItemsList = ({ items }) => (
  <div>
    {Object.keys(items).map((key) => (
      <div key={key}>{items[key].name}</div>
    ))}
  </div>
);

export default DbItemsList;
