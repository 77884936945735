import React from "react";
import PropTypes from "prop-types";
import InvRow from "./InvRow";

const InvTable = ({ invCount, funcNames, values, onChange }) => (
  <table>
    <thead>
      <tr>
        <th>Data</th>
        {[...Array(invCount).fill(0)].map((i, idx) => {
          return <th key={idx}>Inv{idx}</th>;
        })}
      </tr>
    </thead>
    <tbody>
      {funcNames.map((type, idx) => (
        <InvRow
          key={idx}
          rowIdx={idx}
          colCount={invCount}
          name={type}
          data={values[idx]}
          onChange={onChange}
        />
      ))}
    </tbody>
  </table>
);

InvTable.propTypes = {
  invCount: PropTypes.number,
  funcNames: PropTypes.array,
  values: PropTypes.array,
  onChange: PropTypes.func,
};

export default InvTable;
