import React from "react";
import StatsManualEntryComponent from "../components/StatsManualEntry";
import { sendData } from "../services/sendData";
import { pushSysstats, addFieldIfNotFalse } from "../services/backendHelpers";
import * as Spreadsheet from "../services/Spreadsheet";
import moment from "moment";
// import { fetchJsonData } from "../firebase/storage";

import LocalStorageKeys from "../localStorageKeys";

const initialState = {
  // systemsDataIsLoading: true,
  // systemsDataLoadError: null,
  systemsDataArr: null,
  isPushOngoing: false,
  pushResult: null,
  isDataReady: false,
  isPushOngoingToDB: false,
};

class StatsManualEntry extends React.Component {
  _dataReady = false;

  constructor(props) {
    super(props);

    this.state = initialState;
  }

  doPushToSheet = (sysId, dataDate, data) => {
    this.setState({
      isPushOngoing: true,
      pushResult: null,
    });

    let pushData = []
      .concat(data.dailyValues)
      .concat(...data.invValues)
      .concat(...data.sysValues);

    console.log("Pushing", pushData, "Sys", sysId);

    const sysInfo = Object.values(this.state.systemsDataArr).find(
      (sys) => sys.id === sysId
    );
    const docId = sysInfo.docId;

    Spreadsheet.pushData(dataDate, pushData, docId, sysId, "N")
      .then((result) => {
        console.log(result);
        this.setState({
          isPushOngoing: false,
          pushResult: result,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isPushOngoing: false,
          pushResult: {
            success: false,
            message: err.message,
          },
        });
      });
  };

  componentDidMount() {
    if (!this.props.systemsDataIsLoading) {
      this.processData();
    }

    // fetchJsonData("admin/systems.json")
    // .then(systemsData => {
    //   this.setState({
    //     systemsDataIsLoading: false,
    //     systemsDataArr: Object.values(systemsData),
    //   });
    // })
    // .catch(error => {
    //   this.setState({
    //     systemsDataIsLoading: false,
    //     systemsDataLoadError: error
    //   });
    // });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.systemsDataIsLoading !== this.props.systemsDataIsLoading) {
      this.processData();
    }
  }

  processData = () => {
    this.setState((state, props) => {
      const { systemsData, systemsDataIsLoading, systemsDataLoadError } = props;
      if (systemsDataIsLoading || systemsDataLoadError || !systemsData) {
        return;
      }
      return {
        systemsDataArr: Object.values(systemsData),
        isDataReady: true,
      };
    });
  };

  handlePushToDB = (sysId, dataDate, data, extra = null) => {
    this.setState({ isPushOngoingToDB: true });

    const formattedDate = moment(dataDate).format("YYYY-MM-DD");
    const createLog = moment().format();

    let pushData = {
      systemId: sysId,
      dataDate: formattedDate,
      logCreation: createLog,
      adminVersion: process.env.REACT_APP_VERSION,
      data: data,
      statusMessage: "data model 3-Manual",
      createdBy: localStorage.getItem(LocalStorageKeys.userEmail) || "unknown",
      statusCode: 0,
    };

    const statsData = {
      sys_id: sysId,
      date: formattedDate,
    };

    addFieldIfNotFalse(statsData, "comm", "" + data.daily.comm);
    addFieldIfNotFalse(statsData, "blck", "" + data.daily.blackout);
    addFieldIfNotFalse(statsData, "mcm", "" + data.daily.mcm);
    addFieldIfNotFalse(statsData, "fct", "" + data.daily.fct);
    addFieldIfNotFalse(statsData, "sys_energy", "" + data.stats.sysDayEnergy);
    addFieldIfNotFalse(statsData, "sys_acp_max", "" + data.stats.sysMaxPower);
    addFieldIfNotFalse(
      statsData,
      "sys_acp_maxshift",
      "" + data.stats.sysMorningPower
    );
    addFieldIfNotFalse(statsData, "ebase", "" + extra.ebase);

    // add insights
    if (extra && extra.insights) {
      pushData.insights = extra.insights;
    }

    let token = localStorage.getItem(LocalStorageKeys.userToken);
    let url = "/api/logs?s3=true";
    pushSysstats(statsData, token);
    sendData(pushData, url, token).then((res) => {
      this.setState({ isPushOngoingToDB: false });
    });
  };

  render() {
    if (this.props.systemsDataLoadError) {
      return (
        <div>
          Error Loading Systems Data:
          <br />
          {this.props.systemsDataLoadError.message}
        </div>
      );
    }
    if (this.props.systemsDataIsLoading || !this.state.isDataReady) {
      return <div>Loading Systems Data...</div>;
    }

    return (
      <StatsManualEntryComponent
        systems={this.state.systemsDataArr}
        handlePush={this.doPushToSheet}
        handlePushToDB={this.handlePushToDB}
        isPushOngoingToDB={this.state.isPushOngoingToDB}
        isPushOngoing={this.state.isPushOngoing}
        pushResult={this.state.pushResult}
      />
    );
  }
}

export default StatsManualEntry;
