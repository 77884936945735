import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import AuthUserContext from "../Session/AuthUserContext";
import SignOutButton from "../SignOut";
import * as routes from "../../constants/routes";
import { sendData } from "../../services/sendData";

const activeStyle = {
  color: "white",
};

const LinksList = styled.ul`
  list-style: none;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  background: lightblue;
  box-shadow: 0px 0px 10px black;
  z-index: 100;
`;

const LinksListItem = styled.li`
  margin-right: 20px;
`;

const getVersionStringAndEnv = () => {
  const { REACT_APP_VERSION, REACT_APP_ENV } = process.env;
  const isProduction = REACT_APP_ENV === "production";
  return (
    <span style={{ color: isProduction ? "black" : "red" }}>
      {`${REACT_APP_VERSION}` + (isProduction ? "" : ` - ${REACT_APP_ENV}`)}
    </span>
  );
};

class VersionData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminVersion: getVersionStringAndEnv(),
      backendVersion: "pending",
      backendLoadingData: false,
    };
  }

  componentDidMount() {
    this.getBackendVersion();
  }

  /**
   * perform a simple get request from backend
   * @param {string} url endpoint route to query
   * @returns {object} the requested data
   * @throws {Error} in case the request failed for some reason
   */
  getFromBackend = async (url) => {
    const token = await this.props.authUser.getIdToken(false);
    const res = await sendData(null, url, token, "GET");
    if (!res.ok) {
      throw new Error(res.statusText);
    } else {
      return res.data;
    }
  };

  /**
   * get reported version of backend, and update state
   */
  getBackendVersion = async () => {
    try {
      const { version } = await this.getFromBackend("/api/version");
      this.setState({ backendVersion: version });
    } catch (error) {
      console.error(error.stack);
      this.setState({ backendVersion: "error" });
    }
  };

  /**
   * send a data update signal to the backend, resolved when
   * data has started reloading, not when finished
   */
  reloadBackendData = () => {
    this.setState({ backendLoadingData: true }, async () => {
      try {
        const res = await this.getFromBackend("/api/update-systems-list");
        console.log("Backend:", res);
      } catch (error) {
        console.error(error.stack);
      } finally {
        this.setState({ backendLoadingData: false });
      }
    });
  };

  render() {
    const { adminVersion, backendVersion, backendLoadingData } = this.state;
    return (
      <table>
        <tbody>
          <tr>
            <td>Admin: {adminVersion} |&nbsp;</td>
            <td>Backend: {backendVersion}</td>
          </tr>
          <tr>
            <td>
              <button
                onClick={this.props.fetchAllData}
                disabled={this.props.isLoadingData}
              >
                {this.props.isLoadingData ? "loading..." : "reload admin"}
              </button>
            </td>
            <td>
              <button
                onClick={this.reloadBackendData}
                disabled={backendLoadingData}
              >
                {backendLoadingData ? "loading..." : "reload backend"}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

const Navigation = (props) => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? (
        <NavigationAuth authUser={authUser} {...props} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = (props) => (
  <LinksList>
    <LinksListItem>
      <NavLink
        to={`/${routes.DASHBOARD}`}
        activeStyle={activeStyle}
        exact={true}
      >
        Dashboard
      </NavLink>
    </LinksListItem>
    <LinksListItem>
      <NavLink to={`/${routes.OPERATION}`} activeStyle={activeStyle}>
        Operation
      </NavLink>
    </LinksListItem>
    <LinksListItem>
      <NavLink to={`/${routes.USERS}`} activeStyle={activeStyle}>
        Users
      </NavLink>
    </LinksListItem>
    <LinksListItem>
      <NavLink to={`/${routes.SYSTEMS}`} activeStyle={activeStyle}>
        Systems
      </NavLink>
    </LinksListItem>
    <LinksListItem>
      <NavLink to={`/${routes.ORGS}`} activeStyle={activeStyle}>
        Organizations
      </NavLink>
    </LinksListItem>
    <LinksListItem>
      <NavLink to={`/${routes.MESSAGES}`} activeStyle={activeStyle}>
        Messages
      </NavLink>
    </LinksListItem>
    <LinksListItem>
      <NavLink to={`/${routes.MIGRATIONS}`} activeStyle={activeStyle}>
        Migrations
      </NavLink>
    </LinksListItem>
    <LinksListItem>
      <NavLink to={`/${routes.INSIGHTS}`} activeStyle={activeStyle}>
        Insights
      </NavLink>
    </LinksListItem>
    <LinksListItem>
      <NavLink to={`/${routes.SYSMAP}`} activeStyle={activeStyle}>
        Sysmap-api
      </NavLink>
    </LinksListItem>
    <LinksListItem>
      <NavLink to={`/${routes.ACCOUNT}`} activeStyle={activeStyle}>
        Account
      </NavLink>
    </LinksListItem>
    <LinksListItem>
      <SignOutButton />
    </LinksListItem>
    <div style={{ position: "absolute", right: "30px" }}>
      <VersionData {...props} />
    </div>
  </LinksList>
);

const NavigationNonAuth = () => null;

export default Navigation;
