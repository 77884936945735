import React from "react";
import styled from "styled-components";
import moment from "moment";
import localStorageKeys from "../localStorageKeys";
import { sendData } from "../services/sendData";
import SystemsSelect from "../components/SystemsDropDown";
import DatePickerCombo from "../components/DatePickerCombo";

const Label = styled.span`
  min-width: 130px;
  display: inline-block;
`;

const Row = styled.div`
  margin-bottom: 1em;
`;

const List = styled.ul`
  list-style-type: none;
`;

const RightSpan = styled.span`
  float: right;
`;

const Checkbox = styled.input`
  cursor: pointer;
`;

const DATE_BACKEND_FORMAT = "YYYY-MM-DD";
const SHOW_DATE_FORMAT = "DD/MM/YYYY";

const URLS = {
  fetchQueueState: "/api/queue",
  updateBackendQueue: "/api/rush-systems",
  clearQueue: "/api/clear-all",
};

const QUEUE_STATES = [
  "completed",
  "failed",
  "active",
  "delayed",
  "waiting",
  "paused",
];

class CloudQueue extends React.Component {
  constructor(props) {
    super(props);
    const now = moment({ hour: 6 });
    this.state = {
      selectedSystem: "",
      startDate: moment(now),
      endDate: moment(now),
      fullScraper: true,
      smsAlert: false,
      emailAlert: false,
      isLoading: false,
      hideSpecialOptions: true,
      backendUpdateResponses: [],
      backendStatus: (
        <List>
          <li>Not Known</li>
        </List>
      ),
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createBackendStateElement = (backendQueue) => {
    const dataToRow = (rowName, data) => {
      return (
        <tr key={rowName}>
          <td>
            <strong>{rowName}</strong>
          </td>
          {QUEUE_STATES.map((qState) => (
            <td key={rowName + qState}>{data[qState]}</td>
          ))}
        </tr>
      );
    };

    const totalData = Object.fromEntries(
      QUEUE_STATES.map((qState) => [qState, 0])
    );

    const tableBody = Object.entries(backendQueue)
      .sort(([a], [b]) => {
        // ensure "push" is always first
        if (a === "push") {
          return -Infinity;
        } else if (b === "push") {
          return Infinity;
        } else if (a < b) {
          return 1;
        } else if (a > b) {
          return -1;
        }
        return 0;
      })
      .map(([qName, values]) => {
        QUEUE_STATES.forEach((qState) => (totalData[qState] += values[qState]));
        return dataToRow(qName, values);
      });

    return (
      <table>
        <thead>
          <tr>
            <th>queue-name |</th>
            {QUEUE_STATES.map((qState) => (
              <th key={"header" + qState}>{qState} |</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataToRow("total", totalData)}
          {tableBody}
        </tbody>
      </table>
    );
  };

  getDatesArray = () => {
    const { startDate, endDate } = this.state;
    return Array(this.diffDays(endDate, startDate) + 1)
      .fill(0)
      .map((e, idx) => {
        return moment(startDate).add(idx, "days").format(DATE_BACKEND_FORMAT);
      });
  };

  onClearQueue = () => {
    if (!window.confirm("About to clear cloud queue, are you sure?")) {
      return;
    }
    this.setState(
      (state) => {
        if (!this._isMounted || state.isLoading) {
          return;
        }
        window.alert("Clearing cloud queue");
        return { isLoading: true };
      },
      async () => {
        const token = localStorage.getItem(localStorageKeys.userToken);
        await sendData(null, URLS.clearQueue, token, "GET");
        this.setState({ isLoading: false });
      }
    );
  };

  onRushSystem = () => {
    this.setState(
      (state) => {
        if (!this._isMounted || state.isLoading) {
          return;
        }
        return { isLoading: true };
      },
      async () => {
        const reqData = {
          sysIds: [this.state.selectedSystem],
          dates: this.getDatesArray(),
          fullScraper: this.state.fullScraper,
          emailAlert: this.state.emailAlert,
          smsAlert: this.state.smsAlert,
        };
        // if message service is selected, prompt user, and allow aborting the operation
        if (reqData.emailAlert || reqData.smsAlert) {
          if (!window.confirm("send email/sms is set to true, are you sure?")) {
            return this.setState({ isLoading: false });
          }
        }
        const token = localStorage.getItem(localStorageKeys.userToken);
        const backendRes = await sendData(
          reqData,
          URLS.updateBackendQueue,
          token,
          "POST"
        );
        let response = backendRes.data;
        if (!backendRes.ok) {
          console.warn(backendRes);
          response = "ERROR: " + response;
        }
        this.setState((state) => {
          const backendUpdateResponses = [
            ...state.backendUpdateResponses,
            `${state.selectedSystem}, ${state.startDate.format(
              SHOW_DATE_FORMAT
            )} - ${state.endDate.format(SHOW_DATE_FORMAT)} : ${response}`,
          ];
          return { isLoading: false, backendUpdateResponses };
        });
      }
    );
  };

  onGetQueue = () => {
    this.setState(
      (state) => {
        if (!this._isMounted || state.isLoading) {
          return;
        }
        return { isLoading: true };
      },
      async () => {
        const token = localStorage.getItem(localStorageKeys.userToken);
        const backendQueue = await sendData(
          null,
          URLS.fetchQueueState,
          token,
          "GET"
        ).then((res) => {
          return res.data;
        });

        this.setState({
          backendStatus: this.createBackendStateElement(backendQueue),
          isLoading: false,
        });
      }
    );
  };

  onSystemSelect = (sysId) => {
    this.setState({
      selectedSystem: sysId,
    });
  };

  diffDays = (subtructed, by) => {
    return subtructed.diff(by, "days");
  };

  onStartDateSelect = (newStartDate) => {
    this.setState((state) => {
      const newState = { startDate: moment(newStartDate) };
      if (this.diffDays(state.endDate, newStartDate) < 0) {
        newState.endDate = moment(newStartDate);
      }
      return newState;
    });
  };

  onEndDateSelect = (newEndDate) => {
    this.setState((state) => ({
      endDate: moment(
        this.diffDays(newEndDate, state.startDate) < 0
          ? state.startDate
          : newEndDate
      ),
    }));
  };

  // general toggle method for boolean state fields, returns a function
  toggleStateField = (fieldName) => {
    return () => {
      this.setState((state) => ({ [fieldName]: !state[fieldName] }));
    };
  };

  render() {
    const { systemsData: systems } = this.props;
    const {
      isLoading,
      selectedSystem,
      startDate,
      endDate,
      fullScraper,
      smsAlert,
      emailAlert,
      backendStatus,
      backendUpdateResponses,
      hideSpecialOptions,
    } = this.state;

    if (!systems) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <Row>
          <Label>Select system:</Label>
          <SystemsSelect
            systems={Object.values(systems)}
            onSystemSelect={this.onSystemSelect}
            selectedSystem={selectedSystem}
            disabled={isLoading}
          />
          <RightSpan>
            <Checkbox
              type="checkbox"
              checked={!hideSpecialOptions}
              onChange={this.toggleStateField("hideSpecialOptions")}
              disabled={isLoading}
            />
            &nbsp;show hidden options
          </RightSpan>
        </Row>
        <Row>
          <Label>Select date range:&nbsp;&nbsp;</Label>
          <DatePickerCombo
            selected={startDate}
            onChange={this.onStartDateSelect}
            disable={isLoading}
          />
          &nbsp;to&nbsp;
          <DatePickerCombo
            selected={endDate}
            onChange={this.onEndDateSelect}
            disable={isLoading}
          />
        </Row>
        <Row>
          <Label>Rush options:</Label>
          <List>
            <li>
              <Checkbox
                type="checkbox"
                checked={fullScraper}
                onChange={this.toggleStateField("fullScraper")}
                disabled={isLoading}
              />
              <Label>&nbsp;Full scraper (e.g. webconnect)</Label>
            </li>
            <li hidden={hideSpecialOptions}>
              <Checkbox
                type="checkbox"
                checked={smsAlert}
                onChange={this.toggleStateField("smsAlert")}
                disabled={isLoading}
              />
              <Label>&nbsp;Alert SMS</Label>
            </li>
            <li hidden={hideSpecialOptions}>
              <Checkbox
                type="checkbox"
                checked={emailAlert}
                onChange={this.toggleStateField("emailAlert")}
                disabled={isLoading}
              />
              <Label>&nbsp;Alert Email</Label>
            </li>
          </List>
        </Row>
        <Row hidden={hideSpecialOptions}>
          <button
            onClick={this.onClearQueue}
            disabled={isLoading || hideSpecialOptions}
          >
            Clear-Queue
          </button>
        </Row>
        <Row>
          <Row>
            <button
              onClick={this.onRushSystem}
              disabled={isLoading || !selectedSystem}
            >
              Rush-Backend
            </button>
          </Row>
          <Row>
            <Label>Backend responses:</Label>
            <List>
              {backendUpdateResponses.map((res, idx) => (
                <li key={idx}>{res}</li>
              ))}
            </List>
          </Row>
        </Row>
        <Row>
          <button onClick={this.onGetQueue} disabled={isLoading}>
            get queue state
          </button>
        </Row>
        <Row>
          <Label>Last known state:</Label>
          <>{backendStatus}</>
        </Row>
      </div>
    );
  }
}

export default CloudQueue;
