import React from "react";
import withAuthorization from "../components/Session/withAuthorization";
import {
  Route,
  NavLink,
  withRouter,
  Redirect,
  Switch,
  Prompt,
} from "react-router-dom";
import StatsDashboard from "../containers/StatsDashboard";
// import RefsDashboard from "../containers/RefsDashboard";
import StatsManualEntry from "../containers/StatsManualEntry";
import AutoDashboard from "../containers/AutoDashboard";
import CloudQueue from "../containers/CloudQueue";
import styled from "styled-components";

const activeStyle = {
  color: "white",
};

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const LinksList = styled.ul`
  list-style: none;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  background: lightblue;
`;
const LinksListItem = styled.li`
  margin-right: 20px;
`;

const Content = styled.div`
  padding: 10px;
  flex: 1;
  height: 100%;
`;

// confirmation whether or not to allow tab close
function confirmTabClose(ev) {
  ev.preventDefault();
  ev.returnValue = "";
}

class Operation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      systemsData: null,
      orgs: null,
      // regionsData: null,
      // schedule: null,
      // systemsDataIsLoading: true,
      // systemsDataLoadError: null
    };
  }

  processDataFromProps = () => {
    this.setState((state, props) => {
      if (!this._isMounted) {
        return;
      }

      const { loadError, usersData: users } = props;

      if (loadError) {
        return {
          orgs: null,
          systemsData: null,
        };
      }

      const [orgs, systems] = [{ ...props.orgsData }, { ...props.systemsData }];

      Object.keys(orgs).forEach((orgId) => {
        orgs[orgId].users = [];
        orgs[orgId].systemIds.forEach((sysId) => {
          if (!systems[sysId]) {
            return;
          }
          if (!Array.isArray(systems[sysId].orgs)) {
            systems[sysId].orgs = [];
          }
          systems[sysId].orgs.push(orgId);
        });
      });

      Object.entries(users).forEach(([phone, userData]) => {
        const { organization: userOrg } = userData;
        orgs[userOrg].users.push({ phone, ...userData });
      });
      return {
        orgs,
        systemsData: systems,
      };
    });
  };

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("beforeunload", confirmTabClose);
    if (!this.props.isLoading) {
      this.processDataFromProps();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("beforeunload", confirmTabClose);
  }

  componentDidUpdate(prevProps) {
    // handle loading to idle
    if (prevProps.isLoading && !this.props.isLoading) {
      this.processDataFromProps();
    }
  }

  render() {
    const {
      match,
      location,
      regionsData,
      schedule,
      isLoading: systemsDataIsLoading,
      loadError: systemsDataLoadError,
    } = this.props;
    const pathname = location.pathname;
    const isPromptEnabled =
      pathname === `${match.path}/systems` ||
      // pathname === `${match.path}/references` ||
      pathname === `${match.path}/auto`;
    const extraPropsToChildren = {
      regionsData,
      schedule,
      systemsDataIsLoading,
      systemsDataLoadError,
    };

    return (
      <Wrapper>
        <LinksList>
          {/* <LinksListItem key="refs">
            <NavLink to={`${match.url}/references`} activeStyle={activeStyle}>
              Refs
            </NavLink>
          </LinksListItem> */}
          <LinksListItem key="sys">
            <NavLink to={`${match.url}/systems`} activeStyle={activeStyle}>
              Systems
            </NavLink>
          </LinksListItem>
          <LinksListItem key="manual">
            <NavLink to={`${match.url}/manual`} activeStyle={activeStyle}>
              Manual
            </NavLink>
          </LinksListItem>
          <LinksListItem key="auto">
            <NavLink to={`${match.url}/auto`} activeStyle={activeStyle}>
              Auto
            </NavLink>
          </LinksListItem>
          <LinksListItem key="cloud-queue">
            <NavLink to={`${match.url}/cloud-queue`} activeStyle={activeStyle}>
              Cloud
            </NavLink>
          </LinksListItem>
        </LinksList>
        <Content>
          <Switch>
            {/* <Route
              path={`${match.path}/references`}
              render={() => (
                <RefsDashboard {...this.state} {...extraPropsToChildren} />
              )}
            /> */}
            <Route
              path={`${match.path}/systems`}
              render={() => (
                <StatsDashboard {...this.state} {...extraPropsToChildren} />
              )}
            />
            <Route
              path={`${match.path}/manual`}
              render={() => (
                <StatsManualEntry {...this.state} {...extraPropsToChildren} />
              )}
            />
            <Route
              path={`${match.path}/auto`}
              render={() => (
                <AutoDashboard {...this.state} {...extraPropsToChildren} />
              )}
            />
            <Route
              path={`${match.path}/cloud-queue`}
              render={() => (
                <CloudQueue {...this.state} {...extraPropsToChildren} />
              )}
            />
            <Redirect
              from={`${match.path}`}
              to={`${match.url}/systems`}
              exact={true}
              strict={true}
            />
          </Switch>
        </Content>
        <Prompt
          when={isPromptEnabled}
          message="Leaving this tab will clear all push data log! Do you still want to leave?"
        />
      </Wrapper>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withRouter(Operation));
