import { db } from "./firebase";

// User API

export const doCreateUser = (id, username, email) =>
  db.ref(`users/${id}`).set({
    username,
    email,
  });

export const onceGetUsers = () => {
  return db.ref("users").once("value");
};
export const onceGetSystems = () => {
  return db.ref("systems").once("value");
};

export const onceGetOrgs = () => {
  return db.ref("organizations").once("value");
};

export const onceGetDB = () => {
  return db.ref().once("value");
};

// Other db APIs ...
