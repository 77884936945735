import React from "react";
import PropTypes from "prop-types";
import StyledInput from "./StyledInput";

const SystemStats = ({ funcNames, extraFuncs, values, onChange }) => (
  <table>
    <thead>
      <tr>
        {funcNames.map((name) => (
          <th key={name}>{name}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      <tr>
        {funcNames.map((name, idx) => (
          <td key={name}>
            <StyledInput
              type="text"
              name={name}
              value={values[idx]}
              onChange={(ev) => onChange(idx, ev)}
            />
          </td>
        ))}
      </tr>
    </tbody>

    {/* extra functions to be seperated from the general purpose ones */}

    <thead>
      <tr>
        {extraFuncs.map((name) => (
          <th key={name}>{name}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      <tr>
        {extraFuncs.map((name, idx) => {
          idx += funcNames.length;
          return (
            <td key={name}>
              <StyledInput
                type="text"
                name={name}
                value={values[idx]}
                onChange={(ev) => onChange(idx, ev)}
              />
            </td>
          );
        })}
      </tr>
    </tbody>
  </table>
);

SystemStats.propTypes = {
  funcNames: PropTypes.array,
  values: PropTypes.array,
  onChange: PropTypes.func,
};

export default SystemStats;
