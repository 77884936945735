import * as Stats from "../helpers/stats";
import * as DataTrans from "../helpers/dataTrans";
// import * as Units from "../helpers/units";
import moment from "moment";

import queryString from "query-string";
const API_URL = process.env.REACT_APP_API_URL;

export function getData(systemInfo, date, timeOffset) {
  const { sources } = systemInfo;
  const params = {
    date: date.format("M-D-YYYY"),
    time_offset: timeOffset,
  };
  const urlPrefix = API_URL + "getDataSma?";
  const urls = [
    sources.sysPowerEnergy.id
      ? urlPrefix +
        queryString.stringify({
          ...params,
          page_id: sources.sysPowerEnergy.id,
          page_name: "sysPowerEnergy",
        })
      : null,
    sources.invPower.id
      ? urlPrefix +
        queryString.stringify({
          ...params,
          page_id: sources.invPower.id,
          page_name: "invPower",
        })
      : null,
    sources.invDcVoltage.id
      ? urlPrefix +
        queryString.stringify({
          ...params,
          page_id: sources.invDcVoltage.id,
          page_name: "invDcVoltage",
        })
      : null,
    sources.invAcVoltage.id
      ? urlPrefix +
        queryString.stringify({
          ...params,
          page_id: sources.invAcVoltage.id,
          page_name: "invAcVoltage",
        })
      : null,
  ];

  return Promise.all(
    urls.map((url) => (url ? fetch(url, { mode: "cors" }) : null))
  )
    .then((responses) =>
      Promise.all(responses.map((res) => (res ? res.json() : null)))
    )
    .then((results) => {
      const normSysPowerEnergy = normalizeSysPowerEnergy(
        results[0],
        sources,
        date
      );
      // console.log(normSysPowerEnergy);
      const invAcVoltage = results[3]
        ? normalizeInvAcVoltage(results[3], sources, date)
        : {};
      // TODO: deal with empties
      const returnResult = {
        sysPower:
          results[0] && results[0].data && results[0].data[0]
            ? [normSysPowerEnergy.data[0]]
            : [],
        sysEnergy:
          results[0] && results[0].data && results[0].data[1]
            ? [normSysPowerEnergy.data[1]]
            : [],
        invPower: results[1]
          ? normalizeInvPower(results[1], sources, date)
          : {},
        invDcVoltage: results[2]
          ? normalizeInvDcVoltage(results[2], sources, date)
          : {},
        invAcVoltageMin: invAcVoltage,
        invAcVoltageMax: invAcVoltage,
        messageCount: null,
      };
      // console.log(returnResult);
      return Promise.resolve(returnResult);
    });
}

export function normalizeColumn(rawData, header, date, toUnit) {
  if (!rawData || Object.keys(rawData).length === 0) {
    return null;
  }
  let result = null;
  result = Stats.filterForDate(rawData, date);
  // const fromUnit = getUnitFromHeader(header);
  // result = Units.convertUnits(result, fromUnit, toUnit);
  return result;
}

// reorder and normalize
// note that we throw away the headers
export function normalizeSysPowerEnergy(rawResults, sourcesMeta, date) {
  if (rawResults.data.length < 2) {
    return {
      data: [{}, {}],
    };
  }
  const reorderedSysPowerEnergy = reorderResult(
    "sysPowerEnergy",
    rawResults,
    sourcesMeta
  );
  const normResults = {
    data: [
      normalizeColumn(reorderedSysPowerEnergy[0], [], date, "kW"),
      normalizeColumn(reorderedSysPowerEnergy[1], [], date, "kWh"),
    ],
  };
  return normResults;
}

export function normalizeSysPower(rawData, header, date) {
  const data = normalizeColumn(rawData, header, date, "kW");
  return data === null ? [] : [data];
}

export function normalizeSysEnergy(rawData, header, date) {
  const data = normalizeColumn(rawData, header, date, "kWh");
  return data === null ? [] : [data];
}

export function normalizeInvPower(rawResults, sourcesMeta, date) {
  const normData = rawResults.data.map((set, idx) =>
    normalizeColumn(set, rawResults.headers[idx], date, "kW")
  );
  const normResults = {
    ...rawResults,
    data: normData,
  };
  const reorderedResults = reorderResult("invPower", normResults, sourcesMeta);
  return reorderedResults;
}

export function normalizeInvDcVoltage(rawResults, sourcesMeta, date) {
  return reorderResult("invDcVoltage", rawResults, sourcesMeta);
}

export function normalizeInvAcVoltage(rawResults, sourcesMeta, date) {
  return reorderResult("invAcVoltage", rawResults, sourcesMeta);
}

/**
 *
 * @param {string} key data set name (EG invPower)
 * @param {object} result raw result object
 * @param {object} sources sources metadata
 */
function reorderResult(key, result, sources) {
  if (!Array.isArray(result.data) || result.data.length === 0) {
    return [];
  }
  let ordered;
  if ("order" in sources[key] && Array.isArray(sources[key].order)) {
    const orderedHeaders = DataTrans.reorderArrayTo(
      result.headers,
      sources[key].order,
      12,
      "-"
    );
    console.log("Reordered ", key, orderedHeaders);
    ordered = DataTrans.reorderArrayTo(result.data, sources[key].order, 12, {});
  } else {
    console.log("Keeping source order", key);
    ordered = result.data;
  }
  return ordered;
}

/**
 *
 * @param {string} header
 */
export function getUnitFromHeader(header) {
  const matches = header.match(/\[(\w+)\]$/);
  return matches ? matches[1] : null;
}

// sma style collation
export function collateHourlyData(data, mins = 60, keepOffset = false) {
  if (!Number.isInteger(mins) || mins <= 0 || 60 % mins !== 0) {
    mins = 60;
  }
  const midRes = {};
  Object.keys(data).forEach((timeStamp) => {
    const currentMoment = moment(timeStamp);
    if (currentMoment.minutes() % mins !== 0) {
      currentMoment.add(mins, "minutes");
    }
    // remove remainder from last mins interval, set to start of minute
    const currentHour = currentMoment
      .subtract(currentMoment.minutes() % mins, "minutes")
      .startOf("minute")
      .toISOString(keepOffset);
    const value = Number.parseFloat(data[timeStamp]);
    if (!Number.isNaN(value)) {
      if (!(currentHour in midRes)) {
        midRes[currentHour] = [];
      }
      midRes[currentHour].push(value);
    }
  });
  return midRes;
}

// sma style time checking
export function isActiveCb(val) {
  const numVal = parseFloat(val);
  return !Number.isNaN(numVal) && numVal !== 0;
}

export function getLatestStartTime(data) {
  const keys = Object.keys(data);
  if (keys.length === 0) {
    return null;
  }
  keys.sort();
  let res = isActiveCb(data[keys[0]]) ? keys[0] : null;
  for (let i = keys.length - 1; i > 0; --i) {
    if (!isActiveCb(data[keys[i - 1]]) && isActiveCb(data[keys[i]])) {
      res = keys[i - 1];
      break;
    }
  }
  return res;
}

export function getLatestStopTime(data) {
  const dataEntries = Object.entries(data).sort((log1, log2) =>
    log1[0] < log2[0] ? -1 : 1
  );
  for (let i = dataEntries.length - 1; i >= 0; --i) {
    if (isActiveCb(dataEntries[i][1])) {
      break;
    }
    dataEntries.pop();
  }
  const length = dataEntries.length;
  if (length === 0) {
    return null;
  }
  return dataEntries[length - 1][1] ? dataEntries[length - 1][0] : null;
}
