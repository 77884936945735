import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import * as SunCalc from "suncalc";

const Sunset = (props) => {
  const sunsetTime = SunCalc.getTimes(props.date, props.lat, props.lon).sunset;
  return <span>{moment(sunsetTime).format("HH:mm")}</span>;
};

Sunset.propTypes = {
  date: PropTypes.object,
  lat: PropTypes.number,
  lon: PropTypes.number,
};

export default Sunset;
