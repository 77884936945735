import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";

import messageGenerator from "@soltell/soltell-message-generator";

import SystemsSelect from "../components/SystemsDropDown";
import DatePickerCombo from "../components/DatePickerCombo";
import { sendData } from "../services/sendData";

import LocalStorageKeys from "../localStorageKeys";
import { pushSysstats } from "../services/backendHelpers";

const TLabel = styled.th`
  text-align: start;
`;

const SubmitButton = styled.button`
  margin-top: 1em;
`;

const DATE_BACKEND_FORMAT = "YYYY-MM-DD";

const EF_LOGGER_MAX_LENGTH = 255;
const ALLOWED_INVDEG_VALUES = [
  "",
  "ok",
  ...Object.keys(messageGenerator.getIssuesData()["if-invdeg-n"]),
];

const getCleanState = () => {
  return {
    selectedSysId: "",
    startDate: moment({ hour: 6 }),
    endDate: moment({ hour: 6 }),
    invdegString: "",
    invdegSend: false,
    efloggerString: "",
    efloggerSend: true,
    ebaseValue: "",
    ebaseSend: false,
  };
};

class DailyInsights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...getCleanState(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isLoading) {
      this.setState(getCleanState());
    }
  }

  getToken = () => {
    return localStorage.getItem(LocalStorageKeys.userToken);
  };

  onSystemSelect = (sysId) => {
    this.setState({ selectedSysId: sysId });
  };

  diffDays = (subtructed = this.state.endDate, by = this.state.startDate) => {
    return subtructed.diff(by, "days");
  };

  getDatesArray = () => {
    const { startDate, endDate } = this.state;
    return Array(this.diffDays(endDate, startDate) + 1)
      .fill(0)
      .map((e, idx) => {
        return moment(startDate).add(idx, "days").format(DATE_BACKEND_FORMAT);
      });
  };

  onStartDateSelect = (newStartDate) => {
    this.setState((state) => {
      const newState = { startDate: moment(newStartDate) };
      if (this.diffDays(state.endDate, newStartDate) < 0) {
        newState.endDate = moment(newStartDate);
      }
      return newState;
    });
  };

  onEndDateSelect = (newEndDate) => {
    this.setState((state) => ({
      endDate: moment(
        this.diffDays(newEndDate, state.startDate) < 0
          ? state.startDate
          : newEndDate
      ),
    }));
  };

  handleInvdegChange = (ev) => {
    ev.preventDefault();
    const newValue = ev.target.value;
    this.setState({
      invdegString: newValue,
      invdegSend: newValue.trim().length > 0,
    });
  };

  handleEfloggerChange = (ev) => {
    ev.preventDefault();
    const newValue = ev.target.value;
    this.setState({ efloggerString: newValue });
  };

  handleEbaseChange = (ev) => {
    ev.preventDefault();
    this.setState({ ebaseValue: ev.target.value });
    // const rawValue = ev.target.value;
    // const newValue = Number.parseFloat(ev.target.value);
    // if (rawValue === "") {
    //   this.setState({ ebaseValue: rawValue });
    // } else if (Number.isFinite(newValue)) {
    //   this.setState({ ebaseValue: newValue });
    // }
  };

  isAllowedInvDeg = (str) => {
    return ALLOWED_INVDEG_VALUES.includes(str);
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    const sys = this.props.systemsData[this.state.selectedSysId];
    const ef_logger = this.state.efloggerString;
    const if_invdeg = this.state.invdegString.split(",").map((e) => e.trim());
    const ebase =
      this.state.ebaseValue === ""
        ? null
        : Number.parseFloat(this.state.ebaseValue);

    const sharedPayload = {
      systemId: sys.id,
    };

    const sysstatsPayload = {
      sys_id: sys.id,
      ebase,
    };

    if (this.state.invdegSend) {
      if (if_invdeg.some((s) => !this.isAllowedInvDeg(s))) {
        window.alert("Wrong invdeg string");
        return;
      } else {
        sharedPayload.if_invdeg = if_invdeg;
      }
    }

    if (this.state.efloggerSend) {
      if (ef_logger.length > EF_LOGGER_MAX_LENGTH) {
        window.alert(
          `'ef_logger' string can't exceed ${EF_LOGGER_MAX_LENGTH} characters`
        );
        return;
      } else {
        sharedPayload.ef_logger = ef_logger || null;
      }
    }

    const urlPost = "/api/insights/daily";
    const urlPut = "/api/insight/daily";
    const existingRowStatusCode = 403;

    const dates = this.getDatesArray();
    Promise.all(
      dates.map((date) => {
        if (this.state.ebaseSend) {
          return pushSysstats({ ...sysstatsPayload, date }, this.getToken());
        }
        return true;
      })
    )
      .then(() =>
        Promise.all(
          dates.map((date) => {
            const payload = {
              ...sharedPayload,
              date,
            };
            return sendData(payload, urlPost, this.getToken(), "post");
          })
        )
      )
      .then((results) => {
        return Promise.all(
          results.map((res) => {
            // 'post' failed, try 'put'
            if (res.status === existingRowStatusCode) {
              const payload = {
                ...sharedPayload,
                date: res.data.date,
              };
              const url = `${urlPut}/${res.data.id}`;
              return sendData(payload, url, this.getToken(), "put");
            }
            return res;
          })
        );
      })
      .then((results) => {
        const badResults = results.filter((r) => !r.ok);
        if (badResults.length) {
          console.warn(badResults);
          throw new Error("some results are bad, all are printed above");
        }
        window.alert("done");
      })
      .catch((err) => {
        console.error(err);
        window.alert("error, check console with f12");
      });
  };

  handleCheckbox = (ev) => {
    this.setState({ [ev.target.name]: ev.target.checked });
  };

  render() {
    const { loadError, isLoading, systemsData } = this.props;
    const { selectedSysId, efloggerSend, invdegSend, ebaseSend, ebaseValue } =
      this.state;

    if (loadError) {
      return (
        <div>
          Error Loading Systems Data:
          <br />
          {loadError.message}
        </div>
      );
    }
    if (isLoading) {
      return <div>Loading Systems Data...</div>;
    }

    // remove refs
    const systems = Object.entries(systemsData).reduce((acc, [id, sys]) => {
      if (!sys.reference && !sys.is_reference) {
        acc[id] = sys;
      }
      return acc;
    }, {});

    return (
      <div>
        <div>
          <table>
            <thead>
              <tr>
                <TLabel>System:</TLabel>
                <TLabel>Start Date:</TLabel>
                <TLabel>End Date:</TLabel>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <SystemsSelect
                    systems={Object.values(systems)}
                    selectedSystem={selectedSysId}
                    onSystemSelect={this.onSystemSelect}
                  />
                </td>
                <td>
                  <DatePickerCombo
                    selected={this.state.startDate}
                    onChange={this.onStartDateSelect}
                  />
                </td>
                <td>
                  <DatePickerCombo
                    selected={this.state.endDate}
                    onChange={this.onEndDateSelect}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <TLabel>
                  <input
                    type="checkbox"
                    name="efloggerSend"
                    checked={efloggerSend}
                    onChange={this.handleCheckbox}
                    disabled={false}
                  />{" "}
                  ef_logger:
                </TLabel>
                <TLabel>
                  <input
                    type="checkbox"
                    name="invdegSend"
                    checked={invdegSend}
                    onChange={this.handleCheckbox}
                    disabled={true}
                  />{" "}
                  if_invdeg:
                </TLabel>
                <TLabel>
                  <input
                    type="checkbox"
                    name="ebaseSend"
                    checked={ebaseSend}
                    onChange={this.handleCheckbox}
                    disabled={false}
                  />{" "}
                  ebase:
                </TLabel>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    disabled={!efloggerSend}
                    value={this.state.efloggerString}
                    placeholder={`No longer than ${EF_LOGGER_MAX_LENGTH} characters`}
                    onChange={this.handleEfloggerChange}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={this.state.invdegString}
                    placeholder={`Can be: ${ALLOWED_INVDEG_VALUES}`}
                    onChange={this.handleInvdegChange}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    disabled={!ebaseSend}
                    value={ebaseValue}
                    placeholder={`[null]`}
                    onChange={this.handleEbaseChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <SubmitButton
          disabled={
            !selectedSysId || (!efloggerSend && !invdegSend && !ebaseSend)
          }
          onClick={this.handleSubmit}
        >
          Submit
        </SubmitButton>
      </div>
    );
  }
}

export default DailyInsights;
