import React, { Component } from "react";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import styled from "styled-components";
import * as SunCalc from "suncalc";

import messageGenerator from "@soltell/soltell-message-generator";

import Services from "../../services";
import * as Email from "../../services/Email";
import * as Sms from "../../services/Sms";
import * as Spreadsheet from "../../services/Spreadsheet";
import * as Stats from "../../helpers/stats";
import {
  calcRefComm,
  calcFct,
  calcRiso,
  calcMcm,
  calcBlackout,
  getWebboxField,
  getStoppedInv,
  getStoppedSubchannels,
} from "../../helpers/daily";
import LocalStorageKeys from "../../localStorageKeys";

import SelectionForm from "./SelectionForm";
import CurrentPromises from "./CurrentPromises";
import Queue from "./Queue";
import DoneList from "./DoneList";

import * as helpers from "../StatResults/helpers";
import { sendData } from "../../services/sendData";

const { fixedTs } = messageGenerator;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

// defaults to 10 minute
const DEFAULT_TIMER_INTERVAL = 10 * 60 * 1000;

// default date to be shared among all moments where only the time matters
const DEFAULT_DATE = { date: 1, month: 0, year: 2020 };

// shall be used as key formatter wherever applicable regarding moment objects
const DATE_FORMAT = "DD/MM/YYYY";

const SCHEDULE_TIME_FORMAT = "HH:mm";

const MAX_FETCH_PROMISES = 1; // max amount of concurrent fetching promises
const MAX_PUSH_PROMISES = 4; // max amount of allowed pushing promises

const MAX_FETCH_RETRIES = 2; // max automatic retries to fetch data before giving up
const MAX_PUSH_RETRIES = 2; // max automatic retries to push data before declaring error

// const MISSING_REFERENCE_SYS = 'no ref';

function sendEmailToUser(sysId, message, userInfo, sysName, sender) {
  const subject = `${sysName}: ${message}`;
  const body = `${sysName}: ${message}\n${moment().format(
    "DD/MM/YYYY, HH:mm"
  )}`;
  Email.sendEmail([userInfo.email], subject, body, sender).then((res) =>
    console.log(`email sent`, {
      system: sysId,
      user: userInfo.name,
      body,
      response: res,
    })
  );
}

function sendSmsToUser(
  t,
  sysId,
  message,
  userInfo,
  sysName,
  sender,
  messageType = "Promotional"
) {
  const messageBody = `${t("system")} ${sysName}: ${message}`;
  Sms.sendSMS(
    messageBody,
    userInfo.phone,
    sender,
    messageType,
    userInfo.alerts.language || DEFAULT_ALERT_LANGUAGE,
    sysId
  ).then((res) =>
    console.log(`sms sent`, {
      system: sysId,
      user: userInfo.name,
      messageBody,
      response: res,
    })
  );
}

function sendMessages(
  t,
  sysId,
  message,
  alertObj,
  sender,
  sysName,
  userInfo,
  shouldAlertObj
) {
  if (!shouldAlertObj) {
    console.warn(
      "send message procedure misconfigured, please contact dev",
      shouldAlertObj
    );
    return;
  }
  if (!alertObj || !alertObj.isActive || !alertObj.methods) {
    return;
  }

  if (alertObj.methods.email && shouldAlertObj.alertEmail) {
    sendEmailToUser(sysId, message, userInfo, sysName, sender);
  }
  if (alertObj.methods.sms && shouldAlertObj.alertSms) {
    sendSmsToUser(t, sysId, message, userInfo, sysName, sender);
  }
}

const DAILY_FUNCS = [
  "comm",
  "blackout",
  "mcm",
  "fct",
  "ovld",
  "riso",
  "shadow",
  "webbox",
];

const DEFAULT_ALERT_LANGUAGE = "he";

// returns a random index of a given start and end indices range is [min,max)
const getRandomIndex = (min, max) => {
  if (max <= min) {
    return 0;
  }
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

// initiate 'daily functions' array
const initDailyFunctions = (sys, data, stats, lat, lon, date) => {
  const sunTimes = Object.entries(
    SunCalc.getTimes(moment(date), lat, lon)
  ).reduce((acc, [timeOfDay, hour]) => {
    acc[timeOfDay] = moment(hour);
    return acc;
  }, {});

  const powerForComm =
    data.sysPower[0] ||
    Stats.convertInvPowerToSysPowerCommCalc(data.invPower)[0] ||
    {};
  const comm = calcRefComm(
    powerForComm,
    data.invPower,
    stats.sysDayEnergy,
    lat,
    lon,
    sys.inverterSizes,
    sys.sysACSize,
    sys.systemPowerFactor,
    sys.inverterPowerFactor
  ).value;
  const webbox = getWebboxField(data.messageCount);
  const initValue = comm === 3 || comm === 4 ? "" : 0;
  const daily = Object.assign(
    ...DAILY_FUNCS.map((func) => ({ [func]: initValue })),
    { comm, webbox }
  );
  if (comm !== 3 && comm !== 4) {
    daily.fct = calcFct(stats, sys, data);
    daily.mcm = calcMcm(stats, data, sys, moment(sunTimes.sunrise));
    daily.riso = calcRiso(stats, data, sys, moment(sunTimes.sunrise));
    daily.blackout = calcBlackout(data, sys);
  }
  return daily;
};

const finalizeDataForSystemPush = (job) => {
  const daily = job.data.daily;
  const stats = job.data.stats;
  const headers = DAILY_FUNCS.concat(helpers.getHeaders(12));
  const data = {
    ...daily,
    ...stats,
  };
  const payload = helpers.getDataByHeaders(data, headers);
  return payload;
};

const finalizeDataForReferencePush = (job) => {
  // adds the last fetch timestamp to push
  return [
    Array(29).fill(""),
    job.data.map((vals) => vals.concat(Array(3).fill(""))),
    job.lastChangeTimestamp.unix(),
  ].flat(2);
  // old style reference push
  // return [Array(29).fill(''), job.data.map(vals => vals.concat(Array(3).fill('')))].flat(2);
};

const getSystemsTasks = (systems) => {
  if (!systems) {
    return {};
  }
  return Object.keys(systems).reduce((acc, sysId) => {
    acc[sysId] = {};
    return acc;
  }, {});
};

function prettyWeekdaysString(weekdays) {
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  if (weekdays.length === 7) {
    return "all week";
  }
  if (weekdays.length === 6 && weekdays[5] === 5) {
    return "Sun through Fri";
  }
  return `[${weekdays.map((dayNum) => dayNames[dayNum]).join(", ")}]`;
}

function ScheduleTask(
  executionTime,
  weekdays,
  allSystems,
  systems,
  excludeSystems,
  prevDay,
  alertEmail,
  alertSms,
  isLoaded
) {
  if (allSystems) {
    systems = [];
  } else {
    excludeSystems = [];
  }
  this.executionTime = moment(executionTime).set(DEFAULT_DATE);
  this.weekdays = [
    ...new Set(
      weekdays
        .map((day) => Number(day))
        .filter((day) => Number.isInteger(day) && 0 <= day && day <= 6)
    ),
  ].sort();
  this.allSystems = Boolean(allSystems);
  this.systems = [...systems].sort();
  this.excludeSystems = [...excludeSystems].sort();
  this.prevDay = Boolean(prevDay);
  this.alertEmail = Boolean(alertEmail);
  this.alertSms = Boolean(alertSms);
  this.isLoaded = Boolean(isLoaded);

  this.string = () =>
    `${this.executionTime.format(SCHEDULE_TIME_FORMAT)},${
      this.allSystems
    },${this.systems.join()},${this.alertEmail},${
      this.prevDay
    },${this.weekdays.join()},${this.isLoaded}`;

  this.tableElement = (style = { textAlign: "center" }) => (
    <React.Fragment>
      <td style={style}>{this.executionTime.format(SCHEDULE_TIME_FORMAT)}</td>
      <td style={style}>{this.prevDay ? "V" : "X"}</td>
      <td style={style}>{this.alertEmail ? "V" : "X"}</td>
      <td style={style}>{this.alertSms ? "V" : "X"}</td>
      <td style={style}>{this.isLoaded ? "V" : "X"}</td>
      <td style={style}>{prettyWeekdaysString(this.weekdays)}</td>
      <td style={style}>
        {this.allSystems
          ? `all, excluding: [${this.excludeSystems.join(", ")}]`
          : `[${this.systems.join(", ")}]`}
      </td>
    </React.Fragment>
  );

  this.compareTime = (other) => {
    if (this.executionTime.isSame(other.executionTime)) {
      return 0;
    }
    return this.executionTime.isBefore(other.executionTime) ? -1 : 1;
  };

  this.isSameButWeekdays = (other) => {
    let sameSystems = this.systems.length === other.systems.length;
    if (sameSystems) {
      for (let idx = 0; idx < this.systems.length; ++idx) {
        if (this.systems[idx] !== other.systems[idx]) {
          sameSystems = false;
          break;
        }
      }
    }
    return Boolean(
      sameSystems &&
        this.executionTime.isSame(other.executionTime) &&
        this.allSystems === other.allSystems &&
        this.prevDay === other.prevDay &&
        this.alertEmail === other.alertEmail &&
        this.alertSms === other.alertSms
    );
  };

  this.isSame = (other) => {
    return Boolean(
      other.weekdays.length === this.weekdays.length &&
        this.weekdays.reduce((same, day, idx) => {
          return Boolean(same && other.weekdays[idx] === day);
        }, true) &&
        this.isSameButWeekdays(other)
    );
  };

  this.isBetweenInclusive = (from, to) => {
    return this.executionTime.isBetween(from, to, "[]");
  };

  this.includesWeekday = (weekday) => this.weekdays.includes(weekday);
}

const JOB_IDLE_FETCH = "waiting for fetch"; // waiting for data to be fetched
const JOB_FETCHING = "fetching"; // having an active promise fetching data
const JOB_FETCH_FAILURE = "failed fetch"; // fetch failed at least once
const JOB_IDLE_PUSH = "waiting for push"; // waiting in queue for data to be pushed
const JOB_PUSHING = "pushing"; // having an active promise pushing data
const JOB_PUSH_SUCCESS = "successful push"; // finished push with success response recieved
const JOB_PUSH_FAILURE = "failed push"; // push promise has failed at least once

/*
  constructor for Job object, should represent a single job in it's entirety
 */
function Job(
  systemId,
  date,
  alertEmail = false,
  alertSms = false,
  status = JOB_IDLE_FETCH
) {
  this.id = `${systemId}: ${date.format(DATE_FORMAT)}`;
  this.systemId = systemId; // system id
  this.date = moment(date); // date of data to poll
  this.status = status; // job status
  this.data = {}; // job data
  this.error = null; // possible error object
  this.counter = 0; // counter of current retry number for a task
  this.alertEmail = Boolean(alertEmail); // whether or not to alert connected email accounts
  this.alertSms = Boolean(alertSms);
  this.lastChangeTimestamp = moment(); // last change of the job
}

class AutoDashboard extends Component {
  static propTypes = {
    systems: PropTypes.object,
    regions: PropTypes.object,
    schedule: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.lastScheduledTimestamp = moment().set(DEFAULT_DATE); // don't move into state
    this.state = {
      // source of truth regarding existing tasks
      systemsTasks: getSystemsTasks(this.props.systems),
      scheduledHours: [], // hours to automatically do rush all within
      fetchQueue: [], // queue for Jobs awaiting for data fetch
      pushQueue: [], // queue for Jobs with data awaiting for pushing
      doneJobs: {}, // object of Jobs that were concluded successfuly
      canceledJobs: {}, // object of Jobs that have had too many errors during fetch/push tries
      fetchPromises: {}, // object of concurrently running fetch promises
      pushPromises: {}, // object of concurrently running push promises
      isPaused: false, // indicates if jobs are currently paused or not
      loadSchedule: false, // indicates if a schedule should be loaded from fetched file
      useFullScrapers: false, // indicates whether to use full version of scrapers
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.scheduleTimer = setInterval(
      () => this.scheduledExecution(),
      DEFAULT_TIMER_INTERVAL
    );
    // to ensure that view is reloaded if translations are not loaded yet
    if ("then" in fixedTs.ready) {
      fixedTs.ready.then(() => this._isMounted && this.forceUpdate());
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.scheduleTimer);
  }

  scheduledExecution() {
    const fetchDate = moment();
    const now = moment(fetchDate).set(DEFAULT_DATE);
    const weekday = fetchDate.day();
    console.log("checking scheduled tasks");
    for (const scheduledTask of this.state.scheduledHours) {
      const sameTime = scheduledTask.isBetweenInclusive(
        this.lastScheduledTimestamp,
        now
      );
      const sameWeekday = scheduledTask.includesWeekday(weekday);
      if (sameTime && sameWeekday) {
        const dataDate = scheduledTask.prevDay
          ? moment(fetchDate).subtract(1, "days")
          : moment(fetchDate);
        if (scheduledTask.allSystems) {
          console.log(
            `scheduled rush all ${dataDate.format(
              DATE_FORMAT
            )}, excluding [${scheduledTask.excludeSystems.join(", ")}]`
          );
          this.rushAll(
            [dataDate],
            scheduledTask.alertEmail,
            scheduledTask.alertSms,
            scheduledTask.excludeSystems
          );
        } else {
          console.log(
            `scheduled rush for ${dataDate.format(
              DATE_FORMAT
            )}, systems [${scheduledTask.systems.join(", ")}]`
          );
          this.updateQueue(
            [...scheduledTask.systems],
            [dataDate],
            scheduledTask.alertEmail,
            scheduledTask.alertSms
          );
        }
      }
    }
    this.lastScheduledTimestamp = now;
  }

  // indicates if the module is currently fetching/pushing data
  isBusy = () => {
    return (
      Boolean(Object.keys(this.state.pushPromises).length) ||
      Boolean(Object.keys(this.state.fetchPromises).length)
    );
  };

  rushAll = (dates, alertEmail = false, alertSms = false, exclude = []) => {
    if (!dates) {
      dates = [moment({ hour: 6 })];
    }
    // hoists references over all other systems to avoid communication ambiguity
    const hoistedRefsSystemList = Object.entries(this.props.systems)
      .filter(([id, sys]) => {
        return !exclude.includes(id);
      })
      .sort(([idA, sysA], [idB, sysB]) => {
        // both or neither are refs, keep order
        if ((sysA.refs && sysB.refs) || (!sysA.refs && !sysB.refs)) {
          return 0;
        }
        return sysA.refs ? -1 : 1;
      })
      .map(([id]) => id);
    this.updateQueue(hoistedRefsSystemList, dates, alertEmail, alertSms);
  };

  // updates shceduled hours for auto rush all
  updateSchedule = (
    executionTime,
    allSystems,
    weekdays,
    systems,
    excludeSystems,
    prevDay,
    alertMethods,
    isLoaded = false
  ) => {
    // in case not all systems requested, and no other systems are given
    if (!allSystems && (!Array.isArray(systems) || !systems.length)) {
      return;
    }
    const { alertEmail, alertSms } = alertMethods || {};
    this.setState((state) => {
      const scheduledHours = [...state.scheduledHours];
      let newTask = new ScheduleTask(
        moment(executionTime).set(DEFAULT_DATE),
        weekdays,
        allSystems,
        systems,
        excludeSystems,
        prevDay,
        alertEmail,
        alertSms,
        isLoaded
      );
      const oldMatchingTasks = scheduledHours
        .map((task, idx) => [task, idx])
        .filter(([task, idx]) => !newTask.compareTime(task));
      const sameTask = oldMatchingTasks.find(([task, idx]) =>
        task.isSameButWeekdays(newTask)
      );
      if (sameTask) {
        newTask = new ScheduleTask(
          newTask.executionTime,
          [...newTask.weekdays, ...sameTask[0].weekdays],
          allSystems,
          systems,
          excludeSystems,
          prevDay,
          alertEmail,
          alertSms,
          isLoaded
        );
        scheduledHours.splice(sameTask[1], 1);
      }
      return {
        scheduledHours: [...scheduledHours, newTask].sort((a, b) =>
          a.compareTime(b)
        ),
      };
    });
  };

  removeSchedule = (removeTask) => {
    this.setState((state) => ({
      scheduledHours: state.scheduledHours.filter(
        (task) => !task.isSame(removeTask)
      ),
    }));
  };

  removeAllSchedule = () => {
    this.setState({ scheduledHours: [] });
  };

  updateQueue = (systems, dates, alertEmail = false, alertSms = false) => {
    this.setState((state) => {
      const systemsTasks = { ...state.systemsTasks };
      const fetchQueue = [...state.fetchQueue];
      const extraState = {};
      dates.forEach((date) => {
        systems.forEach((sys) => {
          const dateKey = date.format(DATE_FORMAT);
          if (!systemsTasks[sys]) {
            systemsTasks[sys] = {};
          }
          if (systemsTasks[sys][dateKey]) {
            const oldJob = systemsTasks[sys][dateKey];
            if (state.doneJobs[oldJob.id]) {
              if (!extraState.doneJobs) {
                extraState.doneJobs = { ...state.doneJobs };
              }
              delete extraState.doneJobs[oldJob.id];
            } else if (state.canceledJobs[oldJob.id]) {
              if (!extraState.canceledJobs) {
                extraState.canceledJobs = { ...state.canceledJobs };
              }
              delete extraState.canceledJobs[oldJob.id];
            } else {
              return;
            }
            delete systemsTasks[sys][dateKey];
          }
          const newJob = new Job(sys, date, alertEmail, alertSms);
          systemsTasks[sys][dateKey] = newJob;
          fetchQueue.push(newJob);
        });
      });
      return {
        systemsTasks,
        fetchQueue,
        ...extraState,
      };
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loadSchedule !== prevState.loadSchedule) {
      if (this.state.loadSchedule) {
        this.loadScheduleFromFile();
      } else {
        this.removeLoadedSchedule();
      }
      return;
    }
    if (this.state.isPaused) {
      return;
    }
    const availableFetches =
      MAX_FETCH_PROMISES - Object.keys(this.state.fetchPromises).length;
    const availablePushes =
      MAX_PUSH_PROMISES - Object.keys(this.state.pushPromises).length;

    const shouldUpdateFetch = !!(
      availableFetches > 0 && this.state.fetchQueue.length
    );
    const shouldUpdatePush = !!(
      availablePushes > 0 && this.state.pushQueue.length
    );

    if (shouldUpdateFetch || shouldUpdatePush) {
      this.setState((state) => {
        let newState = {};
        if (shouldUpdateFetch) {
          const fetchQueue = state.fetchQueue.slice(availableFetches);
          const fetchPromises = Object.fromEntries(
            Object.entries(state.fetchPromises).concat(
              state.fetchQueue.slice(0, availableFetches).map((job) => {
                job.status = JOB_FETCHING;
                job.counter++;
                return [job.id, this.doFetch(job)];
              })
            )
          );
          newState = { fetchQueue, fetchPromises };
        }
        if (shouldUpdatePush) {
          const randIdx =
            state.pushQueue.length <= availablePushes
              ? 0
              : getRandomIndex(0, 1 + state.pushQueue.length - availablePushes);
          const pushQueue = [
            ...state.pushQueue.slice(0, randIdx),
            ...state.pushQueue.slice(randIdx + availablePushes),
          ];
          const pushPromises = Object.fromEntries(
            Object.entries(state.pushPromises).concat(
              state.pushQueue
                .slice(randIdx, randIdx + availablePushes)
                .map((job) => {
                  job.status = JOB_PUSHING;
                  job.counter++;
                  return [job.id, this.doPush(job)];
                })
            )
          );
          newState = { ...newState, pushQueue, pushPromises };
        }
        return newState;
      });
    }
  }

  loadScheduleFromFile = () => {
    const schedule = { ...this.props.schedule };
    if (!schedule) {
      console.warn("no loaded schedule found");
      return;
    }
    console.log("loading fetched schedule");
    Object.entries(schedule).forEach(([timestamp, tasks]) => {
      const timeObj = moment(timestamp, SCHEDULE_TIME_FORMAT);
      if (!timeObj.isValid()) {
        return;
      }
      tasks.forEach((task) => {
        if (!task.isActive) {
          return;
        }
        const systems = Array.isArray(task.includeSystems)
          ? task.includeSystems.filter((sysId) =>
              this.props.systems.hasOwnProperty(sysId)
            )
          : [];
        const exclude = Array.isArray(task.excludeSystems)
          ? task.excludeSystems.filter((sysId) =>
              this.props.systems.hasOwnProperty(sysId)
            )
          : [];
        this.updateSchedule(
          timeObj,
          task.all,
          task.weekdays,
          systems,
          exclude,
          task.previousDay,
          task.alertUser,
          true
        );
      });
    });
  };

  removeLoadedSchedule = () => {
    this.setState((state) => {
      console.log("removing loaded schedule");
      return {
        scheduledHours: state.scheduledHours.filter((task) => !task.isLoaded),
      };
    });
  };

  retryJob = (jobId) => {
    const job = this.state.canceledJobs[jobId]
      ? this.state.canceledJobs[jobId]
      : this.state.doneJobs[jobId];
    if (job) {
      this.updateQueue([job.systemId], [job.date]);
    }
  };

  retryAll = () => {
    Object.keys(this.state.canceledJobs).forEach((jobId) =>
      this.retryJob(jobId)
    );
  };

  // fetch promise for reference systems list
  fetchRefs = (region, date) => {
    const sunTimes = Object.entries(
      SunCalc.getTimes(moment(date), region.location.lat, region.location.lon)
    ).reduce((acc, [timeOfDay, hour]) => {
      acc[timeOfDay] = moment(hour);
      return acc;
    }, {});

    return Promise.all(
      region.refs.map(async (ref) => {
        if (!ref.sources.sysPowerEnergy.id) {
          return ["", "", "", ""];
        }
        const data = await Services[ref.inverter_make].getData(
          ref,
          date,
          date.utcOffset()
        );

        const stats = Stats.getStatistics(
          data.sysPower,
          data.sysEnergy,
          data.invPower,
          data.invDcVoltage,
          data.invAcVoltageMin,
          data.invAcVoltageMax,
          data.invEnergy,
          data.chanDcCurrent,
          data.chanDcPowerInput,
          Services[ref.inverter_make],
          ref,
          sunTimes
        );

        const comm = calcRefComm(
          data.sysPower[0],
          data.invPower,
          stats.sysDayEnergy,
          region.location.lat,
          region.location.lon,
          ref.inverterSizes,
          ref.sysACSize
        ).value;

        return [
          comm,
          stats.sysDayEnergy || "",
          stats.sysMaxPower || "",
          stats.sysMorningPower || "",
        ];
      })
    );
  };

  // fetch promise for a non-reference systems
  fetchSystem = (system, date, alertEmail, alertSms) => {
    return Services[system.inverter_make]
      .getData(system, date, date.utcOffset(), this.state.useFullScrapers)
      .then((rawData) => {
        const location = system.location
          ? system.location
          : this.props.regions[system.region].location;

        const sunTimes = Object.entries(
          SunCalc.getTimes(moment(date), location.lat, location.lon)
        ).reduce((acc, [timeOfDay, hour]) => {
          acc[timeOfDay] = moment(hour);
          return acc;
        }, {});

        const stats = Stats.getStatistics(
          rawData.sysPower,
          rawData.sysEnergy,
          rawData.invPower,
          rawData.invDcVoltage,
          rawData.invAcVoltageMin,
          rawData.invAcVoltageMax,
          rawData.invEnergy,
          rawData.chanDcCurrent,
          rawData.chanDcPowerInput,
          Services[system.inverter_make],
          system,
          sunTimes
        );
        const daily = initDailyFunctions(
          system,
          rawData,
          stats,
          location.lat,
          location.lon,
          date
        );
        // alert message sending starts here
        let emailSent = false;
        const now = moment();
        // new messages procedure
        const commStopAlert = daily.comm === 2 || daily.comm === 3;
        let invStopAlert = "",
          subchannelStopAlert = "";

        if ([0, 1].includes(daily.comm)) {
          const stoppedInv = getStoppedInv(
            rawData.invPower,
            location.lat,
            location.lon,
            Services[system.inverter_make].collateHourlyData
          );
          const stoppedSubchannels = getStoppedSubchannels(
            rawData.subchannelDcCurrent,
            moment(date),
            location.lat,
            location.lon,
            Services[system.inverter_make].collateHourlyData
          );

          // string containing names of stopped inverters
          invStopAlert = stoppedInv
            .reduce((msg, didStop, idx) => {
              const invNames = system.inverters;
              if (didStop) {
                if (Array.isArray(invNames) && idx < invNames.length) {
                  msg.push(invNames[idx]);
                } else {
                  console.error(
                    `'${system.id}': system inverters misconfigured`,
                    system
                  );
                }
              }
              return msg;
            }, [])
            .join("; ");

          // string containing names of stopped subchannels
          subchannelStopAlert = stoppedSubchannels
            .reduce((acc, subchannels, idx) => {
              // create an error only if inverter is not stopped
              if (!stoppedInv[idx]) {
                for (let subIdx = 0; subIdx < subchannels.length; ++subIdx) {
                  if (subchannels[subIdx]) {
                    if (Array.isArray(system.inverterMapping)) {
                      // a very convoluted way to extrapolate the index of the inverter due to
                      // the fact that we used to store only channels, and now need to change
                      let invIdx,
                        startIdx,
                        endIdx = 0;
                      for (
                        invIdx = 0;
                        invIdx < system.inverterMapping.length;
                        ++invIdx
                      ) {
                        startIdx = endIdx;
                        endIdx +=
                          system.inverterMapping[invIdx].channels.length;
                        if (startIdx <= idx && idx < endIdx) {
                          break;
                        }
                      }
                      acc.push(
                        system.inverterMapping[invIdx].channels[idx]
                          .subchannels[subIdx].name
                      );
                    } else {
                      acc.push(`${system.inverters[idx]}-subchannel ${subIdx}`);
                    }
                  }
                }
              }
              return acc;
            }, [])
            .join("; ");
        }

        const orgs = system.orgs;
        const shouldSend =
          !system.frozen &&
          (alertEmail || alertSms) &&
          (commStopAlert || invStopAlert || subchannelStopAlert) &&
          now.isSame(date, "day") &&
          Array.isArray(orgs);
        if (shouldSend) {
          // retrieve all users for this system
          const users = orgs.reduce((userAcc, orgId) => {
            const org = this.props.orgs[orgId];
            if (org && Array.isArray(org.users)) {
              userAcc.push(...org.users);
            }
            return userAcc;
          }, []);
          // iterate over users, and send appropriate messages
          users.forEach((userInfo) => {
            if (!userInfo || !userInfo.alerts) {
              return;
            }
            const { label, alerts } = userInfo;
            if (
              (commStopAlert &&
                alerts.toSend.commStopAlert &&
                alerts.toSend.commStopAlert.isActive) ||
              (invStopAlert &&
                alerts.toSend.invStopAlert &&
                alerts.toSend.invStopAlert.isActive) ||
              (subchannelStopAlert &&
                alerts.toSend.subchannelStopAlert &&
                alerts.toSend.subchannelStopAlert.isActive)
            ) {
              const t =
                fixedTs[alerts.language] ||
                fixedTs[DEFAULT_ALERT_LANGUAGE] ||
                ((msg) => msg);
              const sender = label[0];
              const sysName = system.name || system.id;
              if (invStopAlert) {
                const msg = t("msg_if-powdeg-n_critical", { n: invStopAlert });
                sendMessages(
                  t,
                  system.id,
                  msg,
                  alerts.toSend.invStopAlert,
                  sender,
                  sysName,
                  userInfo,
                  { alertEmail, alertSms }
                );
              }
              if (commStopAlert) {
                const msg = t(
                  daily.comm === 2 ? "msg_f-comm_timeout" : "msg_f-comm_bad"
                );
                sendMessages(
                  t,
                  system.id,
                  msg,
                  alerts.toSend.commStopAlert,
                  sender,
                  sysName,
                  userInfo,
                  { alertEmail, alertSms }
                );
              }
              if (subchannelStopAlert) {
                const msg = t("msg_if-invdeg-n_fail", {
                  n: subchannelStopAlert,
                });
                sendMessages(
                  t,
                  system.id,
                  msg,
                  alerts.toSend.subchannelStopAlert,
                  sender,
                  sysName,
                  userInfo,
                  { alertEmail, alertSms }
                );
              }
            }
          });
        }
        const results = { rawData, stats, daily, emailSent };
        return results;
      });
  };

  doFetch = (job) => {
    const sysId = job.systemId;
    const date = job.date;
    const system = this.props.systems[sysId];
    return (
      system.refs
        ? this.fetchRefs(system, date)
        : this.fetchSystem(system, date, job.alertEmail, job.alertSms)
    )
      .then((results) => {
        if (!this._isMounted) {
          return null;
        }
        if (results.emailSent) {
          job.alertEmail = false;
        }
        this.setState((state) => {
          job.lastChangeTimestamp = moment();
          job.data = results;
          job.counter = 0;
          job.status = JOB_IDLE_PUSH;
          job.error = null;
          const pushQueue = [...state.pushQueue, job];
          const fetchPromises = { ...state.fetchPromises };
          delete fetchPromises[job.id];
          return {
            pushQueue,
            fetchPromises,
          };
        });
        return job;
      })
      .catch((error) => {
        if (!this._isMounted) {
          return null;
        }
        console.warn(error);
        this.setState((state) => {
          job.lastChangeTimestamp = moment();
          job.error = error;
          const fetchPromises = { ...state.fetchPromises };
          delete fetchPromises[job.id];
          if (job.counter >= MAX_FETCH_RETRIES) {
            job.status = JOB_FETCH_FAILURE;
            const canceledJobs = { ...state.canceledJobs, [job.id]: job };
            return {
              canceledJobs,
              fetchPromises,
            };
          }
          job.status = JOB_IDLE_FETCH;
          const fetchQueue = [...state.fetchQueue, job];
          return {
            fetchQueue,
            fetchPromises,
          };
        });
        return job;
      });
  };

  doPush = (job) => {
    const sysId = job.systemId;
    const date = job.date;
    const system = this.props.systems[sysId];
    const isReference = !!system.refs;
    const payload = (
      isReference ? finalizeDataForReferencePush : finalizeDataForSystemPush
    )(job);
    console.log(`pushing data for ${job.id}`, payload);
    return Promise.all(
      !isReference
        ? [Spreadsheet.pushData(date, payload, system.docId, sysId, "N")]
        : system.docId.map((docId) =>
            Spreadsheet.pushData(date, payload, docId, "Summary", "B")
          )
    )
      .then((responses) => {
        if (!this._isMounted) {
          return;
        }
        const errorMessage = responses.reduce((acc, res, idx) => {
          return acc + (res.success ? "" : `${idx}: ${res.message}; `);
        }, "");
        this.handlePushToDB(
          date,
          job.data,
          system.docId,
          sysId,
          isReference,
          errorMessage ? 1 : 0
        );
        if (errorMessage) {
          throw new Error(errorMessage);
        }
        this.setState((state) => {
          job.lastChangeTimestamp = moment();
          job.status = JOB_PUSH_SUCCESS;
          job.error = null;
          job.counter = 0;
          const doneJobs = { ...state.doneJobs, [job.id]: job };
          const pushPromises = { ...state.pushPromises };
          delete pushPromises[job.id];
          return {
            pushPromises,
            doneJobs,
          };
        });
        return job;
      })
      .catch((error) => {
        if (!this._isMounted) {
          return;
        }
        console.warn(error);
        this.setState((state) => {
          job.lastChangeTimestamp = moment();
          job.error = error;
          const pushPromises = { ...state.pushPromises };
          delete pushPromises[job.id];
          if (job.counter >= MAX_PUSH_RETRIES) {
            job.status = JOB_PUSH_FAILURE;
            const canceledJobs = { ...state.canceledJobs, [job.id]: job };
            return {
              canceledJobs,
              pushPromises,
            };
          }
          job.status = JOB_IDLE_PUSH;
          const pushQueue = [...state.pushQueue, job];
          return {
            pushQueue,
            pushPromises,
          };
        });
        return job;
      });
  };

  handlePushToDB = (date, data, docId, sysId, isReference, statusCode) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const createLog = moment().format();
    // const system = this.props.systems[sysId];

    const createPushData = (
      data,
      insights = null,
      allowEmptyInsight = false
    ) => {
      let extra = {};
      if (
        insights &&
        typeof insights === "object" &&
        Object.keys(insights).length
      ) {
        extra.insights = Object.fromEntries(
          Object.entries(insights).filter(([key, val]) => {
            const isNumber = Number.isFinite(Number.parseFloat(val));
            // if falsey values are not allowed, all empty values will be filtered
            return allowEmptyInsight
              ? [key, val]
              : (val || isNumber) && [key, val];
          })
        );
      }
      return {
        dataDate: formattedDate,
        logCreation: createLog,
        data: data,
        adminVersion: process.env.REACT_APP_VERSION,
        createdBy:
          localStorage.getItem(LocalStorageKeys.userEmail) || "unknown",
        statusMessage: "data model 3-Auto",
        statusCode: statusCode,
        systemId: sysId,
        ...extra,
      };
    };
    let token = localStorage.getItem(LocalStorageKeys.userToken);
    let url = "/api/logs";
    let urlRaw = "/api/raw-logs";
    if (!isReference) {
      // fix to Kw if needed
      const systemPowerFactor = this.props.systems[sysId].systemPowerFactor;
      const daily_sys_power =
        data.stats.dailySysPower &&
        (!systemPowerFactor || +systemPowerFactor === 1
          ? data.stats.dailySysPower
          : data.stats.dailySysPower.map((hourPower) => {
              return +hourPower * +systemPowerFactor;
            }));
      const insights = this.props.systems[sysId].frozen
        ? {}
        : {
            denoutput: data.stats.sysDayEnergy,
            daily_sys_power,
          };
      sendData(createPushData(data.rawData), urlRaw, token);
      sendData(
        createPushData(
          { stats: { ...data.stats }, daily: { ...data.daily } },
          insights
        ),
        url,
        token
      );
    } else {
      sendData(createPushData({ daily: [...data] }), url, token);
    }
  };

  // toggles pause/play state of the dashboard
  handleTogglePause = () => {
    this.setState((state) => ({ isPaused: !state.isPaused }));
  };

  // clears both done AND canceled jobs
  clearDoneJobs = () => {
    this.setState((state) => {
      const systemsTasks = { ...state.systemsTasks };
      Object.values(state.doneJobs).forEach((job) => {
        delete systemsTasks[job.systemId][job.date.format(DATE_FORMAT)];
      });
      Object.values(state.canceledJobs).forEach((job) => {
        delete systemsTasks[job.systemId][job.date.format(DATE_FORMAT)];
      });
      return {
        systemsTasks,
        doneJobs: {},
        canceledJobs: {},
      };
    });
  };

  renderPlayPause = () => {
    return (
      <button onClick={this.handleTogglePause}>
        {this.state.isPaused ? "▶ resume" : "⏸ pause"}
      </button>
    );
  };

  toggleLoadSchedule = () => {
    this.setState((state) => ({ loadSchedule: !state.loadSchedule }));
  };

  toggleUseFullScrapers = () => {
    this.setState((state) => ({ useFullScrapers: !state.useFullScrapers }));
  };

  render() {
    if (this.props.systems === null || this.props.systems.length === 0) {
      return <div>No systems found :(</div>;
    }
    return (
      <Container>
        <SelectionForm
          systems={this.props.systems}
          scheduledHours={this.state.scheduledHours}
          updateQueue={this.updateQueue}
          updateSchedule={this.updateSchedule}
          removeSchedule={this.removeSchedule}
          removeAllSchedule={this.removeAllSchedule}
          onClear={this.clearDoneJobs}
          retryAll={this.retryAll}
          rushAll={this.rushAll}
          isBusy={this.isBusy}
          loadSchedule={this.state.loadSchedule}
          toggleLoadSchedule={this.toggleLoadSchedule}
        />
        <div style={{ marginTop: "1em" }}>
          <input
            type="checkbox"
            disabled={this.isBusy()}
            checked={this.state.useFullScrapers}
            onChange={this.toggleUseFullScrapers}
          />
          <span>Use full scraper versions (e.g. webconnect)</span>
        </div>
        <div style={{ marginTop: "2em" }}>{this.renderPlayPause()}</div>
        {
          // shows an alert only if paused
          this.state.isPaused && (
            <div style={{ marginTop: "2em" }}>
              <strong style={{ color: "red" }}>queues paused</strong>
            </div>
          )
        }
        <CurrentPromises
          fetchPromises={this.state.fetchPromises}
          pushPromises={this.state.pushPromises}
        />
        <Queue title="Fetch" queue={this.state.fetchQueue} />
        <Queue title="Random - Push" queue={this.state.pushQueue} />
        <DoneList
          doneJobs={this.state.doneJobs}
          canceledJobs={this.state.canceledJobs}
          retryJob={this.retryJob}
        />
      </Container>
    );
  }
}

export default AutoDashboard;
