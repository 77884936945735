import React from "react";
import styled from "styled-components";

const Field = styled.div`
  margin-bottom: 1em;
`;

const FieldName = styled.b`
  margin-${(props) => (props.leftMargin ? "left" : "right")}: 1em;
`;

function getCleanState() {
  return {
    id: "",
    name: "",
    make: "",
    date_installed: "",
    parent_id: "",
    power: "",
    frozen: false,
    inverters: [],
  };
}

class AddSystem extends React.Component {
  constructor(props) {
    super(props);

    this.state = getCleanState();
  }

  createSystemString() {
    const {
      id,
      name,
      make,
      date_installed,
      parent_id,
      power,
      frozen,
      inverters,
    } = this.state;
    const sysObject = {
      id,
      name,
      make,
      date_installed,
      power: Number.parseFloat(power) || 0,
    };
    if (parent_id) {
      sysObject.parent_id = parent_id;
    }
    if (frozen) {
      sysObject.frozen = !!frozen;
    }
    if (inverters.length) {
      sysObject.subsystems = [];
      sysObject.inverters = [];
      inverters.forEach(({ id, name: invName, disabled, channels }) => {
        const invData = { id, name: invName, disabled };
        if (channels.length) {
          invData.channels = channels.map(
            ({ id, name: chanName, disabled, subchannels }, chanIdx) => {
              sysObject.subsystems.push(`${invName}-${chanIdx + 1}`);
              const chan = { id, name: chanName, disabled };
              if (subchannels.length) {
                chan.subchannels = subchannels.map(
                  ({ id, name: subchanName, disabled }) => {
                    return { id, name: subchanName, disabled };
                  }
                );
              }
              return chan;
            }
          );
        }
        sysObject.inverters.push(invData);
      });
    }
    return `"${id}": ${JSON.stringify(sysObject, null, 2)}`;
  }

  addInverter = () => {
    this.setState((state) => {
      const inverters = [...state.inverters];
      inverters.push({ id: "", name: "", disabled: false, channels: [] });
      return { inverters };
    });
  };

  removeInverter = (idx) => {
    this.setState((state) => {
      const inverters = state.inverters.filter(
        (inv, currIdx) => currIdx !== idx
      );
      return { inverters };
    });
  };

  removeChannel = (invIdx, chanIdx) => {
    this.setState((state) => {
      const inverters = [...state.inverters];
      inverters[invIdx].channels = inverters[invIdx].channels.filter(
        (chan, currIdx) => currIdx !== chanIdx
      );
      return { inverters };
    });
  };

  removeSubchannel = (invIdx, chanIdx, subIdx) => {
    this.setState((state) => {
      const inverters = [...state.inverters];
      inverters[invIdx].channels[chanIdx].subchannels = inverters[
        invIdx
      ].channels[chanIdx].subchannels.filter(
        (sub, currIdx) => currIdx !== subIdx
      );
      return { inverters };
    });
  };

  addChannel = (idx) => {
    this.setState((state) => {
      const inverters = [...state.inverters];
      inverters[idx].channels.push({
        id: "",
        name: "",
        disabled: false,
        subchannels: [],
      });
      return { inverters };
    });
  };

  addSubchannel = (invIdx, chanIdx) => {
    this.setState((state) => {
      const inverters = [...state.inverters];
      inverters[invIdx].channels[chanIdx].subchannels.push({
        id: "",
        name: "",
        disabled: false,
      });
      return { inverters };
    });
  };

  onChangeInv = (idx, prop, value) => {
    this.setState((state) => {
      const inverters = [...state.inverters];
      inverters[idx][prop] = value;
      return { inverters };
    });
  };

  onChangeChan = (invIdx, chanIdx, prop, value) => {
    this.setState((state) => {
      const inverters = [...state.inverters];
      inverters[invIdx].channels[chanIdx][prop] = value;
      return { inverters };
    });
  };

  onChangeSub = (invIdx, chanIdx, subIdx, prop, value) => {
    this.setState((state) => {
      const inverters = [...state.inverters];
      inverters[invIdx].channels[chanIdx].subchannels[subIdx][prop] = value;
      return { inverters };
    });
  };

  render() {
    const systemString = this.createSystemString();
    return (
      <div>
        <h1>Add System</h1>

        <Field>
          <input
            type="checkbox"
            checked={this.state.frozen}
            onChange={(e) => this.setState({ frozen: e.target.checked })}
          />
          <FieldName leftMargin>Frozen</FieldName>
        </Field>

        <Field>
          <FieldName>System id:</FieldName>
          <input
            type="text"
            value={this.state.id}
            onChange={(e) => this.setState({ id: e.target.value })}
          />
        </Field>

        <Field>
          <FieldName>System name:</FieldName>
          <input
            type="text"
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </Field>

        <Field>
          <FieldName>System make:</FieldName>
          <input
            type="text"
            value={this.state.make}
            onChange={(e) => this.setState({ make: e.target.value })}
          />
        </Field>

        <Field>
          <FieldName>Date installed:</FieldName>
          <input
            type="text"
            value={this.state.date_installed}
            onChange={(e) => this.setState({ date_installed: e.target.value })}
          />
        </Field>

        <Field>
          <FieldName>Parent id:</FieldName>
          <input
            type="text"
            value={this.state.parent_id}
            onChange={(e) => this.setState({ parent_id: e.target.value })}
          />
        </Field>

        <Field>
          <FieldName>System power:</FieldName>
          <input
            type="number"
            value={this.state.power}
            onChange={(e) => this.setState({ power: e.target.value })}
          />
        </Field>

        <Field>
          <FieldName>Inverters:</FieldName>
          <button onClick={this.addInverter}>Add inverter</button>
          <ol>
            {this.state.inverters.map((inv, idx) => {
              return (
                <li key={idx}>
                  <Field>
                    <button onClick={() => this.removeInverter(idx)}>
                      Remove inverver
                    </button>
                  </Field>

                  <Field>
                    <Field>
                      <FieldName>inv id:</FieldName>
                      <input
                        type="text"
                        value={inv.id}
                        onChange={(e) =>
                          this.onChangeInv(idx, "id", e.target.value)
                        }
                      />
                    </Field>

                    <Field>
                      <FieldName>inv name:</FieldName>
                      <input
                        type="text"
                        value={inv.name}
                        onChange={(e) =>
                          this.onChangeInv(idx, "name", e.target.value)
                        }
                      />
                    </Field>

                    <Field>
                      <FieldName>inv disabled:</FieldName>
                      <input
                        type="checkbox"
                        checked={inv.disabled}
                        onChange={(e) =>
                          this.onChangeInv(idx, "disabled", e.target.checked)
                        }
                      />
                    </Field>

                    <Field>
                      <Field>
                        <FieldName>inv channels:</FieldName>
                        <button onClick={() => this.addChannel(idx)}>
                          Add channel
                        </button>
                      </Field>
                      <ol>
                        {inv.channels.map((chan, chanIdx) => {
                          return (
                            <li key={chanIdx}>
                              <Field>
                                <button
                                  onClick={() =>
                                    this.removeChannel(idx, chanIdx)
                                  }
                                >
                                  Remove channal
                                </button>
                              </Field>

                              <Field>
                                <FieldName>chan id:</FieldName>
                                <input
                                  type="text"
                                  value={chan.id}
                                  onChange={(e) =>
                                    this.onChangeChan(
                                      idx,
                                      chanIdx,
                                      "id",
                                      e.target.value
                                    )
                                  }
                                />
                              </Field>
                              <Field>
                                <FieldName>chan name:</FieldName>
                                <input
                                  type="text"
                                  value={chan.name}
                                  onChange={(e) =>
                                    this.onChangeChan(
                                      idx,
                                      chanIdx,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                />
                              </Field>
                              <Field>
                                <FieldName>chan disabled:</FieldName>
                                <input
                                  type="checkbox"
                                  value={chan.disabled}
                                  onChange={(e) =>
                                    this.onChangeChan(
                                      idx,
                                      chanIdx,
                                      "disabled",
                                      e.target.checked
                                    )
                                  }
                                />
                              </Field>
                              <Field>
                                <Field>
                                  <FieldName>subchannels:</FieldName>
                                  <button
                                    onClick={() =>
                                      this.addSubchannel(idx, chanIdx)
                                    }
                                  >
                                    Add subchannel
                                  </button>
                                </Field>
                                <ol>
                                  {chan.subchannels.map((sub, subIdx) => {
                                    return (
                                      <li key={subIdx}>
                                        <Field>
                                          <button
                                            onClick={() =>
                                              this.removeSubchannel(
                                                idx,
                                                chanIdx,
                                                subIdx
                                              )
                                            }
                                          >
                                            Remove subchannel
                                          </button>
                                          <div>
                                            <FieldName>sub id:</FieldName>
                                            <input
                                              type="text"
                                              value={sub.id}
                                              onChange={(e) =>
                                                this.onChangeSub(
                                                  idx,
                                                  chanIdx,
                                                  subIdx,
                                                  "id",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>

                                          <div>
                                            <FieldName>sub name:</FieldName>
                                            <input
                                              type="text"
                                              value={sub.name}
                                              onChange={(e) =>
                                                this.onChangeSub(
                                                  idx,
                                                  chanIdx,
                                                  subIdx,
                                                  "name",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>

                                          <div>
                                            <FieldName>sub disabled:</FieldName>
                                            <input
                                              type="checkbox"
                                              value={sub.disabled}
                                              onChange={(e) =>
                                                this.onChangeSub(
                                                  idx,
                                                  chanIdx,
                                                  subIdx,
                                                  "disabled",
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </div>
                                        </Field>
                                      </li>
                                    );
                                  })}
                                </ol>
                              </Field>
                            </li>
                          );
                        })}
                      </ol>
                    </Field>
                  </Field>
                </li>
              );
            })}
          </ol>
        </Field>

        <div>
          <Field>
            <FieldName>Result:</FieldName>
            <button onClick={() => navigator.clipboard.writeText(systemString)}>
              Copy
            </button>
          </Field>
          <textarea value={systemString} readOnly={true} rows={30} cols={50} />
        </div>
        <button onClick={() => this.setState({ ...getCleanState() })}>
          Clear
        </button>
      </div>
    );
  }
}

export default AddSystem;
