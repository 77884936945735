import * as React from "react";
import PropTypes from "prop-types";
import Content from "./Content";
// import * as helpers from './helpers';
// import * as Spreadsheet from '../../services/Spreadsheet';

// const initialState = {
//   pushResult: null,
//   pushQueries: [],
//   currentPushPosition: 0
// }

class StatResults extends React.Component {
  headers;

  static propTypes = {
    data: PropTypes.object,
    daily: PropTypes.shape({
      comm: PropTypes.number,
      fct: PropTypes.any,
      webbox: PropTypes.string,
    }),
    results: PropTypes.object,
    sysId: PropTypes.string,
    dataSys: PropTypes.object,
    sysDocId: PropTypes.string,
    dataDate: PropTypes.object, //moment
  };

  render() {
    return (
      <Content
        dataSys={this.props.dataSys}
        sysId={this.props.dataSys.id}
        sysDocId={this.props.sysDocId}
        dataDate={this.props.dataDate}
        data={this.props.data}
        daily={this.props.daily}
        canPush={!!this.props.sysDocId}
        doPushToSheet={this.props.doPushToSheet}
        results={this.props.results}
      />
    );
  }
}

export default StatResults;
