import * as Stats from "../helpers/stats";
import * as DataTrans from "../helpers/dataTrans";
// import * as Units from "../helpers/units";
import moment from "moment";

import queryString from "query-string";
const API_URL = process.env.REACT_APP_API_URL;

export function getData(systemInfo, date, timeOffset) {
  const { sources, time_offset_correction } = systemInfo;
  const params = {
    date: date.format("M-D-YYYY"),
    time_offset: timeOffset,
  };
  const urlPrefix = API_URL + "getDataSma?";
  const urls = [
    urlPrefix +
      queryString.stringify({
        ...params,
        page_id: sources.sysPowerEnergy.id,
        page_name: "sysPowerEnergy",
      }),
    urlPrefix +
      queryString.stringify({
        ...params,
        page_id: sources.invPower.id,
        page_name: "invPower",
      }),
    urlPrefix +
      queryString.stringify({
        ...params,
        page_id: sources.invDcVoltage.id,
        page_name: "invDcVoltage",
      }),
    urlPrefix +
      queryString.stringify({
        ...params,
        page_id: sources.invAcVoltage.id,
        page_name: "invAcVoltage",
      }),
    urlPrefix +
      queryString.stringify({
        ...params,
        page_id: sources.invEnergy.id,
        page_name: "invEnergy",
      }),
  ];

  return Promise.all(urls.map((url) => fetch(url, { mode: "cors" })))
    .then((responses) => Promise.all(responses.map((res) => res.json())))
    .then((results) => {
      const invAcVoltage = normalizeInvAcVoltage(results[3], sources, date);
      const sysPowerEnergyData = reorderResult(
        "sysPowerEnergy",
        results[0],
        sources,
        2
      );

      return Promise.resolve({
        sysPower: normalizeSysPower(
          sysPowerEnergyData[0],
          results[0].headers[0],
          date
        ),
        sysEnergy: normalizeSysEnergy(
          sysPowerEnergyData[1],
          results[0].headers[1],
          date
        ),
        invEnergy: normalizeInvEnergy(results[4], sources, date),
        invDcVoltage: normalizeInvDcVoltage(results[2], sources, date),
        invAcVoltageMin: invAcVoltage.min,
        invAcVoltageMax: invAcVoltage.max,
        invPower: normalizeInvPower(results[1], sources, date),
        messageCount: null,
      });
    })
    .then((preTimeCorrection) => {
      if (!time_offset_correction) {
        return preTimeCorrection;
      }
      return Object.entries(preTimeCorrection).reduce(
        (res, [dataName, dataObj]) => {
          if (!Array.isArray(dataObj)) {
            res[dataName] = dataObj;
          } else {
            res[dataName] = dataObj.map((dataChannel) => {
              return Object.fromEntries(
                Object.entries(dataChannel).map(([ts, data]) => {
                  // TODO: check if needed to care of time overflow to adjecent dates, currently, overflown dates are clipped
                  const newTs = moment(ts).add(
                    Number(time_offset_correction),
                    "minutes"
                  );
                  return [
                    !newTs.isValid() || !moment(date).isSame(newTs, "day")
                      ? ts
                      : newTs.format(),
                    data,
                  ];
                })
              );
            });
          }
          return res;
        },
        {}
      );
    });
}

export function normalizeColumn(rawData, header, date, toUnit) {
  if (!rawData || Object.keys(rawData).length === 0) {
    return null;
  }
  let result = null;
  result = Stats.filterForDate(rawData, date);
  // const fromUnit = getUnitFromHeader(header);
  // result = Units.convertUnits(result, fromUnit, toUnit);
  return result;
}

export function normalizeSysPower(rawData, header, date) {
  const data = normalizeColumn(rawData, header, date, "kW");
  return data === null ? [] : [data];
}

export function normalizeSysEnergy(rawData, header, date) {
  const data = normalizeColumn(rawData, header, date, "kWh");
  return data === null ? [] : [data];
}

export function normalizeInvPower(rawResults, sourcesMeta, date) {
  const normData = rawResults.data.map((set, idx) =>
    normalizeColumn(set, rawResults.headers[idx], date, "kW")
  );
  const normResults = {
    ...rawResults,
    data: normData,
  };
  const reorderedResults = reorderResult("invPower", normResults, sourcesMeta);
  return reorderedResults;
}

export function normalizeInvEnergy(rawResults, sourcesMeta, date) {
  const normData = rawResults.data.map((set, idx) =>
    normalizeColumn(set, rawResults.headers[idx], date, "kWh")
  );
  const normResults = {
    ...rawResults,
    data: normData,
  };
  const reorderedResults = reorderResult("invEnergy", normResults, sourcesMeta);
  return reorderedResults;
}

export function normalizeInvDcVoltage(rawResults, sourcesMeta, date) {
  let normResults = {
    ...rawResults,
    data: rawResults.data.map((col) => Stats.filterForDate(col, date)),
  };
  normResults = reorderResult("invDcVoltage", normResults, sourcesMeta);
  return normResults;
}

export function normalizeInvAcVoltage(rawResults, sourcesMeta, date) {
  const invAcVoltageMax = [];
  const invAcVoltageMin = [];
  const filteredResults = {
    ...rawResults,
    data: rawResults.data.map((col) => Stats.filterForDate(col, date)),
  };
  const ordered = reorderResult("invAcVoltage", filteredResults, sourcesMeta);
  ordered.forEach((invAcReadings) => {
    const mins = {};
    const maxs = {};
    Object.keys(invAcReadings).forEach((date) => {
      if (Array.isArray(invAcReadings[date])) {
        // made to make sure that empty strings and/or null values are not parsed as 0
        const minMaxArray = invAcReadings[date].filter(
          (log) => log !== "" && log !== null
        );
        // we have an array of readings - calculate min and max
        const min = Math.min(...minMaxArray);
        if (min && Number.isFinite(min)) {
          mins[date] = "" + min;
        } else {
          mins[date] = "";
        }
        const max = Math.max(...minMaxArray);
        if (max && Number.isFinite(max)) {
          maxs[date] = "" + max;
        } else {
          maxs[date] = "";
        }
      } else {
        // we have a single reading - just use it
        maxs[date] = invAcReadings[date];
        mins[date] = invAcReadings[date];
      }
    });
    // add to output array
    invAcVoltageMax.push(maxs);
    invAcVoltageMin.push(mins);
  });
  return {
    min: invAcVoltageMin,
    max: invAcVoltageMax,
  };
}

/**
 *
 * @param {string} key data set name (EG invPower)
 * @param {object} result raw result object
 * @param {object} sources sources metadata
 * @param {number} resLength how many items in resulting array (default 12)
 */
function reorderResult(key, result, sources, resLength = 12) {
  if (!Array.isArray(result.data) || result.data.length === 0) {
    return [];
  }
  let ordered;
  if ("order" in sources[key] && Array.isArray(sources[key].order)) {
    // console.log("Reordered ", key);
    ordered = DataTrans.columnsToObjects(
      result.data,
      sources[key].order,
      resLength
    );
  } else {
    // console.log("Keeping source order", key);
    ordered = result.data;
  }
  return ordered;
}

/**
 *
 * @param {string} header
 */
export function getUnitFromHeader(header) {
  const matches = header.match(/\[(\w+)\]$/);
  return matches ? matches[1] : null;
}

// sma style collation
export function collateHourlyData(data, mins = 60, keepOffset = false) {
  if (!Number.isInteger(mins) || mins <= 0 || 60 % mins !== 0) {
    mins = 60;
  }
  const midRes = {};
  Object.keys(data).forEach((timeStamp) => {
    const currentMoment = moment(timeStamp);
    if (currentMoment.minutes() % mins !== 0) {
      currentMoment.add(mins, "minutes");
    }
    // remove remainder from last mins interval, set to start of minute
    const currentHour = currentMoment
      .subtract(currentMoment.minutes() % mins, "minutes")
      .startOf("minute")
      .toISOString(keepOffset);
    const value = Number.parseFloat(data[timeStamp]);
    if (!Number.isNaN(value)) {
      if (!(currentHour in midRes)) {
        midRes[currentHour] = [];
      }
      midRes[currentHour].push(value);
    }
  });
  return midRes;
}

// sma style time checking
export function isActiveCb(val) {
  const numVal = parseFloat(val);
  return !Number.isNaN(numVal) && numVal !== 0;
}

export function getLatestStartTime(data) {
  const keys = Object.keys(data);
  if (keys.length === 0) {
    return null;
  }
  keys.sort();
  let res = isActiveCb(data[keys[0]]) ? keys[0] : null;
  for (let i = keys.length - 1; i > 0; --i) {
    if (!isActiveCb(data[keys[i - 1]]) && isActiveCb(data[keys[i]])) {
      res = keys[i - 1];
      break;
    }
  }
  return res;
}

export function getLatestStopTime(data) {
  const dataEntries = Object.entries(data).sort((log1, log2) =>
    log1[0] < log2[0] ? -1 : 1
  );
  for (let i = dataEntries.length - 1; i >= 0; --i) {
    if (isActiveCb(dataEntries[i][1])) {
      break;
    }
    dataEntries.pop();
  }
  const length = dataEntries.length;
  if (length === 0) {
    return null;
  }
  return dataEntries[length - 1][1] ? dataEntries[length - 1][0] : null;
}
