import React, { Component } from "react";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as Stats from "../../helpers/stats";
import Result from "./Result";
import Message from "./Message";
import DataCharts from "../DataCharts";
import styled from "styled-components";
import * as SunCalc from "suncalc";
import SelectionForm from "./SelectionForm";
import {
  calcRefComm,
  calcFct,
  calcRiso,
  calcMcm,
  calcBlackout,
  getWebboxField,
} from "../../helpers/daily";

// TODO: take from system data
const IL_LAT = 32.0853;
const IL_LON = 34.781768;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow: hidden;
`;
const Title = styled.span`
  font-weight: bold;
  margin-right: 0.2em;
`;
const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;
  overflow: hidden;
`;

class StatsDashboard extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    message: PropTypes.string,
    severity: PropTypes.number,
    requestData: PropTypes.func,
    systems: PropTypes.object,
    results: PropTypes.object,
    dataDate: PropTypes.object,
    dataSysId: PropTypes.string,
    regionalCoordinates: PropTypes.object,
    inverterMakeServices: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const now = moment();

    this.state = {
      stats: null,
      latest: null,
      selectedSystem: "",
      selectedDate: now,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (this.props.results !== nextProps.results) {
      const system = nextProps.systems[nextProps.dataSysId];
      const regionLocation = nextProps.regionalCoordinates[system.region];
      const location = system.location ||
        regionLocation || { lat: IL_LAT, lon: IL_LON };

      const sunTimes = Object.entries(
        SunCalc.getTimes(moment(nextProps.dataDate), location.lat, location.lon)
      ).reduce((acc, [timeOfDay, hour]) => {
        acc[timeOfDay] = moment(hour);
        return acc;
      }, {});

      const stats = Stats.getStatistics(
        nextProps.results.sysPower,
        nextProps.results.sysEnergy,
        nextProps.results.invPower,
        nextProps.results.invDcVoltage,
        nextProps.results.invAcVoltageMin,
        nextProps.results.invAcVoltageMax,
        nextProps.results.invEnergy,
        nextProps.results.chanDcCurrent,
        nextProps.results.chanDcPowerInput,
        nextProps.inverterMakeServices,
        system,
        sunTimes
      );

      const latest = Stats.getLatestDataTime(
        [].concat(
          nextProps.results.sysPower,
          nextProps.results.sysEnergy,
          nextProps.results.invPower,
          nextProps.results.invDcVoltage,
          nextProps.results.invAcVoltageMin
          // DO NOT ADD invEnergy here
        )
      );

      const { sysPower, invPower } = nextProps.results;
      const powerForComm =
        sysPower[0] ||
        Stats.convertInvPowerToSysPowerCommCalc(invPower)[0] ||
        {};
      const comm = calcRefComm(
        powerForComm,
        invPower,
        stats.sysDayEnergy,
        location.lat,
        location.lon,
        system.inverterSizes,
        system.sysACSize,
        system.systemPowerFactor,
        system.inverterPowerFactor
      ).value;
      let fct = calcFct(
        stats,
        nextProps.systems[nextProps.dataSysId],
        nextProps.results
      );
      let riso = calcRiso(
        stats,
        nextProps.results,
        nextProps.systems[nextProps.dataSysId],
        moment(sunTimes.sunrise)
      );
      let mcm = calcMcm(
        stats,
        nextProps.results,
        nextProps.systems[nextProps.dataSysId],
        moment(sunTimes.sunrise)
      );
      let webbox = getWebboxField(nextProps.results.messageCount);
      let blackout = calcBlackout(
        nextProps.results,
        nextProps.systems[nextProps.dataSysId]
      );
      if (comm === 3 || comm === 4) {
        fct = "";
        riso = "";
        blackout = "";
        mcm = "";
      }
      const daily = {
        comm,
        fct,
        mcm,
        riso,
        blackout,
        webbox,
      };
      this.setState({
        stats,
        latest,
        daily,
        location,
      });
    }
  };

  doFetch = (sysId, date, utcOffset) => {
    this.props.requestData(sysId, date, utcOffset);
  };

  render() {
    if (this.props.systems === null || this.props.systems.length === 0) {
      return <div className="StatsDashboard">No systems found :(</div>;
    }
    return (
      <Container>
        <SelectionForm
          isFetching={this.props.isFetching}
          systems={this.props.systems}
          doFetch={this.doFetch}
        />
        <ResultsContainer>
          <div>
            <Title>Result:</Title>
            <Message text={this.props.message} severity={this.props.severity} />
          </div>
          {this.props.results ? (
            <Result
              dataSys={this.props.systems[this.props.dataSysId]}
              dataDate={this.props.dataDate}
              data={this.state.stats}
              daily={this.state.daily}
              latest={this.state.latest}
              location={this.state.location}
              results={this.props.results}
            />
          ) : (
            "No results yet"
          )}
          {this.props.results && (
            <ChartsContainer>
              <h4>Graphs</h4>
              <DataCharts
                data={this.props.results}
                inverters={this.props.systems[this.props.dataSysId].inverters}
                inverterMapping={
                  this.props.systems[this.props.dataSysId].inverterMapping
                }
              />
            </ChartsContainer>
          )}
        </ResultsContainer>
      </Container>
    );
  }
}

export default StatsDashboard;
