import moment from "moment";

const DATE_FORMAT = {
  db: "YYYY-MM-DD",
  local: "_YYYY_MM",
};

const propDict = {
  epi: "monthly_epi",
  cumyear: "monthly_cumyear",
  rating: "monthly_rating",
  yield: "monthly_yield",
  expratio: "monthly_expratio",
  expect: "monthly_expect",
  expuptime: "monthly_exp_uptime",
};

// if the value is not parsable as a number, returns null
const parseFloatNullable = (toParse) => {
  const parsed = Number.parseFloat(toParse);
  return Number.isNaN(parsed) ? null : parsed;
};

// now is sent to avoid recalculation, also if the process have started on the cusp of a new day, so teh results would not be
// affected
const monthlyInsightSheetToDb = (sysId, monthToMigrate, payload) => {
  const regex = RegExp(/^(\d\d\d\d)(\d+)-(.+)$/);
  const payloadByMonths = Object.entries(payload).reduce(
    (byMonths, [key, value]) => {
      const patternSearch = regex.exec(key);
      if (patternSearch) {
        const [, /* ignore */ year, month, fieldName] = patternSearch;
        const dateName = `_${year}_${month}`;
        const date = moment([+year, +month - 1]).startOf("month");
        if (monthToMigrate.isSame(date, "month")) {
          if (!byMonths.hasOwnProperty(dateName)) {
            byMonths[dateName] = {};
          }
          const numericValue = parseFloatNullable(value);
          // ensure that null values are not sent, importent so existing data is not overriden by corrupted gsheet data
          if (numericValue !== null) {
            byMonths[dateName][fieldName] = parseFloatNullable(value);
          }
        }
      }
      return byMonths;
    },
    {}
  );

  return Object.entries(payloadByMonths).reduce(
    (resultArr, [dateName, monthData]) => {
      const monthPayload = {
        systemId: sysId,
        month: moment(dateName, DATE_FORMAT.local).format(DATE_FORMAT.db),
      };
      for (const prop in monthData) {
        if (propDict.hasOwnProperty(prop)) {
          monthPayload[propDict[prop]] = monthData[prop];
        }
      }
      // filter out empty logs
      if (Object.keys(monthPayload).length > 2) {
        resultArr.push(monthPayload);
      }
      return resultArr;
    },
    []
  );
};

export default (monthlyData, firstDate) => {
  const monthToMigrate = moment(firstDate.startOf("month"));
  return Object.entries(monthlyData).reduce((resultArr, [sysId, sysData]) => {
    resultArr.push(...monthlyInsightSheetToDb(sysId, monthToMigrate, sysData));
    return resultArr;
  }, []);
};
