import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";

const BufferContainer = styled.div`
  position: absolute;
  top: 358px;
  left: 61%;
`;

const QueriesTableContainer = styled.div`
  height: auto;
  max-height: 340px;
  overflow: auto;
  padding: 4px;
`;

const QueriesTable = styled.table`
  border: 1px solid black;
  min-width: 400px;
  widht: auto;
`;
const QueriesTd = styled.td`
  border: 1px solid black;
  padding: 4px 8px;
  text-align: center;
`;
const ActionText = styled.span`
  ${(props) =>
    props.status === "Failed" ||
    props.status === "Sending" ||
    props.status === "Skipped"
      ? `
    text-decoration: underline;
    &:hover {
      color: blue;
      cursor: pointer;
    };
   `
      : ""}
`;
const StatusText = styled.span`
  ${(props) =>
    props.status === "Failed"
      ? `
      color: #ff9999
      `
      : ""}
`;

const QueriesTr = styled.tr`
  border: 1px solid black;
`;

const ClearAllButton = styled.button`
  width: 75px;
  padding: 5px;
  margin-bottom: 10px;
`;

class QueriesStatuses extends Component {
  static propTypes = {
    pushQueries: PropTypes.array,
    retryPush: PropTypes.func,
    isPushOngoing: PropTypes.bool,
    removePushQueryData: PropTypes.func,
    cancelPushQuery: PropTypes.func,
    clearAllQueries: PropTypes.func,
  };

  pushQueryAction = (pushInfo, index) => {
    const { date, data, sysDocId, status, name, sysId } = pushInfo;
    if (status === "Failed" || status === "Skipped") {
      const payload = { date, data, sysDocId, name, sysId, status: "Sending" };
      this.props.retryPush(payload, index);
    } else if (status === "Sending") {
      this.props.cancelPushQuery(index);
    }
  };

  render() {
    return (
      <BufferContainer>
        <ClearAllButton
          disabled={this.props.isPushOngoing}
          onClick={() => this.props.clearAllQueries()}
        >
          Clear All
        </ClearAllButton>
        {this.props.pushQueries.length ? (
          <QueriesTableContainer>
            <QueriesTable>
              <thead>
                <QueriesTr>
                  <QueriesTd style={{ width: "50%" }}>Info</QueriesTd>
                  <QueriesTd>Status</QueriesTd>
                  <QueriesTd>Action</QueriesTd>
                </QueriesTr>
              </thead>
              <tbody>
                {this.props.pushQueries.map((pushInfo, index) => (
                  <QueriesTr key={`${index}-query`}>
                    <QueriesTd style={{ width: "50%" }}>
                      Update {pushInfo.name}{" "}
                      {moment(pushInfo.date).format("DD/MM/YYYY")}
                    </QueriesTd>
                    <QueriesTd>
                      <StatusText isFailed={pushInfo.status === "Failed"}>
                        {pushInfo.status}
                      </StatusText>
                    </QueriesTd>
                    <QueriesTd>
                      <ActionText
                        status={pushInfo.status}
                        onClick={() => this.pushQueryAction(pushInfo, index)}
                      >
                        {pushInfo.status === "Failed" ||
                        pushInfo.status === "Skipped"
                          ? "Retry"
                          : pushInfo.status === "Sending"
                          ? "Cancel"
                          : "-"}
                      </ActionText>
                    </QueriesTd>
                  </QueriesTr>
                ))}
              </tbody>
            </QueriesTable>
          </QueriesTableContainer>
        ) : (
          <p>No data has been pushed so far...</p>
        )}
      </BufferContainer>
    );
  }
}

export default QueriesStatuses;
