import React from "react";

function Queue(props) {
  const { title, queue } = props;
  return (
    <div style={{ marginTop: "1em" }}>
      <strong>{title} queue</strong>
      <ul>
        {queue.map((job) => {
          return (
            <li key={job.id}>
              {job.id} {job.status}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Queue;
