import React from "react";
import { Route, withRouter, Redirect, Switch } from "react-router-dom";
import styled from "styled-components";

import withAuthorization from "../components/Session/withAuthorization";

import Navigation from "../components/Navigation";
import * as routes from "../constants/routes";

import OpsPage from "../pages/Operation";
import SystemsPage from "../pages/Systems";
import OrgsPage from "../pages/Organizations";
import UsersPage from "../pages/Users";
import AccountPage from "../pages/Account";
import MessagesPage from "../pages/Messages";
import MigrationsPage from "../pages/Migrations";
import InsightsPage from "../pages/Insights";
import SysmapApi from "./SysmapApi";

import { fetchJsonData } from "../firebase/storage";
import { db } from "../firebase";

const AppLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  flex: 1;
  overflow: hidden;
  height: 100%;
`;

// function constructor to create a new clean state with possible loading or not
function createCleanState(isLoading = false, error = null) {
  return {
    isLoading: isLoading,
    loadError: error,
    regionsData: null,
    systemsData: null,
    schedule: null,
    orgsData: null,
    usersData: null,
  };
}

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...createCleanState(true) };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadDataFromFirebase();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadDataFromFirebase = () => {
    this.setState(
      () => {
        if (!this._isMounted) {
          return;
        }
        return { ...createCleanState(true) };
      },
      () => {
        Promise.all([
          fetchJsonData("admin/regions.json"),
          fetchJsonData("admin/systems.json"),
          fetchJsonData("admin/schedule.json"),
          db.onceGetDB().then((res) => res.val()),
        ])
          .then(([regionsData, systemsData, schedule, dbSnapshot]) => {
            if (!this._isMounted) {
              return;
            }

            const { organizations, systems: dbSystems, users } = dbSnapshot;

            // check for missing system id's in the organizations
            const dbOrgs = Object.entries(organizations).reduce(
              (orgs, [orgId, orgData]) => {
                if (
                  !Array.isArray(orgData.systemIds) ||
                  !orgData.systemIds.length
                ) {
                  console.warn(`Organization "${orgId}" misconfigured`);
                  window.alert(`Organization "${orgId}" misconfigured`);
                } else {
                  orgs[orgId] = orgData;
                }
                return orgs;
              },
              {}
            );

            const dbUsers = Object.entries(users).reduce(
              (usersAcc, [phone, userData]) => {
                if (!dbOrgs[userData.organization]) {
                  console.warn(`user "${phone}" has no organization`);
                  window.alert(`user "${phone}" has no organization`);
                } else {
                  usersAcc[phone] = userData;
                }
                return usersAcc;
              },
              {}
            );

            let missingSystem = false;

            // replace systemsData 'name', 'inverters' fields with the DB's equivalent's
            Object.keys(systemsData).forEach((id) => {
              if (systemsData[id].is_reference) {
                return;
              }
              if (!dbSystems[id]) {
                missingSystem = true;
                console.error(
                  `'${id}' is in firebase storage, and not in DB, please correct immediately`
                );
              } else {
                // getting the flag indicating if the system is frozen for messaging purposes
                systemsData[id].frozen = !!dbSystems[id].frozen;
                if (dbSystems[id].parent_id && !systemsData[id].frozen) {
                  const parent = systemsData[dbSystems[id].parent_id];
                  systemsData[id].parent_id = parent.id;
                  if (!Array.isArray(parent.children_id)) {
                    parent.children_id = [];
                  }
                  parent.children_id.push(id);
                }
                systemsData[id].name = dbSystems[id].name
                  ? dbSystems[id].name
                  : id;
                // old style array for channels of the system
                // TODO: deprecate when all systems have moved to inverters as the next clause
                if (Array.isArray(dbSystems[id].subsystems)) {
                  systemsData[id].inverters = [...dbSystems[id].subsystems];
                }
                // new style inverter representation, takes into account system's architecture
                if (Array.isArray(dbSystems[id].inverters)) {
                  systemsData[id].inverterMapping = [
                    ...dbSystems[id].inverters,
                  ];
                }
              }
            });
            if (missingSystem) {
              window.alert(
                `problem loading systems. after this alert, press 'F12', save or capture 'console'->'errors'`
              );
            }

            this.setState(() => {
              if (!this._isMounted) {
                return;
              }
              return {
                isLoading: false,
                regionsData,
                systemsData,
                schedule,
                orgsData: dbOrgs,
                usersData: dbUsers,
              };
            });
          })
          .catch((error) => {
            this.setState(() => {
              if (!this._isMounted) {
                return;
              }

              console.error(error);
              return { ...createCleanState(false, error) };
            });
          });
      }
    );
  };

  // rendering of the old dashboard greeting
  renderLandingDashboard = () => (
    <div>
      <h1>Dashboard</h1>
      <p>Hello there!</p>
    </div>
  );

  render() {
    const { match } = this.props;
    const { isLoading, systemsData, orgsData, usersData, loadError } =
      this.state;
    return (
      <AppLayout>
        <Navigation
          isLoadingData={isLoading}
          fetchAllData={this.loadDataFromFirebase}
        />

        <MainContainer>
          <Switch>
            <Route
              exact
              path={`${match.path}${routes.DASHBOARD}`}
              render={() => this.renderLandingDashboard()}
            />
            <Route
              exact
              path={`${match.path}${routes.ORGS}`}
              render={() => (
                <OrgsPage {...{ isLoading, loadError, orgsData }} />
              )}
            />
            <Route
              path={`${match.path}${routes.SYSTEMS}`}
              render={() => (
                <SystemsPage {...{ isLoading, loadError, systemsData }} />
              )}
            />
            <Route
              exact
              path={`${match.path}${routes.USERS}`}
              render={() => (
                <UsersPage {...{ isLoading, loadError, usersData }} />
              )}
            />
            <Route
              path={`${match.path}${routes.SYSMAP}`}
              render={() => <SysmapApi {...this.state} />}
            />
            <Route
              exact
              path={`${match.path}${routes.ACCOUNT}`}
              render={() => <AccountPage />}
            />
            <Route
              path={`${match.path}${routes.OPERATION}`}
              render={() => <OpsPage {...this.state} />}
            />
            <Route
              path={`${match.path}${routes.MESSAGES}`}
              render={() => (
                <MessagesPage
                  {...{
                    isLoading,
                    loadError,
                    systemsData,
                    orgsData,
                    usersData,
                  }}
                />
              )}
            />
            <Route
              path={`${match.path}${routes.MIGRATIONS}`}
              render={() => (
                <MigrationsPage {...{ isLoading, loadError, systemsData }} />
              )}
            />
            <Route
              path={`${match.path}${routes.INSIGHTS}`}
              render={() => <InsightsPage {...this.state} />}
            />
            <Redirect to={`${match.url}${routes.DASHBOARD}`} />
          </Switch>
        </MainContainer>
      </AppLayout>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withRouter(HomePage));
