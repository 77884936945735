import moment from "moment";

const API_URL = "https://api.solarweb.com/swqapi";

// don't change, currently fronius has problems with any more
const LIMIT_PER_CALL = 101;
const TZ = "local"; // timezone

const UNIT_CONVERTIONS = {
  "%": 1 / 100,
  Wh: 1 / 1000,
  V: 1,
};

function getInvChannels() {
  return [
    "StandardizedPower",
    "EnergyExported",
    "VoltageA",
    "VoltageB",
    "VoltageC",
    "VoltageDC1",
  ];
}

// gets a unit string, and returns a coefficient to multiply in order to standardize units
function convertUnits(unit) {
  if (UNIT_CONVERTIONS[unit]) {
    return UNIT_CONVERTIONS[unit];
  }
  throw new Error(`new type of unit, please add '${unit}'`);
}

function getOptions(keyId, keyValue) {
  return {
    mode: "cors",
    headers: {
      Accept: "application/json",
      AccessKeyId: keyId,
      AccessKeyValue: keyValue,
    },
  };
}

function getChannelsQuery(channels = getInvChannels()) {
  if (!Array.isArray || !channels.length) {
    return "";
  }
  return `channel=${channels.join(",")}&`;
}

// fetch all pages from given url with given options, done sequentially, in order to leave network load
async function fetchAllPages(url, options) {
  try {
    const raw = await fetch(url, options);
    if (!raw.ok) {
      throw new Error(`${raw.status}: ${raw.statusText}`);
    }
    const response = await raw.json();
    const data = response.data.map((datum) => {
      return {
        logDateTime: moment(datum.logDateTime),
        channels: [...datum.channels],
      };
    });
    for (
      let newRes, next = response.links.next;
      next;
      next = newRes.links.next
    ) {
      newRes = await fetch(next, options);
      if (!newRes.ok) {
        throw new Error(`${newRes.status}: ${newRes.statusText}`);
      }
      newRes = await newRes.json();
      data.push(
        ...newRes.data.map((datum) => {
          datum.logDateTime = moment(datum.logDateTime);
          return datum;
        })
      );
    }
    return data.sort((d1, d2) =>
      d1.logDateTime.diff(d2.logDateTime, "minutes")
    );
  } catch (e) {
    throw e;
  }
}

export async function getData(systemInfo, date, timeOffset) {
  const { inverterIds, inverterSizes } = systemInfo;
  const { keyId, keyValue, siteId } = systemInfo.sources;

  const startStamp = moment(date).hour(0).minute(0).second(0).unix();
  const endStamp = moment(date).hour(23).minute(59).second(59).unix();
  const options = getOptions(keyId, keyValue);
  const invertersUrls = inverterIds.map(
    (id) =>
      `${API_URL}/pvsystems/${siteId}/devices/${id}/histdata?from=${startStamp}&to=${endStamp}&limit=${LIMIT_PER_CALL}&${getChannelsQuery()}timezone=${TZ}`
  );
  const messageCountUrl = `${API_URL}/pvsystems/${siteId}/messages-count?from=${startStamp}&to=${endStamp}&languageCode=en`;
  try {
    const invRawData = await Promise.all(
      invertersUrls.map((url) => fetchAllPages(url, options))
    );
    const messageCountRaw = await (
      await fetch(messageCountUrl, options)
    ).json();
    const invData = normalizeInvData(invRawData, inverterSizes);
    return {
      sysPower: [],
      sysEnergy: [],
      ...invData,
      messageCount: messageCountRaw.count,
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}

function calcInvPower(standardizedPower, size) {
  return Object.fromEntries(
    Object.entries(standardizedPower).map(([date, percentage]) => [
      date,
      Number.isFinite(percentage) ? Number(percentage) * Number(size) : null,
    ])
  );
}

function getAcMinMax(voltageLogs) {
  const invAcVoltageMin = {};
  const invAcVoltageMax = {};
  Object.entries(voltageLogs).forEach(([time, values]) => {
    const filteredVals = values.filter((val) => Number.isFinite(val));
    const min = Math.min(...filteredVals);
    const max = Math.max(...filteredVals);
    invAcVoltageMin[time] = Number.isFinite(min) ? min : null;
    invAcVoltageMax[time] = Number.isFinite(max) ? max : null;
  });
  return {
    invAcVoltageMin,
    invAcVoltageMax,
  };
}

function normalizeSingleInverter(raw, size) {
  const voltageKeys = { VoltageA: 0, VoltageB: 1, VoltageC: 2 };
  const channels = Object.fromEntries(getInvChannels().map((key) => [key, {}]));
  const voltageLogs = {};
  raw.forEach((log) => {
    const logTime = log.logDateTime.format();
    log.channels.forEach((chan) => {
      const value =
        chan.value === null ? null : chan.value * convertUnits(chan.unit);
      if (chan.channelName in voltageKeys) {
        if (!voltageLogs.hasOwnProperty(logTime)) {
          voltageLogs[logTime] = [];
        }
        voltageLogs[logTime].push(value);
      }
      channels[chan.channelName][[logTime]] = value;
    });
  });
  const { invAcVoltageMin, invAcVoltageMax } = getAcMinMax(voltageLogs);
  const results = {
    invPower: calcInvPower(channels.StandardizedPower, size),
    invEnergy: { ...channels.EnergyExported },
    invDcVoltage: { ...channels.VoltageDC1 },
    invAcVoltageMin,
    invAcVoltageMax,
  };
  return results;
}

function normalizeInvData(raw, sizes) {
  const results = {
    invPower: [],
    invEnergy: [],
    invDcVoltage: [],
    invAcVoltageMax: [],
    invAcVoltageMin: [],
  };
  raw.forEach((invData, idx) => {
    Object.entries(normalizeSingleInverter(invData, sizes[idx])).forEach(
      ([key, value]) => {
        results[key].push(value);
      }
    );
  });
  return results;
}

// solar-edge style collation
export function collateHourlyData(data, mins = 60, keepOffset = false) {
  if (!Number.isInteger(mins) || mins <= 0 || 60 % mins !== 0) {
    mins = 60;
  }
  const midRes = {};
  Object.keys(data).forEach((timeStamp) => {
    const currentMoment = moment(timeStamp);
    currentMoment.add(mins, "minutes");
    const currentHour = currentMoment
      .subtract(currentMoment.minutes() % mins, "minutes")
      .startOf("minute")
      .toISOString(keepOffset);
    const value = Number.parseFloat(data[timeStamp]);
    if (!Number.isNaN(value)) {
      if (!(currentHour in midRes)) {
        midRes[currentHour] = [];
      }
      midRes[currentHour].push(value);
    }
  });
  return midRes;
}

// solaredge style time checking
export function isActiveCb(val) {
  const numVal = parseFloat(val);
  return !Number.isNaN(numVal) && numVal !== 0;
}

export function getLatestStartTime(data) {
  const keys = Object.keys(data);
  if (keys.length === 0) {
    return null;
  }
  keys.sort();
  let res = isActiveCb(data[keys[0]]) ? keys[0] : null;
  for (let i = keys.length - 1; i > 0; --i) {
    if (!isActiveCb(data[keys[i - 1]]) && isActiveCb(data[keys[i]])) {
      res = keys[i];
      break;
    }
  }
  return res;
}

export function getLatestStopTime(data) {
  const dataEntries = Object.entries(data).sort((log1, log2) =>
    log1[0] < log2[0] ? -1 : 1
  );
  for (let i = dataEntries.length - 1; i >= 0; --i) {
    if (isActiveCb(dataEntries[i][1])) {
      break;
    }
    dataEntries.pop();
  }
  const length = dataEntries.length;
  if (length === 0) {
    return null;
  }
  return dataEntries[length - 1][1] ? dataEntries[length - 1][0] : null;
}
