import React, { Component } from "react";
import moment from "moment";

class MessageLogItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  onClick = () => {
    if (!this._isMounted) {
      return;
    }
    this.setState((state) => {
      return {
        collapsed: !state.collapsed,
      };
    });
  };

  renderExpanded = (sender, body, lastEdit, error) => {
    return (
      <div>
        <i>message:</i> '{body}'<br />
        <i>label:</i> '{sender}'<br />
        {error && (
          <span>
            <i>error:</i> '{error.message}'
          </span>
        )}
        {lastEdit && (
          <>
            <i>last edit:</i> {lastEdit.format()}
          </>
        )}
      </div>
    );
  };

  renderMsgCode = (msgCode) => {
    return !msgCode ? null : <>'{msgCode}',&nbsp;</>;
  };

  render() {
    const {
      messageId,
      recipientNumber,
      status,
      body,
      error,
      sender,
      msgCode,
      lastEdit,
    } = this.props.log;
    const message = typeof body !== "string" ? body.body : body;
    return (
      <li
        onClick={this.onClick}
        style={{ color: error ? "red" : "green", cursor: "pointer" }}
      >
        <i>#:</i> '{recipientNumber}',
        {this.renderMsgCode(msgCode)}
        <i>status:</i> {status}, aws-id:
        <i> {messageId}</i>
        {!this.state.collapsed &&
          this.renderExpanded(sender, message, lastEdit, error)}
      </li>
    );
  }
}

export default MessageLogItem;
