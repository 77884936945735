import React from "react";
import styled from "styled-components";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Container = styled.div`
  display: inline-block;
`;
const DatePickerWrapper = styled.div`
  display: inline-block;
`;

class DatePickerCombo extends React.Component {
  movePrevDay = (interval) => {
    const newDate = moment(this.props.selected);
    newDate.add(-1, interval);
    this.props.onChange(newDate);
  };
  moveNextDay = (interval) => {
    const newDate = moment(this.props.selected);
    newDate.add(1, interval);
    this.props.onChange(newDate);
  };

  render() {
    const {
      selected,
      onChange,
      disable,
      format,
      intervalIncrements,
      pickerProps,
    } = this.props;
    const interval = intervalIncrements || "days";
    return (
      <Container>
        <button disabled={disable} onClick={() => this.movePrevDay(interval)}>
          ◀
        </button>
        <DatePickerWrapper>
          <DatePicker
            disabled={disable}
            selected={selected}
            onChange={onChange}
            dateFormat={format || "DD/MM/YYYY"}
            {...pickerProps}
          />
        </DatePickerWrapper>
        <button disabled={disable} onClick={() => this.moveNextDay(interval)}>
          ▶
        </button>
      </Container>
    );
  }
}

export default DatePickerCombo;
