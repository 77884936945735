import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SystemsSelect from "../SystemsDropDown";
import DatePickerCombo from "../DatePickerCombo";

import DailyFuncs from "./DailyFuncs";
import InvTable from "./InvTable";
import SystemStats from "./SystemStats";

import moment from "moment";
import PushUi from "../PushToSheetUi";

const DAILY_FUNCS = [
  "comm",
  "blackout",
  "mcm",
  "fct",
  "ovld",
  "riso",
  "shadow",
  "webbox",
];

const INV_DATA_SETS = [
  "invMaxDailyPower",
  "invLatestStartTime",
  "invLatestStopTime",
  "invMedianDcVoltage",
];
const INV_COUNT = 12;

const SYS_DATA = [
  "sysDayEnergy",
  "sysMaxPower",
  "sysAcVoltageMin",
  "sysAcVoltageMax",
  "sysMorningPower",
];

const EXTRA_SYS_DATA = ["dailyMessage", "eBaseline"];

const Section = styled.div`
  border-top: 1px solid silver;
  padding: 10px 0;
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
`;
const SectionTitle = styled.span`
  font-weight: bold;
  margin-bottom: 10px;
  display: inline-block;
`;
const Label = styled.span`
  min-width: 100px;
  display: inline-block;
`;

const initEmpty2DArray = (rowsCount, colsCount) => {
  const Cols = Array(colsCount).fill("");
  const Rows = [];
  for (let i = 0; i < rowsCount; i++) {
    Rows.push(Cols.slice(0));
  }
  return Rows;
};

function getInitialState() {
  return {
    dailyValues: Array(DAILY_FUNCS.length).fill(""),
    invValues: initEmpty2DArray(INV_DATA_SETS.length, INV_COUNT),
    sysValues: Array(SYS_DATA.length + EXTRA_SYS_DATA.length).fill(""),
  };
}

class StatsManualEntry extends Component {
  constructor(props) {
    super(props);

    // pick first non sma and non solaredge system
    const defaultSystem = props.systems.find(
      (sys) =>
        sys.inverter_make !== "sma" &&
        sys.inverter_make !== "smav2" &&
        sys.inverter_make !== "solaredge"
    );

    this.state = {
      selectedDate: moment(),
      selectedSystem: defaultSystem.id,

      ...getInitialState(),
    };
  }

  static propTypes = {
    handlePush: PropTypes.func,
    isPushOngoing: PropTypes.bool,
    pushResult: PropTypes.object,
    handlePushToDB: PropTypes.func,
    isPushOngoingToDB: PropTypes.bool,
  };

  handlePush = () => {
    const { selectedSystem, selectedDate, ...rest } = this.state;
    // const {dailyValues, invValues, sysValues} = this.state;

    this.props.handlePush(selectedSystem, selectedDate, rest);
  };

  handlePushToDB = () => {
    const { selectedSystem, selectedDate, dailyValues, invValues, sysValues } =
      this.state;

    const payload = {
      daily: DAILY_FUNCS.reduce((acc, func, idx) => {
        if (func === "webbox") {
          acc[func] = dailyValues[idx];
        } else {
          const parsed = Number.parseFloat(dailyValues[idx]);
          acc[func] = Number.isFinite(parsed) ? parsed : "";
        }
        return acc;
      }, {}),
      stats: {
        ...INV_DATA_SETS.reduce((acc, attribute, idx) => {
          const attributeRow = invValues[idx];
          const values = attributeRow.reduce((vals, value, i) => {
            const parsed = Number.parseFloat(value);
            if (Number.isFinite(parsed)) {
              const key = `${attribute}${i < 10 ? "0" : ""}${i}`;
              vals[key] = parsed;
            }
            return vals;
          }, {});

          return Object.assign(acc, values);
        }, {}),
        ...SYS_DATA.reduce((acc, attribute, idx) => {
          const parsed = Number.parseFloat(sysValues[idx]);
          acc[attribute] = Number.isFinite(parsed) ? parsed : "";
          return acc;
        }, {}),
      },
    };

    const insights = {};
    const webboxField =
      dailyValues[DAILY_FUNCS.findIndex((e) => e === "webbox")].trim();
    if (webboxField) {
      insights.ef_logger = webboxField;
    }

    const denoutputField = Number.parseFloat(
      sysValues[SYS_DATA.findIndex((e) => e === "sysDayEnergy")]
    );
    if (Number.isFinite(denoutputField)) {
      insights.denoutput = denoutputField;
    }

    const dailyMessageField =
      sysValues[
        SYS_DATA.length + EXTRA_SYS_DATA.findIndex((e) => e === "dailyMessage")
      ].trim();
    if (dailyMessageField) {
      insights.ef_message = dailyMessageField;
    }

    const ebase =
      sysValues[
        SYS_DATA.length + EXTRA_SYS_DATA.findIndex((e) => e === "eBaseline")
      ].trim();

    this.props.handlePushToDB(selectedSystem, selectedDate, payload, {
      insights,
      ebase,
    });
  };

  handleZero = () => {
    const newDailyValues = Array(DAILY_FUNCS.length).fill("0");
    newDailyValues[newDailyValues.length - 1] = "";
    this.setState({
      dailyValues: newDailyValues,
    });
  };

  onDateSelect = (date) => {
    this.setState({
      selectedDate: date,
      ...getInitialState(),
    });
  };

  onSystemSelect = (sysId) => {
    this.setState({
      selectedSystem: sysId,
      ...getInitialState(),
    });
  };

  onArrValsChange = (key, rowIdx, colIdx, ev) => {
    const value = ev.target.value;
    const newValues = this.state[key].map((row, idx) => {
      const cols = row.slice(0);
      if (idx === rowIdx) {
        cols[colIdx] = value;
      }
      return cols;
    });
    this.setState({
      [key]: newValues,
    });
  };

  onInvChange = (rowIdx, colIdx, ev) => {
    this.onArrValsChange("invValues", rowIdx, colIdx, ev);
  };

  onRowValsChange = (key, colIdx, ev) => {
    const newVal = ev.target.value;
    const newValues = this.state[key].map((oldVal, idx) => {
      if (idx === colIdx) {
        return newVal;
      }
      return oldVal;
    });
    this.setState({
      [key]: newValues,
    });
  };

  onSysChange = (colIdx, ev) => {
    this.onRowValsChange("sysValues", colIdx, ev);
  };

  onDailyChange = (colIdx, ev) => {
    this.onRowValsChange("dailyValues", colIdx, ev);
  };

  render() {
    return (
      <div>
        <Section>
          <div>
            <Label>System</Label>
            <SystemsSelect
              systems={this.props.systems}
              onSystemSelect={this.onSystemSelect}
              selectedSystem={this.state.selectedSystem}
            />
          </div>
          <div>
            <Label>Date</Label>
            <DatePickerCombo
              selected={this.state.selectedDate}
              onChange={this.onDateSelect}
            />
          </div>
        </Section>
        <Section>
          <SectionTitle>Daily</SectionTitle>
          <DailyFuncs
            funcNames={DAILY_FUNCS}
            values={this.state.dailyValues}
            onChange={this.onDailyChange}
          />
          <button onClick={this.handleZero} title="Set daily fields to zero">
            Zero
          </button>
        </Section>
        <Section>
          <SectionTitle>Inverters</SectionTitle>
          <InvTable
            invCount={INV_COUNT}
            funcNames={INV_DATA_SETS}
            values={this.state.invValues}
            onChange={this.onInvChange}
          />
        </Section>
        <Section>
          <SectionTitle>System</SectionTitle>
          <SystemStats
            funcNames={SYS_DATA}
            extraFuncs={EXTRA_SYS_DATA}
            values={this.state.sysValues}
            onChange={this.onSysChange}
          />
        </Section>
        <Section>
          <PushUi
            handlePushClick={this.handlePush}
            isManualTab={true}
            isPushOngoing={this.props.isPushOngoing}
            isPushOngoingToDB={this.props.isPushOngoingToDB}
            pushResult={this.props.pushResult}
            handlePushToDB={this.handlePushToDB}
            typeDB={true}
          />
        </Section>
      </div>
    );
  }
}

export default StatsManualEntry;
