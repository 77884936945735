import React from "react";
import withAuthorization from "../components/Session/withAuthorization";
import OrgsList from "../components/DBItemsList";

class OrgsPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { isLoading, loadError, orgsData } = this.props;

    return (
      <div>
        <h1>Organizations</h1>
        {loadError ? (
          "Error loading"
        ) : isLoading || !orgsData ? (
          "Loading..."
        ) : (
          <OrgsList items={orgsData} />
        )}
      </div>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(OrgsPage);
