import React, { Component } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { sendData } from "../services/sendData";
import localStorageKeys from "../localStorageKeys";

const Section = styled.div`
  padding: 10px 0;
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
`;

const ButtonInRow = styled.button`
  margin-right: 1em;
`;

const HIGHLIGHT_BACKGROUND_COLOR = "#d3d3d3";
const HoverableRow = styled.tr`
  &:hover {
    color: blue;
    background: ${HIGHLIGHT_BACKGROUND_COLOR};
    cursor: default;
  }
`;

const TableHeaderCell = styled.th`
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
`;

const TableDataCell = styled.td`
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
`;

const URLS = {
  getSites: "/api/sysmap/sites",
  manipulateSite: "/api/sysmap/site",
};

class SysmapSites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      sites: [],
      editedSite: null,
    };
  }

  loadData = () => {
    if (!this._isMounted) {
      return;
    }

    this.setState(
      {
        isLoading: true,
        isError: false,
        sites: [],
        editedSite: null,
      },
      async () => {
        try {
          const token = localStorage.getItem(localStorageKeys.userToken);
          const sitesRes = await sendData(null, URLS.getSites, token, "GET");

          if (!sitesRes.ok) {
            throw new Error("bad responses");
          }

          const sites = sitesRes.data.map(({ site_id, internal_id, name }) => {
            return {
              siteId: site_id,
              internalId: internal_id,
              name,
            };
          });

          if (!this._isMounted) {
            return;
          }
          this.setState({
            sites,
            isLoading: false,
            isError: false,
          });
        } catch (error) {
          console.error(error);
          this.setState({ isLoading: false, isError: true });
        }
      }
    );
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleAddSite = () => {
    this.setState({
      editedSite: {
        isNew: true,
        siteId: uuidv4(),
        internalId: "",
        name: "",
      },
    });
  };

  handleEditProp = (propName) => (ev) => {
    const newVal = ev.target.value;
    this.setState((state) => {
      const editedSite = { ...state.editedSite };
      editedSite[propName] = newVal;
      return {
        editedSite,
      };
    });
  };

  handleEditSite = (siteId, internalId, name) => () => {
    this.setState({
      editedSite: {
        isNew: false,
        siteId,
        internalId,
        name,
      },
    });
  };

  handleSaveChanges = () => {
    this.setState({ isLoading: true }, async () => {
      try {
        const {
          siteId: site_id,
          internalId: internal_id,
          name,
          isNew,
        } = this.state.editedSite;

        const token = localStorage.getItem(localStorageKeys.userToken);
        const editSiteRes = await (isNew
          ? sendData(
              { values: { site_id, internal_id, name } },
              URLS.manipulateSite,
              token,
              "POST"
            )
          : sendData(
              {
                where: { site_id },
                values: { internal_id, name },
              },
              URLS.manipulateSite,
              token,
              "PUT"
            ));

        if (!editSiteRes.ok) {
          window.alert("Error, try again later");
          return this.setState({ isLoading: false });
        }
      } catch (error) {
        console.error(error);
      }

      this.loadData();
    });
  };

  handleCancelEdit = () => {
    this.setState({ editedSite: null });
  };

  handleDeleteSite = () => {
    const userRes = window.confirm(
      "you are about to delete a site, are you sure?"
    );

    if (!userRes) {
      return;
    }

    this.setState({ isLoading: true }, async () => {
      try {
        const token = localStorage.getItem(localStorageKeys.userToken);
        await sendData(
          { where: { site_id: this.state.editedSite.siteId } },
          URLS.manipulateSite,
          token,
          "DELETE"
        );
        this.loadData();
      } catch (error) {
        console.error(error);
      }
    });
  };

  render() {
    const { isLoading, isError, sites, editedSite } = this.state;

    if (isError) {
      return <Section>Error loading data</Section>;
    }
    if (isLoading) {
      return <Section>Loading data...</Section>;
    }

    if (editedSite) {
      const { isNew, siteId, internalId, name } = editedSite;
      return (
        <Section>
          <h3>
            {isNew ? "add" : "edit"} site - '{internalId}'
          </h3>
          <Section>uuid: {siteId}</Section>
          <Section>
            internal-id:{" "}
            <input
              type="text"
              value={internalId}
              onChange={this.handleEditProp("internalId")}
            />
          </Section>
          <Section>
            name:{" "}
            <input
              type="text"
              value={name}
              onChange={this.handleEditProp("name")}
            />
          </Section>
          <Section>
            <button disabled={isNew} onClick={this.handleDeleteSite}>
              delete
            </button>
          </Section>
          <Section>
            <ButtonInRow onClick={this.handleSaveChanges}>save</ButtonInRow>
            <ButtonInRow onClick={this.handleCancelEdit}>cancel</ButtonInRow>
          </Section>
        </Section>
      );
    }

    return (
      <Section>
        <h3>Sysmap sites:</h3>
        <Section>
          <ButtonInRow onClick={this.handleAddSite}>new site</ButtonInRow>
          <ButtonInRow onClick={this.loadData}>refresh data</ButtonInRow>
        </Section>
        <table>
          <thead>
            <tr>
              <TableHeaderCell>uuid</TableHeaderCell>
              <TableHeaderCell>internal-id</TableHeaderCell>
              <TableHeaderCell>name</TableHeaderCell>
              <TableHeaderCell>edit</TableHeaderCell>
            </tr>
          </thead>
          <tbody>
            {sites.map(({ siteId, internalId, name }, idx) => {
              return (
                <HoverableRow key={idx}>
                  <TableDataCell>{siteId}</TableDataCell>
                  <TableDataCell>{internalId}</TableDataCell>
                  <TableDataCell>{name}</TableDataCell>
                  <TableDataCell>
                    <button
                      onClick={this.handleEditSite(siteId, internalId, name)}
                    >
                      edit
                    </button>
                  </TableDataCell>
                </HoverableRow>
              );
            })}
          </tbody>
        </table>
      </Section>
    );
  }
}

export default SysmapSites;
