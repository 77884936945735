import React from "react";

function CurrentPromises(props) {
  const { fetchPromises, pushPromises } = props;
  const fetchKeys = Object.keys(fetchPromises);
  const pushKeys = Object.keys(pushPromises);

  const currentFetches = !fetchKeys.length ? (
    <div style={{ marginTop: "1em" }}>no fetches</div>
  ) : (
    <div style={{ marginTop: "1em" }}>
      <strong>current fetches</strong>
      <ul>
        {fetchKeys.map((key) => (
          <li key={key}>{key} fetching</li>
        ))}
      </ul>
    </div>
  );

  const currentPushes = !pushKeys.length ? (
    <div style={{ marginTop: "1em" }}>no pushes</div>
  ) : (
    <div style={{ marginTop: "1em" }}>
      <strong>current pushes</strong>
      <ul>
        {pushKeys.map((key) => (
          <li key={key}>{key} pushing</li>
        ))}
      </ul>
    </div>
  );

  return (
    <div style={{ marginTop: "1em" }}>
      {currentFetches}
      {currentPushes}
    </div>
  );
}

export default CurrentPromises;
