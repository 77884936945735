import * as React from "react";
import PropTypes from "prop-types";

class PushUi extends React.Component {
  static propTypes = {
    handlePushClick: PropTypes.func,
    isPushOngoing: PropTypes.bool,
    isManualTab: PropTypes.bool,
    isPushOngoingToDB: PropTypes.bool,
    pushResult: PropTypes.object,
    handlePushToDB: PropTypes.func,
    typeDB: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  getMessage = () => {
    const { pushResult, isPushOngoing } = this.props;

    if (isPushOngoing) {
      return "working...";
    }

    if (pushResult) {
      if (pushResult.success) {
        return "👍";
      }
      return "Error: " + pushResult.message;
    }
    return "";
  };

  render() {
    return (
      <div>
        <button
          onClick={this.props.handlePushClick}
          disabled={this.props.isPushOngoing && this.props.isManualTab}
          title="Push to Sheet"
        >
          Push
        </button>

        {this.props.typeDB && (
          <>
            <button
              onClick={this.props.handlePushToDB}
              disabled={this.props.isPushOngoingToDB}
              title="Set data to db"
            >
              {!this.props.isPushOngoingToDB ? "Push to db" : "working..."}
            </button>
            <button
              disabled={this.props.isPushOngoingToDB}
              title="push to sheet and db"
              onClick={(ev) => {
                this.props.handlePushToDB(ev); // push to db
                this.props.handlePushClick(ev); // push to sheet
              }}
            >
              push all
            </button>
          </>
        )}

        {this.getMessage()}
      </div>
    );
  }
}

export default PushUi;
