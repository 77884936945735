import { getIndexedKey } from "../../helpers/stats";

/**
 * Generate array of table headers for the results
 * @param {number} invCount how many inverter placeholders to create
 */
export const getHeaders = (invCount) => {
  const driver = new Array(invCount).fill(1);
  const res = [].concat(
    driver.map((val, idx) => getIndexedKey("invMaxDailyPower", idx)),
    driver.map((val, idx) => getIndexedKey("invLatestStartTime", idx)),
    driver.map((val, idx) => getIndexedKey("invLatestStopTime", idx)),
    driver.map((val, idx) => getIndexedKey("invMedianDcVoltage", idx))
  );
  res.push("sysDayEnergy");
  res.push("sysMaxPower");
  res.push("sysAcVoltageMin");
  res.push("sysAcVoltageMax");
  res.push("sysMorningPower");

  return res;
};

// expand input data to match the array of headers. Some columns may be empty
export const getDataByHeaders = (data, headers) => {
  const vals = headers.map((header, idx) =>
    header in data ? data[header] : ""
  );
  return vals;
};

/**
 * Generate a tab separated string of the values
 */
export const getDataAsTsv = (data, headers) => {
  const rows = [];

  const vals = getDataByHeaders(data, headers);
  rows.push(vals.join("\t"));

  const res = rows.join("\n");
  return res;
};
