import React from "react";

import BackendMessagesForm from "../components/MessagesBackend";

class MessagesBackend extends React.Component {
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { noRefSystems, testSystems, orgsData, usersData, loadError } =
      this.props;
    if (loadError) {
      return (
        <div style={{ color: "red" }}>
          Error loading systems:
          <div>"{loadError.message}"</div>
        </div>
      );
    }
    if (!noRefSystems) {
      return <div>Loading systems data</div>;
    }
    return (
      <BackendMessagesForm
        systems={noRefSystems}
        testSystems={testSystems}
        orgsData={orgsData}
        usersData={usersData}
      />
    );
  }
}

export default MessagesBackend;
