import React, { Component } from "react";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import styled from "styled-components";
import SystemsSelect from "../SystemsDropDown";
import DatePickerCombo from "../DatePickerCombo";
import TimePicker from "../TimePicker";

const Label = styled.span`
  min-width: 130px;
  display: inline-block;
`;
const Row = styled.div`
  margin-bottom: 1em;
`;

// date range longer than this value would need to be conrirmed
const DATE_RANGE_TO_CONFIRM = 31;

const TIME_SELECTION_INTERVAL = 60; // measured in minutes

const ALL_WEEKDAYS = [0, 1, 2, 3, 4, 5, 6];

class SelectionForm extends Component {
  static propTypes = {
    systems: PropTypes.object,
    scheduledHours: PropTypes.array,
    updateQueue: PropTypes.func,
    updateSchedule: PropTypes.func,
    removeSchedule: PropTypes.func,
    removeAllSchedule: PropTypes.func,
    onClear: PropTypes.func,
    retryAll: PropTypes.func,
    rushAll: PropTypes.func,
    isBusy: PropTypes.func,
    loadSchedule: PropTypes.bool,
    toggleLoadSchedule: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const now = moment({ hour: 6 });
    this.state = {
      selectedSystem: "",
      startDate: now,
      endDate: moment(now),
      selectedTime: moment(now),
    };
  }

  diffDays = (subtructed = this.state.endDate, by = this.state.startDate) => {
    return subtructed.diff(by, "days");
  };

  getMomentRange = () => {
    const initialDate = this.state.startDate;
    return Array(this.diffDays() + 1)
      .fill(0)
      .map((e, idx) => {
        return moment(initialDate).add(idx, "days");
      });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    let dateRange = this.diffDays() + 1;
    if (dateRange > DATE_RANGE_TO_CONFIRM) {
      if (
        !window.confirm(`Selected range of ${dateRange} days, are you sure?`)
      ) {
        return;
      }
    }

    this.props.updateQueue([this.state.selectedSystem], this.getMomentRange());
  };

  handleSchedule = (ev) => {
    ev.preventDefault();
    this.props.updateSchedule(
      this.state.selectedTime,
      true,
      ALL_WEEKDAYS,
      null,
      [],
      false,
      false,
      false
    );
  };

  onSystemSelect = (sysId) => {
    this.setState({
      selectedSystem: sysId,
    });
  };

  onStartDateSelect = (newStartDate) => {
    this.setState((state) => {
      const newState = { startDate: moment(newStartDate) };
      if (this.diffDays(state.endDate, newStartDate) < 0) {
        newState.endDate = moment(newStartDate);
      }
      return newState;
    });
  };

  onEndDateSelect = (newEndDate) => {
    this.setState((state) => ({
      endDate: moment(
        this.diffDays(newEndDate, state.startDate) < 0
          ? state.startDate
          : newEndDate
      ),
    }));
  };

  onChangeTime = (selectedTime) => {
    this.setState({ selectedTime: moment(selectedTime) });
  };

  onRushAll = (ev) => {
    ev.preventDefault();
    if (window.confirm("Rush-All, are you sure?")) {
      this.props.rushAll(this.getMomentRange());
    }
  };

  renderScheduleTable = () => {
    if (
      !Array.isArray(this.props.scheduledHours) ||
      !this.props.scheduledHours.length
    ) {
      return null;
    }
    return (
      <table>
        <thead>
          <tr>
            <th>time:</th>
            <th>previous-day:</th>
            <th>mail-alert:</th>
            <th>sms-alert:</th>
            <th>loaded:</th>
            <th>weekdays:</th>
            <th>systems:</th>
            <th>
              <button
                onClick={(ev) => {
                  ev.preventDefault();
                  this.props.removeAllSchedule();
                }}
              >
                remove all
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.scheduledHours.map((task) => (
            <tr key={task.string()}>
              {task.tableElement()}
              <td>
                <button
                  onClick={(ev) => {
                    ev.preventDefault();
                    this.props.removeSchedule(task);
                  }}
                >
                  remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  renderSchedule = () => {
    return (
      <div style={{ marginTop: "1em" }}>
        <Label>Schedule</Label>
        <div>
          <input
            type="checkbox"
            disabled={this.props.isBusy()}
            checked={this.props.loadSchedule}
            onChange={this.props.toggleLoadSchedule}
          ></input>
          <Label>Load remote schedule</Label>
        </div>
        {this.renderScheduleTable()}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Row>
          <Label>Select system:</Label>
          <SystemsSelect
            systems={Object.values(this.props.systems)}
            onSystemSelect={this.onSystemSelect}
            selectedSystem={this.state.selectedSystem}
          />
        </Row>
        <Row>
          <Label>Select date range:</Label>
          <DatePickerCombo
            selected={this.state.startDate}
            onChange={this.onStartDateSelect}
          />
          &nbsp;to&nbsp;
          <DatePickerCombo
            selected={this.state.endDate}
            onChange={this.onEndDateSelect}
          />
          <span style={{ marginLeft: "1em" }}>
            <Label>Schedule auto rush all:&nbsp;</Label>
            <TimePicker
              selected={this.state.selectedTime}
              onChange={this.onChangeTime}
              timeIntervals={TIME_SELECTION_INTERVAL}
              timeCaption="Rush"
            />
            <button style={{ marginLeft: "1em" }} onClick={this.handleSchedule}>
              Schedule
            </button>
          </span>
        </Row>
        <Row>
          <button
            onClick={this.handleSubmit}
            disabled={!this.state.selectedSystem}
          >
            Rush
          </button>
          <button onClick={this.props.retryAll} style={{ marginLeft: "25em" }}>
            retry all
          </button>
          <button onClick={this.props.onClear} style={{ marginLeft: "0.5em" }}>
            clear done
          </button>
          <button onClick={this.onRushAll} style={{ marginLeft: "0.5em" }}>
            Rush-All
          </button>
        </Row>
        {this.renderSchedule()}
      </div>
    );
  }
}

export default SelectionForm;
