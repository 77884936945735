export function sendData(data, url, token, method = "POST") {
  const fetchObj = {
    method: method,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  if (data) {
    fetchObj.body = JSON.stringify(data);
  }

  return fetch(url, fetchObj)
    .then((response) =>
      Promise.all([
        response.ok,
        response.status,
        response.statusText,
        response.json(),
      ])
    )
    .then(([ok, status, statusText, data]) => ({
      ok,
      status,
      statusText,
      data,
    }))
    .catch((err) => console.error(err));
}
