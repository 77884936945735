import moment from "moment";
import * as SunCalc from "suncalc";
import * as bPromise from "bluebird";

/** time format for sungrow's api calls */
const timeFormat = "YYYYMMDDHHmmss";

/** data about data points from api */
const pointsMetaSys = {
  p83022: {
    id: "sysEnergy",
    sungrowName: "Daily Yield of Plant",
    unit: "Wh",
    factor: 0.001,
    normalize: normalizeEnergy,
  },
  p83033: {
    id: "sysPower",
    sungrowName: "Plant Power",
    unit: "W",
    factor: 0.001,
    normalize: naiveNormalizeData,
  },
  /** returns empty results all of the time */
  // p83012: {
  //   id: "pRadiation",
  //   sungrowName: "P-radiation-H",
  //   unit: :W/m^2,
  // },
  // p83013: {
  //   id: "dayIrraiation",
  //   sungrowName: "Daily Irradiation",
  //   unit: "Wh/m^2",
  // },
};

const pointsMetaInv = {
  /** pvX power - not working */
  // p11: {
  //   id: "pvPow-1?",
  //   sungrowName: "PV1 Power",
  //   unit: "W",
  //   factor: 0.001,
  //   normalize: naiveNormalizeData,
  // },
  p1: {
    id: "invEnergy",
    sungrowName: "Daily Yield",
    unit: "Wh",
    factor: 0.001,
    normalize: normalizeEnergy,
  },
  p24: {
    id: "invPower",
    sungrowName: "Total Active Power",
    unit: "W",
    factor: 0.001,
    normalize: naiveNormalizeData,
  },
  p18: {
    id: "acv-1",
    sungrowName: "Phase A Voltage",
    unit: "V",
    factor: 1,
    normalize: prepareAcVoltChannels,
  },
  p19: {
    id: "acv-2",
    sungrowName: "Phase B Voltage",
    unit: "V",
    factor: 1,
    normalize: prepareAcVoltChannels,
  },
  p20: {
    id: "acv-3",
    sungrowName: "Phase C Voltage",
    unit: "V",
    factor: 1,
    normalize: prepareAcVoltChannels,
  },
  p5: {
    id: "dcv-1",
    sungrowName: "MPPT1 Voltage",
    unit: "V",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p7: {
    id: "dcv-2",
    sungrowName: "MPPT2 Voltage",
    unit: "V",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p9: {
    id: "dcv-3",
    sungrowName: "MPPT3 Voltage",
    unit: "V",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p45: {
    id: "dcv-4",
    sungrowName: "MPPT4 Voltage",
    unit: "V",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p47: {
    id: "dcv-5",
    sungrowName: "MPPT5 Voltage",
    unit: "V",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p49: {
    id: "dcv-6",
    sungrowName: "MPPT6 Voltage",
    unit: "V",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p51: {
    id: "dcv-7",
    sungrowName: "MPPT7 Voltage",
    unit: "V",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p53: {
    id: "dcv-8",
    sungrowName: "MPPT8 Voltage",
    unit: "V",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p55: {
    id: "dcv-9",
    sungrowName: "MPPT9 Voltage",
    unit: "V",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p57: {
    id: "dcv-10",
    sungrowName: "MPPT10 Voltage",
    unit: "V",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p7401: {
    id: "dcv-11",
    sungrowName: "MPPT11 Voltage",
    unit: "V",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p7402: {
    id: "dcv-12",
    sungrowName: "MPPT12 Voltage",
    unit: "V",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p70: {
    id: "si-1",
    sungrowName: "String 1 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p71: {
    id: "si-2",
    sungrowName: "String 2 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p72: {
    id: "si-3",
    sungrowName: "String 3 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p73: {
    id: "si-4",
    sungrowName: "String 4 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p74: {
    id: "si-5",
    sungrowName: "String 5 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p75: {
    id: "si-6",
    sungrowName: "String 6 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p76: {
    id: "si-7",
    sungrowName: "String 7 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p77: {
    id: "si-8",
    sungrowName: "String 8 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p78: {
    id: "si-9",
    sungrowName: "String 9 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p79: {
    id: "si-10",
    sungrowName: "String 10 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p80: {
    id: "si-11",
    sungrowName: "String 11 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p81: {
    id: "si-12",
    sungrowName: "String 12 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p82: {
    id: "si-13",
    sungrowName: "String 13 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p83: {
    id: "si-14",
    sungrowName: "String 14 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p84: {
    id: "si-15",
    sungrowName: "String 15 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p85: {
    id: "si-16",
    sungrowName: "String 16 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p92: {
    id: "si-17",
    sungrowName: "String 17 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p93: {
    id: "si-18",
    sungrowName: "String 18 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p6: {
    id: "dci-1",
    sungrowName: "MPPT1 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p8: {
    id: "dci-2",
    sungrowName: "MPPT2 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p10: {
    id: "dci-3",
    sungrowName: "MPPT3 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p46: {
    id: "dci-4",
    sungrowName: "MPPT4 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p48: {
    id: "dci-5",
    sungrowName: "MPPT5 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p50: {
    id: "dci-6",
    sungrowName: "MPPT6 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p52: {
    id: "dci-7",
    sungrowName: "MPPT7 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p54: {
    id: "dci-8",
    sungrowName: "MPPT8 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p56: {
    id: "dci-9",
    sungrowName: "MPPT9 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p58: {
    id: "dci-10",
    sungrowName: "MPPT10 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p7451: {
    id: "dci-11",
    sungrowName: "MPPT11 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
  p7452: {
    id: "dci-12",
    sungrowName: "MPPT12 Current",
    unit: "A",
    factor: 1,
    normalize: normalizeDataAndFilterHours,
  },
};

/**
 * endpoints for sungrow services
 */
const endpoints = {
  login: "/userService/login",
  getDeviceList: "/devService/getDeviceList",
  getDataPoints: "/commonService/queryDevicePointMinuteDataList",
  getPlantsList: "/powerStationService/getPsList", // bugged out, always returns empty
};

const deviceTypes = {
  inverter: "1",
  plant: "11",
};

const getInvFieldNames = () => [
  "invPower",
  "invEnergy",
  "invDcVoltage",
  "invAcVoltageMin",
  "invAcVoltageMax",
  "subchannelDcCurrent",
  "chanDcCurrent",
];

const DCV_KEY = "dcv-";
const DCI_KEY = "dci-";

function createEmptyInvDataObj() {
  return getInvFieldNames().reduce((acc, fieldName) => {
    acc[fieldName] = [];
    return acc;
  }, {});
}

function getSunTimes(date, lat, lon) {
  const sunCalcTimes = SunCalc.getTimes(date, lat, lon);
  return {
    sunrise: moment(sunCalcTimes.sunrise),
    sunset: moment(sunCalcTimes.sunset).subtract(60, "minutes"),
  };
}

/**
 * @param {any} value
 * @param {Number} factor - defaults to 1
 * @returns {any} if `value` is finite multiplies value by factor, otherwise, returns value
 */
function factorIfFinite(value, factor = 1) {
  return Number.isFinite(value) ? value * factor : value;
}

/**
 * @returns {object} common headers for all sungrow api calls
 */
function getCommonHeaders() {
  return {
    sys_code: "901",
    lang: "_en_US",
    "Content-Type": "application/json",
  };
}

async function fetchFromEndpoint(
  sources,
  resourceEndpoint,
  token = null,
  requestBody = {},
  extraHeaders = {}
) {
  const tokenObject = !token ? {} : { token };
  const fetchOpts = {
    method: "POST",
    mode: "cors",
    headers: { ...getCommonHeaders(), ...extraHeaders },
    body: JSON.stringify({
      appkey: sources.appkey,
      ...tokenObject,
      ...requestBody,
    }),
  };
  return await fetch(`${sources.serverUrl}${resourceEndpoint}`, fetchOpts).then(
    (res) => res.json()
  );
}

/**
 * tries to login to the server and returns a token and user id, if login
 * fails, prints reasons and returns false
 * @param {object} sources - sources object from system's data
 * @returns {object | null} - an object with `token`, the connection token,
 * and `user_id`
 */
async function login(sources) {
  try {
    const extraHeaders = { "x-access-key": sources.xAccessKey };
    const requestBody = {
      user_account: sources.userAccount,
      user_password: sources.userPassword,
      login_type: "1",
    };

    const result = await fetchFromEndpoint(
      sources,
      endpoints.login,
      null,
      requestBody,
      extraHeaders
    );
    // if no data and token are recieved, throw an error
    if (
      !result.result_data ||
      !result.result_data.token ||
      !result.result_data.user_id
    ) {
      throw new Error(result.result_msg || "unknown error");
    }
    return {
      token: result.result_data.token,
      user_id: result.result_data.user_id,
    };
  } catch (error) {
    console.error(
      `error logging into sungrow server '${sources.serverUrl}'\n`,
      error.stack
    );
    return null;
  }
}

/**
 * returns a promise resolving to plant's inverters
 * @param {object} sources - sources object from the system's data
 * @param {string} token - connection token recieved from a successful login attempt
 * @returns {Promise<Array<object>>} - promise resolving to the list of inverters
 */
async function getInverters(sources, inverterMapping, token) {
  const requestBody = {
    ps_id: sources.psId,
    device_type_list: [deviceTypes.inverter],
  };

  return await fetchFromEndpoint(
    sources,
    endpoints.getDeviceList,
    token,
    requestBody
  ).then((res) => {
    return (
      res.result_data.pageList
        // filter out null serial numbers
        .filter(({ device_pro_sn: sn }) => sn)
        .map((invData) => {
          const sn = invData.device_pro_sn;
          const index = sources.mpptMap.findIndex((mppt) => sn === mppt.sn);
          invData.sourcesIndex = index;
          /*
            TODO: remove second part after the 'length',
            the second part after the '||' is to be kept only
            until migration to the array form of the field is done
          */
          invData.mpptCount =
            sources.mpptMap[index].channels.length ||
            sources.mpptMap[index].channels;
          // TODO: as above, remove these checking parts after migration
          invData.subStrings =
            typeof sources.mpptMap[index].channels === "object"
              ? sources.mpptMap[index].channels.map((chan, chanIdx) => {
                  const stringData = [
                    ...chan.strings.map((str, strIdx) => {
                      const stringData = { ...str };
                      // check for the real time database's disabled status for this string
                      stringData.disabled = Boolean(
                        Array.isArray(inverterMapping) &&
                          inverterMapping[index] &&
                          Array.isArray(inverterMapping[index].channels) &&
                          inverterMapping[index].channels[chanIdx] &&
                          Array.isArray(
                            inverterMapping[index].channels[chanIdx].subchannels
                          ) &&
                          inverterMapping[index].channels[chanIdx].subchannels[
                            strIdx
                          ] &&
                          inverterMapping[index].channels[chanIdx].subchannels[
                            strIdx
                          ].disabled
                      );
                      return stringData;
                    }),
                  ];
                  return stringData;
                })
              : null;
          return invData;
        })
        .sort(({ sourcesIndex: i1 }, { sourcesIndex: i2 }) => i1 - i2)
    );
  });
}

function fetchDataPoints(sources, token, devicePsKey, date, pointsObject) {
  const points = Object.keys(pointsObject);
  const start_time_stamp = moment(date).startOf("day").format(timeFormat);
  const end_time_stamp = moment(date).endOf("day").format(timeFormat);
  const requestBody = {
    ps_key: devicePsKey,
    points: points.join(","),
    start_time_stamp,
    end_time_stamp,
  };
  return fetchFromEndpoint(
    sources,
    endpoints.getDataPoints,
    token,
    requestBody
  ).then((res) => {
    if (!res.result_data.length) {
      return null;
    }
    return res.result_data.reduce((acc, log) => {
      const timestamp = moment(log.time_stamp, timeFormat);
      for (let point of points) {
        const datum = Number.parseFloat(log[point]);
        const datumObj = {
          value: !Number.isFinite(datum) ? null : datum,
          timestampMmnt: moment(timestamp),
        };
        acc[point].push(datumObj);
        // acc[pointsObject[point].id][
        //   moment(log.time_stamp, timeFormat).format()
        // ] = datumNum;
      }
      return acc;
    }, Object.fromEntries(points.map((point) => [point, []])));
  });
}

function normalizeEnergy(factor, rawArr = []) {
  const energy = {};
  if (!rawArr.length) {
    return energy;
  }
  const { value, timestampMmnt } = rawArr[0];
  energy[timestampMmnt.format()] = factorIfFinite(value, factor);

  for (let i = 1; i < rawArr.length; ++i) {
    const { value, timestampMmnt } = rawArr[i];
    const { value: valPrev } = rawArr[i - 1];
    const ts = timestampMmnt.format();
    if (!Number.isFinite(value) || !Number.isFinite(valPrev)) {
      energy[ts] = factorIfFinite(value, factor);
    } else {
      energy[ts] = (value - valPrev) * factor;
    }
  }

  return energy;
}

function naiveNormalizeData(factor, rawArr = []) {
  return rawArr.reduce((acc, { value, timestampMmnt }) => {
    acc[timestampMmnt.format()] = factorIfFinite(value, factor);
    return acc;
  }, {});
}

// works like naive normalization, but filters hours out of [sunrise, sunset]
function normalizeDataAndFilterHours(factor, rawArr = [], sunTimes) {
  return rawArr.reduce((acc, { value, timestampMmnt }) => {
    if (
      timestampMmnt.isBetween(
        sunTimes.sunrise,
        sunTimes.sunset,
        undefined,
        "[]"
      )
    ) {
      acc[timestampMmnt.format()] = factorIfFinite(value, factor);
    }
    return acc;
  }, {});
}

// normalization specific to acV channels
function prepareAcVoltChannels(factor, rawArr = [], sunTimes) {
  return rawArr.map(({ value, timestampMmnt }) => {
    // discard of values not within working hours
    const returnValue = timestampMmnt.isBetween(
      sunTimes.sunrise,
      sunTimes.sunset,
      undefined,
      "[]"
    )
      ? value
      : null;
    return {
      value: factorIfFinite(returnValue, factor),
      ts: timestampMmnt.format(),
    };
  });
}

async function getSystemDataPoints(sources, token, date) {
  try {
    const ps_key = `${sources.psId}_11_0_0`;
    const rawData = await fetchDataPoints(
      sources,
      token,
      ps_key,
      date,
      pointsMetaSys
    );

    return Object.entries(rawData).reduce((acc, [pointKey, valuesArr]) => {
      const pointMeta = pointsMetaSys[pointKey];
      acc[pointMeta.id] = [pointMeta.normalize(pointMeta.factor, valuesArr)];
      return acc;
    }, {});
  } catch (err) {
    console.error(err.stack);
    return {};
  }
}

function calcMinMaxAcVoltages(data) {
  const acValues = Object.keys(data)
    .filter((key) => key.startsWith("acv-"))
    .map((acKey) => data[acKey])
    .reduce((acc, logs) => {
      logs.forEach(({ value, ts }) => {
        if (Number.isFinite(value)) {
          if (!acc[ts]) {
            acc[ts] = [];
          }
          acc[ts].push(value);
        }
      });
      return acc;
    }, {});

  return Object.entries(acValues).reduce(
    (acc, [ts, vals]) => {
      const min = Math.min(...vals);
      const max = Math.max(...vals);
      acc.invAcVoltageMin[ts] = Number.isFinite(min) ? min : null;
      acc.invAcVoltageMax[ts] = Number.isFinite(max) ? max : null;
      return acc;
    },
    {
      invAcVoltageMin: {},
      invAcVoltageMax: {},
    }
  );
}

function createDcAttribute(data, mpptCount, attributeKey) {
  return Object.entries(data).reduce(
    (acc, [key, dataObj]) => {
      if (key.startsWith(attributeKey)) {
        const idx = Number.parseInt(key.split("-")[1]) - 1;
        if (idx < mpptCount) {
          acc[idx] = dataObj;
        }
      }
      return acc;
    },
    Array(mpptCount)
      .fill(null)
      .map(() => ({}))
  );
}

function createStringCurrents(data, inv) {
  if (!inv.subStrings) {
    return [];
  }

  const stringKeys = Object.keys(data)
    .filter((key) => key.startsWith("si-"))
    .map((key) => ({ key, idApi: key.split("-")[1] }));

  return inv.subStrings.map((mppt) => {
    return mppt.map((subStr) => {
      // check if substring was disabled manually
      if (subStr.disabled) {
        return {};
      }
      // find matching key for this substring
      const strKey = stringKeys.find((k) => k.idApi == subStr.idApi).key;
      return data[strKey];
    });
  });
}

/**
 * fetches datapoints for a single inverter and organizes them
 * @param {*} sources
 * @param {*} token
 * @param {*} date
 * @param {*} inv
 * @param {*} sunTimes
 * @returns datapoints for a single inverter
 */
async function getInvDataPoints(sources, token, date, inv, sunTimes) {
  try {
    const { ps_key, mpptCount } = inv;

    const rawData = await fetchDataPoints(
      sources,
      token,
      ps_key,
      date,
      pointsMetaInv
    );
    const data = Object.entries(rawData).reduce(
      (acc, [pointKey, valuesArr]) => {
        const pointMeta = pointsMetaInv[pointKey];
        acc[pointMeta.id] = pointMeta.normalize(
          pointMeta.factor,
          valuesArr,
          sunTimes
        );
        return acc;
      },
      {}
    );

    const { invPower, invEnergy } = data;
    const { invAcVoltageMin, invAcVoltageMax } = calcMinMaxAcVoltages(data);
    const dcVoltages = createDcAttribute(data, mpptCount, DCV_KEY);
    const chanDcCurrent = createDcAttribute(data, mpptCount, DCI_KEY);
    const stringDcCurrent = createStringCurrents(data, inv);
    return {
      invPower,
      invEnergy,
      invAcVoltageMin,
      invAcVoltageMax,
      dcVoltages,
      stringDcCurrent,
      chanDcCurrent,
    };
  } catch (err) {
    console.error(err.stack);
    return {};
  }
}

export async function getData(systemInfo, date, timeOffset) {
  const { sources, location, inverterMapping } = systemInfo;

  const { token, user_id } = await login(sources);

  if (!token || !user_id) {
    throw new Error("could not login, check console");
  }

  const inverters = await getInverters(sources, inverterMapping, token);

  const sunTimes = getSunTimes(date, location.lat, location.lon);

  const sysData = await getSystemDataPoints(sources, token, date);
  const invDataArr = await bPromise.map(
    inverters,
    (inv) => {
      return getInvDataPoints(sources, token, date, inv, sunTimes);
    },
    { concurrency: 3 }
  );
  const invData = inverters.reduce((acc, inv, idx) => {
    const {
      invPower,
      invEnergy,
      invAcVoltageMin,
      invAcVoltageMax,
      dcVoltages,
      stringDcCurrent,
      chanDcCurrent,
    } = invDataArr[idx];
    const emptiesToPush = inv.mpptCount - 1;
    acc.invPower.push(
      invPower,
      ...Array(emptiesToPush)
        .fill(null)
        .map(() => ({}))
    );
    acc.invEnergy.push(
      invEnergy,
      ...Array(emptiesToPush)
        .fill(null)
        .map(() => ({}))
    );
    acc.invAcVoltageMin.push(
      invAcVoltageMin,
      ...Array(emptiesToPush)
        .fill(null)
        .map(() => ({}))
    );
    acc.invAcVoltageMax.push(
      invAcVoltageMax,
      ...Array(emptiesToPush)
        .fill(null)
        .map(() => ({}))
    );
    acc.subchannelDcCurrent.push(...stringDcCurrent);
    acc.invDcVoltage.push(...dcVoltages);
    acc.chanDcCurrent.push(...chanDcCurrent);
    return acc;
  }, createEmptyInvDataObj());
  return {
    ...sysData,
    ...invData,
  };
}

// solar-edge style collation
export function collateHourlyData(data, mins = 60, keepOffset = false) {
  if (!Number.isInteger(mins) || mins <= 0 || 60 % mins !== 0) {
    mins = 60;
  }
  const midRes = {};
  Object.keys(data).forEach((timeStamp) => {
    const currentMoment = moment(timeStamp);
    currentMoment.add(mins, "minutes");
    const currentHour = currentMoment
      .subtract(currentMoment.minutes() % mins, "minutes")
      .startOf("minute")
      .toISOString(keepOffset);
    const value = Number.parseFloat(data[timeStamp]);
    if (!Number.isNaN(value)) {
      if (!(currentHour in midRes)) {
        midRes[currentHour] = [];
      }
      midRes[currentHour].push(value);
    }
  });
  return midRes;
}

// solaredge style time checking
export function isActiveCb(val) {
  const numVal = parseFloat(val);
  return !Number.isNaN(numVal) && numVal !== 0;
}

export function getLatestStartTime(data) {
  const keys = Object.keys(data);
  if (keys.length === 0) {
    return null;
  }
  keys.sort();
  let res = isActiveCb(data[keys[0]]) ? keys[0] : null;
  for (let i = keys.length - 1; i > 0; --i) {
    if (!isActiveCb(data[keys[i - 1]]) && isActiveCb(data[keys[i]])) {
      res = keys[i];
      break;
    }
  }
  return res;
}

export function getLatestStopTime(data) {
  const dataEntries = Object.entries(data).sort((log1, log2) =>
    log1[0] < log2[0] ? -1 : 1
  );
  for (let i = dataEntries.length - 1; i >= 0; --i) {
    if (isActiveCb(dataEntries[i][1])) {
      break;
    }
    dataEntries.pop();
  }
  const length = dataEntries.length;
  if (length === 0) {
    return null;
  }
  return dataEntries[length - 1][1] ? dataEntries[length - 1][0] : null;
}
