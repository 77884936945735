import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import DatePickerCombo from "../DatePickerCombo";

import * as sms from "../../services/Sms";
import getSenderIds from "../../services/senderIds";

import MessageLogItem from "./MessageLogItem";
import SystemsSelect from "../SystemsDropDown";

const MAX_MESSAGES_BEFORE_PROMPT = 100;
const MESSAGE_LENGTH_ALERT = 600;

const Section = styled.div`
  padding: 10px 0;
  &:first-child {
    border-top: none;
    padding-top: 0;
  }
`;
const Label = styled.span`
  padding-bottom: 0.3em;
  min-width: 100px;
  display: inline-block;
`;
const CharCounter = styled.div`
  font-size: 0.65em;
  color: #a2a2a2;
`;

// returns a state of a clean form
const getCleanForm = () => {
  return {
    messageType: sms.getMessageTypes()[0],
    recievingPhone: "",
    message: "",
    selectedSysId: "",
    senderId: getSenderIds()[0],
    eventDate: moment(),
  };
};

// constructor for object representing a message log
function MessageLog(messageId, recipient, status, body, sender, error = null) {
  this.messageId = messageId; // aws message id
  this.recipientNumber = recipient; // recipient number
  this.status = status; // log status
  this.body = body; // message body
  this.sender = sender;
  this.error = error; // error if exists
}

class ManualMessagesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messagesCounter: 0,
      sentMessages: [],
      pendingMessage: null,
      ...getCleanForm(),
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  handleMessageTypeChange = (ev) => {
    if (!this._isMounted) {
      return;
    }
    this.setState({ messageType: ev.target.value });
  };

  handleSysIdChange = (sysId) => {
    if (!this._isMounted) {
      return;
    }
    this.setState((state, props) => {
      const systemMeta = props.sysMatch[sysId];
      const label = systemMeta ? systemMeta.label : "";
      const phones = systemMeta ? systemMeta.phones : [];
      return {
        selectedSysId: sysId,
        senderId: label,
        recievingPhone: phones.join(","),
      };
    });
  };

  handlePhoneChange = (ev) => {
    if (!this._isMounted) {
      return;
    }
    this.setState({ recievingPhone: ev.target.value });
  };

  handleMessageChange = (ev) => {
    if (!this._isMounted) {
      return;
    }
    this.setState({ message: ev.target.value });
  };

  handleSenderIdChange = (ev) => {
    if (!this._isMounted) {
      return;
    }
    this.setState({ senderId: ev.target.value });
  };

  onSendSms = () => {
    if (!this._isMounted) {
      return;
    }
    // check if message is too large for sms, or the user intended to do so
    if (this.state.message.length > MESSAGE_LENGTH_ALERT) {
      if (
        !window.confirm(
          `message exceeds ${MESSAGE_LENGTH_ALERT} characters, continue?`
        )
      ) {
        return;
      }
    }
    // confirm after set amount of sent messages to make sure no error occured
    if (this.state.messagesCounter >= MAX_MESSAGES_BEFORE_PROMPT) {
      if (
        !window.confirm(
          `sent messages exceeded ${MAX_MESSAGES_BEFORE_PROMPT}, continue?`
        )
      ) {
        return;
      } else {
        this.setState({ messagesCounter: 0 });
      }
    }
    this.setState(
      (state) => {
        const {
          messageType,
          recievingPhone,
          message,
          senderId,
          selectedSysId,
          eventDate,
        } = state;
        // split phones by either commas or any whitespace
        const phoneList = recievingPhone.split(/,|\s+/).filter((p) => p);
        return {
          pendingMessage: {
            messageType,
            phoneList,
            message,
            senderId,
            systemId: selectedSysId,
            eventDate,
          },
          messagesCounter: state.messagesCounter + phoneList.length,
        };
      },
      () => {
        const {
          messageType,
          phoneList,
          message,
          senderId,
          systemId,
          eventDate,
        } = this.state.pendingMessage;
        sms
          .sendSMS(
            message,
            phoneList,
            senderId,
            messageType,
            null, // language code, not relevant
            systemId,
            eventDate
          )
          .then((res) => {
            if (!this._isMounted) {
              return;
            }
            // create logs for sent messages
            const logs = phoneList.map((recievingPhone, idx) => {
              const messageId = res[idx].MessageId;
              // no aws message id means an error, log that to console and done list
              if (!messageId) {
                console.warn(res[idx].message);
                return new MessageLog(
                  "",
                  recievingPhone,
                  "error",
                  message,
                  senderId,
                  new Error(res[idx].message)
                );
              }
              // log as done with given aws message id
              return new MessageLog(
                messageId,
                recievingPhone,
                "success",
                message,
                senderId
              );
            });
            this.setState((state) => {
              return {
                ...getCleanForm(),
                sentMessages: [...state.sentMessages, ...logs],
              };
            });
          })
          .catch((err) => {
            if (!this._isMounted) {
              return;
            }
            console.warn(err);
            this.setState(() => {
              return {
                ...getCleanForm(),
              };
            });
          })
          .finally(() => {
            if (!this._isMounted) {
              return;
            }
            this.setState({
              pendingMessage: null,
            });
          });
      }
    );
  };

  onClearForm = () => {
    if (!this._isMounted) {
      return;
    }
    this.setState({ ...getCleanForm() });
  };

  onClearSent = () => {
    if (!this._isMounted) {
      return;
    }
    this.setState({ sentMessages: [] });
  };

  onChangeEventDate = (newDate) => {
    this.setState(() => {
      if (!this._isMounted) {
        return;
      }
      return {
        eventDate: moment(newDate),
      };
    });
  };

  render() {
    const pendingMessage = this.state.pendingMessage;
    return (
      <div>
        <Section>
          <Label>Event date-time</Label>
          <div>
            <DatePickerCombo
              selected={this.state.eventDate}
              disable={false}
              onChange={this.onChangeEventDate}
              format={"DD/MM/YYYY, HH:mm"}
              pickerProps={{ showTimeSelect: true }}
            />
          </div>
        </Section>
        <Section>
          <Label>Message type</Label>
          <div>
            <select
              value={this.state.messageType}
              onChange={this.handleMessageTypeChange}
            >
              {sms.getMessageTypes().map((type, idx) => (
                <option key={idx}>{type}</option>
              ))}
            </select>
          </div>
        </Section>
        <Section>
          <Label>Phone number</Label>
          <div>
            <textarea
              placeholder="+1 999 999 9999"
              cols={40}
              rows={2}
              value={this.state.recievingPhone}
              onChange={this.handlePhoneChange}
            />
          </div>
        </Section>
        <Section>
          <Label>System ID</Label>
          <div>
            <SystemsSelect
              systems={Object.values(this.props.systems)}
              onSystemSelect={this.handleSysIdChange}
              selectedSystem={this.state.selectedSysId}
            />
          </div>
        </Section>
        <Section>
          <Label>Message</Label>
          <div>
            <textarea
              rows="10"
              cols="40"
              placeholder="Enter the message body"
              value={this.state.message}
              onChange={this.handleMessageChange}
            />
            <CharCounter>
              {this.state.message.length}/{MESSAGE_LENGTH_ALERT} characters
            </CharCounter>
          </div>
        </Section>
        <Section>
          <Label>Sender ID</Label>
          <div>
            <select
              value={this.state.senderId}
              onChange={this.handleSenderIdChange}
            >
              {getSenderIds().map((sender, idx) => (
                <option key={idx}>{sender}</option>
              ))}
            </select>
          </div>
        </Section>
        <Section>
          <button disabled={pendingMessage} onClick={this.onSendSms}>
            Send
          </button>
          <button onClick={this.onClearForm}>Clear</button>
        </Section>
        {pendingMessage && (
          <Section>
            <Label>Pending response</Label>
            <div style={{ color: "orange" }}>
              <i>phone:</i> '{pendingMessage.recievingPhone}', <i>status:</i>{" "}
              pending
            </div>
          </Section>
        )}
        <Section>
          <Label>Messages log</Label>
          <ul>
            {this.state.sentMessages.map((log, idx) => (
              <MessageLogItem key={idx} log={log} />
            ))}
          </ul>
          <button onClick={this.onClearSent}>Clear sent</button>
        </Section>
      </div>
    );
  }
}

export default ManualMessagesForm;
