import React from "react";
import { withRouter } from "react-router-dom";

import AuthUserContext from "./AuthUserContext";
import { firebase } from "../../firebase";
import * as routes from "../../constants/routes";
import LocalStorageKeys from "../../localStorageKeys";

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      // changed from `onAuthStateChanged` to allow token changing instead of re-login
      firebase.auth.onIdTokenChanged((authUser) => {
        if (!condition(authUser)) {
          this.props.history.push(routes.SIGN_IN);
        } else {
          authUser
            .getIdToken(/* forceRefresh */ false)
            .then(function (idToken) {
              localStorage.setItem(LocalStorageKeys.userEmail, authUser.email);
              localStorage.setItem(LocalStorageKeys.userToken, idToken);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) => (authUser ? <Component {...this.props} /> : null)}
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(WithAuthorization);
};

export default withAuthorization;
