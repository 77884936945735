import React from "react";
import PropTypes from "prop-types";
import OneChart from "./OneChart";
import moment from "moment";
import styled from "styled-components";

const ChartsContainer = styled.div`
  overflow: auto;
  height: 100%;
`;
const ChartWrapper = styled.div`
  text-align: end;
  margin-bottom: 2em;
`;

export const normalize = (raw) => {
  if (raw.length === 0) {
    return [];
  }
  const res = [];
  const setNames = raw.map((set, idx) => `set${idx}`);
  const times = Object.keys(raw[0]);
  times.forEach((time) => {
    const hour = moment(time).format("HH:mm");
    const item = { name: hour };
    raw.forEach((set, idx) => {
      const num = parseFloat(set[time], 10);
      item[setNames[idx]] = isNaN(num) ? null : num;
    });
    res.push(item);
  });
  return res;
};

export const normalize2 = (raw, inverters = null) => {
  if (raw.length === 0) {
    return [];
  }
  if (!inverters) {
    inverters = [];
  }
  const res = [];
  const setNames = raw.map((set, idx) => inverters[idx] || `set-${idx}`);

  raw.forEach((set, idx) => {
    const data = Object.keys(set).map((timeStr) => {
      const num = parseFloat(set[timeStr], 10);
      return {
        time: moment(timeStr).valueOf(),
        value: isNaN(num) ? null : num,
      };
    });
    const chartSet = {
      name: setNames[idx],
      data,
    };
    res.push(chartSet);
  });

  return res;
};

const chartsMeta = [
  {
    key: "invPower",
    title: "Inv Power (kW)",
    units: "kW",
    normalizer: normalize2,
  },
  {
    key: "invDcVoltage",
    title: "Inv DC Voltage (V)",
    units: "V",
    normalizer: normalize2,
  },
  {
    key: "invAcVoltageMin",
    title: "Inv AC Voltage Min (V)",
    units: "V",
    normalizer: normalize2,
  },
  {
    key: "invAcVoltageMax",
    title: "Inv AC Voltage Max (V)",
    units: "V",
    normalizer: normalize2,
  },
  {
    key: "invAcVoltage",
    title: "Inv AC Voltage (V)",
    units: "V",
    normalizer: normalize2,
  },
  {
    key: "invEnergy",
    title: "Inv Energy (kWh)",
    units: "KWh",
    normalizer: normalize2,
  },
  {
    key: "sysPower",
    title: "Sys Power (kW)",
    units: "kW",
    normalizer: normalize2,
  },
  {
    key: "sysEnergy",
    title: "Sys Energy (kWh)",
    units: "kWh",
    normalizer: normalize2,
  },
  {
    key: "chanDcCurrent",
    title: "Channel DC Current",
    units: "A",
    normalizer: normalize2,
  },
  {
    key: "chanDcPowerInput",
    title: "Channel DC Power Input",
    units: "kW",
    normalizer: normalize2,
  },
];

class DataCharts extends React.Component {
  static propTypes = {
    data: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <ChartsContainer>
        <div>
          {chartsMeta.map(
            (chart) =>
              chart.key in this.props.data && (
                <ChartWrapper key={chart.key}>
                  {chart.title}
                  <OneChart
                    label={chart.title}
                    units={chart.units}
                    data={chart.normalizer(
                      this.props.data[chart.key],
                      chart.key.startsWith("inv") ||
                        chart.key.startsWith("chan")
                        ? this.props.inverters
                        : null
                    )}
                    sets={this.props.data[chart.key]}
                  />
                </ChartWrapper>
              )
          )}
        </div>
      </ChartsContainer>
    );
  }
}

export default DataCharts;
