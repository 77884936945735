import React from "react";

import AdminMessagesForm from "../components/MessagesAdmin";

class MessagesAdmin extends React.Component {
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const {
      noRefSystems,
      testSystems,
      frozenSystems,
      usersData,
      sysMatch,
      orgsData,
    } = this.props;
    const systems = {
      "": { id: "", name: "" },
      ...(testSystems || {}),
      ...(noRefSystems || {}),
      ...(frozenSystems || {}),
    };
    if (Object.keys(systems).length <= 1) {
      return <div>Loading systems...</div>;
    }

    return (
      <AdminMessagesForm
        systems={systems}
        sysMatch={sysMatch}
        usersData={usersData}
        orgsData={orgsData}
      />
    );
  }
}

export default MessagesAdmin;
